import { Box, Button, InputAdornment, TextField } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { AntSwitch } from 'app/components/stylesComponents/AntSwitch'
import { Nullable } from 'app/pages/Chat/interface/interface'
import { getParamQueries, saleType, vatRefundable } from 'config/appConfig'
import { FieldValdations } from 'config/constants'
import { urlParamInterface } from 'config/interface'
import { profileType, profileTypeString } from 'config/publicProfileConfig'
import { roundToTwoDecimalPlaces } from 'config/utils'
import { Strings, getProductTypeName, getSubsidiaryStored, myVehicleStatus, productTypes, vehicles } from 'config/variables'
import { translations } from 'locales/translations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Toaster } from 'services/Toaster'
import { sellReserveVehicleFailure, sellReserveVehicleRequest, sellReserveVehicleSuccess } from 'store/actions/myAds-actions'

const PricingAndSalesDetails = () => {

    const subsidiary = getSubsidiaryStored()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { my_vehicle_status, vehicle_id, vehicle }: urlParamInterface = useParams();

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const { subsidiaryDetails } = useSelector((state: RootStateOrAny) => state.company);
    const { validateData } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { selectedCustomer, vehicleTradeSuccess, vehicleTradeFailure } = useSelector((state: RootStateOrAny) => state.myAds)

    const [orderCreated, _orderCreated] = useState<boolean>(false)
    const [typeOfSale, _typeOfSale] = useState<Nullable<number>>()

    const [hasWarranty, _hasWarranty] = useState<boolean>(false)
    const [monthsWarranty, _monthsWarranty] = useState<number>(0)

    const [isVATRefundable, _isVATRefundable] = useState<Nullable<number>>(vatRefundable.false)
    const [vatRate, _vatRate] = useState<number>(0)

    const [priceWithoutVat, _priceWithoutVat] = useState<Nullable<number>>()
    const [priceWithVat, _priceWithVat] = useState<Nullable<number>>()
    const [vatDifference, _vatDifference] = useState<Nullable<number>>(0)
    // Setting VAT rate if logged in as subsidiary
    useEffect(() => {
        if (subsidiary && subsidiaryDetails && Object.keys(subsidiaryDetails).length > 0) {
            _vatRate(subsidiaryDetails.limited_address.vat_rate)
        }
    }, [subsidiaryDetails, subsidiary])

    // Prefilling field values if vehcile was reserved or ad was already created
    useEffect(() => {
        if (validateData && Object.keys(validateData).length > 0) {
            _isVATRefundable(validateData.vehicle_deductible_vat)
            if (validateData.latest_ad_vehicle && Object.keys(validateData.latest_ad_vehicle).length > 0) {
                handlePriceWithoutVAT(validateData.latest_ad_vehicle.public_price)
                if (validateData.latest_ad_vehicle.vehicle_month_warranty > 0) {
                    _hasWarranty(true)
                    _monthsWarranty(validateData.latest_ad_vehicle.vehicle_month_warranty)
                }
            }
            if (validateData.latest_exclusive_tax_price && Object.keys(validateData.latest_exclusive_tax_price).length > 0) {
                handlePriceWithoutVAT(validateData.latest_exclusive_tax_price.exclusive_tax_price)
                if (validateData.latest_exclusive_tax_price.vehicle_month_warranty > 0) {
                    _hasWarranty(true)
                    _monthsWarranty(validateData.latest_exclusive_tax_price.vehicle_month_warranty)
                }
            }
        }
    }, [validateData])

    useEffect(() => {
        if (my_vehicle_status == myVehicleStatus.confirmSale) {
            const payload = getParamQueries(location.search)
            if (payload.saleType) {
                _typeOfSale(payload.saleType)
            }
        }
    }, [location])


    // Capturing and Showing success message whenever any action has been peformed
    useEffect(() => {
        if (vehicleTradeSuccess && Object.keys(vehicleTradeSuccess).length > 0) {
            if (vehicleTradeSuccess.data && vehicleTradeSuccess.data.message) {
                Toaster.success(vehicleTradeSuccess.data.message)
            }
            dispatch(sellReserveVehicleSuccess({}))
            history.push(routes.vehicleRoutes.myAds + '/' + getProductTypeName[validateData?.vehicle?.product_category_type?.product_type_id ?? productTypes.car] + routes.vehicleRoutes.park)
        }
    }, [vehicleTradeSuccess])

    const handleCancelButton = () => {
        const prevURL = localStorage.getItem('myadsURL')
        if (prevURL) {
            history.push(prevURL)
            return
        }
        history.goBack()
    }

    // Capturing and Showing error message whenever any action has been peformed
    useEffect(() => {
        if (vehicleTradeFailure && Object.keys(vehicleTradeFailure).length > 0) {
            if (vehicleTradeFailure.data) {
                Toaster.error(Object.values(vehicleTradeFailure.data).toString())
                dispatch(sellReserveVehicleFailure({}))
                return
            }
            Toaster.error(vehicleTradeFailure.message)
            dispatch(sellReserveVehicleFailure({}))
        }
    }, [vehicleTradeFailure])


    const handlePriceWithoutVAT = (value) => {
        _priceWithoutVat(value)

        if (subsidiary) {
            const difference = (value / 100) * vatRate
            const vatDiff = roundToTwoDecimalPlaces(difference)
            const finalPrice = roundToTwoDecimalPlaces(value)
            _vatDifference(vatDiff)
            if (value) {
                _priceWithVat(roundToTwoDecimalPlaces(finalPrice + vatDiff))
                return
            }
            _priceWithVat(0)
        }
    }

    const handlePriceWithVAT = (value) => {
        _priceWithVat(value)
        const totalTaxAndPriceRate = 100 + Math.trunc(vatRate)
        const amount = (value / totalTaxAndPriceRate) * 100
        const totalAmount = roundToTwoDecimalPlaces(amount);
        _priceWithoutVat(totalAmount)
        const vatdiff = roundToTwoDecimalPlaces(value - totalAmount)
        _vatDifference(vatdiff)

    }

    const handleAction = (actionType) => {
        if (!priceWithoutVat) {
            Toaster.error(t(translations.ads.PUBLIC_PRICE_REQUIRED))
            return
        }
        if (hasWarranty) {
            if (!monthsWarranty || monthsWarranty < FieldValdations.WARRANTY_VALIDATIION_MINIMUM) {
                Toaster.error(Strings.warrantyMinimumError)
                return
            }
            if (monthsWarranty > FieldValdations.WARRANTY_VALIDATIION_MAXIMUM) {
                Toaster.error(Strings.warrantyError)
                return
            }
        }
        _orderCreated(true)
        history.push(`${routes.userRoutes.myVehicles}${routes.userRoutes.confirmSale}/${vehicle_id}?saleType=${actionType}`)
    }


    // Conditionally calls API for vehcile on confirmation
    const handleConfirmAction = () => {
        if (!priceWithoutVat) {
            Toaster.error(t(translations.ads.PUBLIC_PRICE_REQUIRED))
            return
        }
        // Checks if sale type is reserve then calls reserve vehicle API
        if (typeOfSale == saleType.reserve) {
            handleReserved()
        }
        // Checks if sale type is sold then calls sold vehicle API
        if (typeOfSale == saleType.sold) {
            handleConfirmSale()

        }
    }

    // API calling function for reserve
    const handleReserved = () => {
        let payload = {
            type: saleType.reserve,
            id: vehicle_id,
            customer_id: selectedCustomer.id,
            customer_type: selectedCustomer.source == profileTypeString.user ? profileType.user : profileType.subsidiary,
            exclusive_tax_price: priceWithoutVat,
            vehicle_deductible_vat: isVATRefundable == vatRefundable.true && subsidiary ? vatRefundable.true : vatRefundable.false,
            total_price: isVATRefundable == vatRefundable.true && subsidiary ? priceWithVat : priceWithoutVat
        }
        if (monthsWarranty && hasWarranty) {
            payload['vehicle_month_warranty'] = monthsWarranty
        }
        dispatch(sellReserveVehicleRequest(payload))
    }

    // API calling function for sold
    const handleConfirmSale = () => {
        let payload = {
            type: saleType.sold,
            id: vehicle_id,
            customer_id: selectedCustomer.id,
            customer_type: selectedCustomer.source == profileTypeString.user ? profileType.user : profileType.subsidiary,
        }
        // price and warranty values are sent only if vehicle was in park and order was just created without reserving 
        if (orderCreated) {
            payload['exclusive_tax_price'] = priceWithoutVat
            payload['total_price'] = isVATRefundable == vatRefundable.true && subsidiary ? priceWithVat : priceWithoutVat

            payload['vehicle_deductible_vat'] = isVATRefundable == vatRefundable.true && subsidiary ? vatRefundable.true : vatRefundable.false
            if (monthsWarranty && hasWarranty) {
                payload['vehicle_month_warranty'] = monthsWarranty
            }
        }
        dispatch(sellReserveVehicleRequest(payload))
    }

    return (
        <Box className='container rightContainer flexRow'>
            <Box className='pricingContainer flexColumn'>
                <span className='price'>{t(translations.ads.PRICE)}</span>
                {/* VAT toggle appears only for Subsidiary */}
                {
                    subsidiary && <Box className='flexRow justifyContentBetween'>
                        <span className='fieldLabel'>{t(translations.ads.VAT_REFUNDABLE)}</span>
                        <AntSwitch name={"findable"} checked={isVATRefundable == vatRefundable.true ? true : false} onChange={() => { _isVATRefundable((refundable) => refundable == vatRefundable.true ? vatRefundable.false : vatRefundable.true) }} inputProps={{ 'aria-label': 'ant design' }} className={"toggle_icon " + (my_vehicle_status == myVehicleStatus.confirmSale ? 'disabled' : '')} disabled={my_vehicle_status == myVehicleStatus.confirmSale} />
                    </Box>
                }

                <TextField
                    id="outlined-required-price"
                    label={isVATRefundable ? t(translations.ads.PRICE_WITHOUT_VAT) : t(translations.ads.PRICE)}
                    placeholder={isVATRefundable ? t(translations.ads.PRICE_WITHOUT_VAT) : t(translations.ads.PRICE)}
                    value={priceWithoutVat}
                    type='number'
                    disabled={my_vehicle_status == myVehicleStatus.confirmSale}
                    onChange={e => handlePriceWithoutVAT(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                />

                {
                    subsidiary && isVATRefundable && isVATRefundable !== vatRefundable.false ?
                        <>
                            <Box sx={{ marginLeft: '15px' }}>
                                <span className='priceValue'>€ {vatDifference}</span>
                                <span className='vat'>{" "}{t(translations.ads.VAT)}</span>
                                <span className='vatPercent'>{" "}({vatRate}%)</span>
                            </Box>
                            <TextField
                                id="outlined-required-price-with-VAT"
                                label={t(translations.ads.PRICE_WITH_VAT)}
                                placeholder={t(translations.ads.PRICE_WITH_VAT)}
                                value={priceWithVat}
                                type='number'
                                disabled={my_vehicle_status == myVehicleStatus.confirmSale}
                                onChange={e => handlePriceWithVAT(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                            />
                        </> : null

                }
                <Box className='borderBottom'></Box>

                <Box className='flexRow justifyContentBetween'>
                    <Box className='flexColumn'>
                        <span className='fieldLabelPrice'>{t(translations.ads.TOTAL_PAYABLE)}</span>
                        {/* <span className='vatPercent' style={{ marginTop: '5px' }}>(
                            {isVATRefundable ?
                                t(translations.ads.PRICE) + " + " + t(translations.ads.VAT) :
                                t(translations.webTexts.INCL_VAT)
                            }
                            )</span> */}
                    </Box>
                    <span className='price'>€ {isVATRefundable ? priceWithVat ?? '-' : priceWithoutVat ?? '-'}</span>
                </Box>

            </Box>
            <Box className='actionContainer'>
                <Box className='flexColumn' sx={{ gap: '25px' }}>
                    <Box className='flexRow justifyContentBetween'>
                        <span className='fieldLabel'>{t(translations.ads.WARRANTY)}</span>
                        <AntSwitch name={"findable"} checked={hasWarranty} onChange={() => { _hasWarranty(warranty => !warranty) }} inputProps={{ 'aria-label': 'ant design' }} className={"toggle_icon " + (my_vehicle_status == myVehicleStatus.confirmSale ? 'disabled' : '')} disabled={my_vehicle_status == myVehicleStatus.confirmSale} />
                    </Box>
                    {hasWarranty && <TextField
                        id="outlined-required-warranty"
                        type='number'
                        label={t(translations.addVehicleScreen.WARRANTY_PERIOD)}
                        placeholder={t(translations.addVehicleScreen.WARRANTY_PERIOD)}
                        value={monthsWarranty > 0 ? monthsWarranty : null}
                        disabled={my_vehicle_status == myVehicleStatus.confirmSale}
                        onChange={e => _monthsWarranty(Number(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t(translations.ads.UNTIL_MONTH)}</InputAdornment>,
                        }}
                    />}
                </Box>

                <Box className='flexRow justifyContentBetween orderButtons' sx={{ mt: 2 }}>


                    {
                        my_vehicle_status == myVehicleStatus.createOrder &&
                        <>
                            <Button className='soldButton' onClick={() => handleAction(saleType.sold)}>{t(translations.PUBLICPROFILE.SOLD)}</Button>
                            <Button className='reservedButton' onClick={() => handleAction(saleType.reserve)}>{t(translations.PUBLICPROFILE.RESERVED)}</Button>
                        </>
                    }

                    {
                        my_vehicle_status == myVehicleStatus.confirmSale &&
                        <Box className='flexColumn confirmSaleContainer'>
                            <Button className='confirmSale' onClick={handleConfirmAction}>
                                {
                                    typeOfSale == saleType.sold ?
                                        t(translations.ads.CONFIRM_SALE) :
                                        t(translations.ads.CONFIRM_RESERATION)
                                }
                            </Button>
                            {(isSmallDevice || isMediumDevice) && <Button className='reservedButton' onClick={handleCancelButton}>
                                {
                                    typeOfSale == saleType.sold ?
                                        t(translations.ads.CANCEL_SALE) :
                                        t(translations.ads.CANCEL_RESERATION)
                                }
                            </Button>}
                        </Box>
                    }
                </Box>
            </Box>

        </Box >
    )
}

export default PricingAndSalesDetails