import { Box, Button, Typography } from '@mui/material';
import { SvgCheckNew, SvgCircularCheck, SVGCross, SVGFeatured } from 'app/components/svgicons/svg';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { localstorageKey } from 'config/constants';
import { hasPermissions, PERMISSIONS } from 'config/permission';
import { numberFormat } from 'config/variables';
import { translations } from 'locales/translations';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes/routes';
import { Toaster } from 'services/Toaster';
import { getCreditPlansRequest } from 'store/actions/user-actions';
import CreditCouponCountdown from './CreditCouponCountdown';

const CreditFeatures = () => {
  //Redux states
  const { allCreditPlans, promoCode, loading } = useSelector((state: RootStateOrAny) => state.user);

  const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType();

  const permissionList = useSelector((state: any) => state.user.permissionData);
  const hasAddPermission = hasPermissions(
    permissionList,
    PERMISSIONS.FEATURES.FINANCE,
    PERMISSIONS.ACTIONS.ADD
  );
  const hasUpdatePermission = hasPermissions(
    permissionList,
    PERMISSIONS.FEATURES.FINANCE,
    PERMISSIONS.ACTIONS.UPDATE
  );

  //hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [coponCode, setCoponCode] = useState(null);
  const [couponExpiredAt, setCouponExpiredAt] = useState(null);
  const [percentageOff, setPercentageOff] = useState(0)

  useEffect(() => {
    //API call for getting credit plan list
    localStorage.removeItem(localstorageKey.purchaseCredit);
    dispatch(getCreditPlansRequest());
  }, []);

  // function to handle buy credits
  const handleBuyNow = (Id, stripeProductId, stripePriceId) => {
    // redirect on credit payment screen with price detail
    //here sp_ID (stripe_product_id) and pid is price_id of credit plan
    // st_pr_id (stripe price id)
    // btoa() is used here for encode ids
    if (!hasAddPermission && !hasUpdatePermission) {
      // if users do not have permissions for making payments, then display error
      Toaster.error(t(translations.CTB_CREDIT.DONT_HAVE_PERMISSION_TO_THIS_ACTION));
      return;
    }
    window.scrollTo(0, 0);
    localStorage.setItem(localstorageKey.purchaseCredit, history.location.pathname);
    history.push({
      pathname: routes.userRoutes.creditsPayment,
      search:
        '?sp_Id=' + btoa(stripeProductId) + '&pid=' + btoa(Id) + '&st_pr_id=' + btoa(stripePriceId),
    });
  };

  useLayoutEffect(() => {
    // update coupon code and expired date from API response
    setCoponCode(promoCode?.code)
    setCouponExpiredAt(promoCode?.coupon?.redeem_by)
    setPercentageOff(parseInt(promoCode?.coupon?.percent_off) || 0)
  }, [promoCode])

  if (loading) {
    return <></>;
  }

  return (
    <>
      {coponCode !== null ? <CreditCouponCountdown expiryTime={couponExpiredAt} coupon={coponCode} percentageOff={percentageOff} /> : null}
      <Box className="plansContainer">
        {(isLargeDevice || isExtraLargeDevice) && (
          <Box className="flexColumn planSection rightBorder">
            <Box className="plan">
              <p className="sectionHeader">{t(translations.SERVICE_PACKAGES.PLANPRCING)}</p>
            </Box>
            <Box className="featuresProvided">
              <span className="sectionHeader">{t(translations.SERVICE_PACKAGES.USAGE)}</span>
              <span className="greyBg">{t(translations.SERVICE_PACKAGES.ACCESSTOSALES)}</span>
              <span>
                <Box>
                  <b style={{ marginRight: '2px' }}>€{process.env.REACT_APP_DEFAULT_DISCOUNT} </b>
                  {t(translations.SERVICE_PACKAGES.DISCOUNT400)}
                </Box>

              </span>
              <span className="greyBg">{t(translations.SERVICE_PACKAGES.PRIORITYSUPPORT)}</span>
              <span>{t(translations.SERVICE_PACKAGES.CPIOFFERED)}</span>
              <span className="greyBg">{t(translations.SERVICE_PACKAGES.REGOFFERED)}</span>
              <span>{t(translations.SERVICE_PACKAGES.DELIVERY50OFF)}</span>
              <span className="greyBg">{t(translations.SERVICE_PACKAGES.VALETSERVICE)}</span>
            </Box>
          </Box>
        )}
        {allCreditPlans &&
          allCreditPlans.map((plan, index) => {
            const credits = plan.prices[0];
            const amountInEuro = (credits.unit_amount / 100).toFixed(2);
            return (
              <>
                <Box
                  className={`flexColumn planSection ${credits.is_popular == 1 ? 'bluebg mt-10' : ''
                    } `}
                  key={`plan${plan.id}`}
                >
                  <Box className="plan">
                    {credits.is_popular ? (
                      <Box className={'popularTag'}>{t(translations.SERVICE_PACKAGES.MOST_RECOMMENDED)}</Box>
                    ) : null}
                    <Box className="planName">
                      <p>{plan.name}</p>
                      {credits.is_popular && (!isSmallDevice && !isMediumDevice) ? <SVGFeatured /> : ''}
                    </Box>
                    <p className="planPrice">{`€${numberFormat(amountInEuro)}*`}</p>
                    <Typography className="credits">
                      {t(translations.HOT_DEALS.CREDITS, {
                        credits: credits.credit_points ?? 0,
                        creditText:
                          credits.credit_points > 1
                            ? t(translations.invoiceScreen.CREDITS)
                            : t(translations.HOT_DEALS.CREDIT),
                      })}
                    </Typography>
                    <Box className={`validity`}>
                      <span >{t(translations.HOT_DEALS.VALIDITY)}: </span>
                      <span className="validity-days">
                        {' '}
                        {credits.validity_in_days > 1
                          ? t(translations.HOT_DEALS.DAYS, { days: credits.validity_in_days })
                          : t(translations.HOT_DEALS.DAY, { days: credits.validity_in_days })}
                      </span>
                    </Box>
                    <Button
                      onClick={() => {
                        handleBuyNow(credits.id, plan.stripe_product_id, credits.stripe_price_id);
                      }}
                      className={index === 1 ? 'blackButton' : 'whiteButton'}
                      disabled={!hasAddPermission && !hasUpdatePermission}
                    >
                      {t(translations.SERVICE_PACKAGES.BUY_NOW)}
                    </Button>
                    {credits.is_popular ? (
                      <p className="mostBought">
                        {t(translations.SERVICE_PACKAGES.OVER100PURCHASES, {
                          purchases: process.env.REACT_APP_PLAN_BOUGHT_COUNT,
                        })}
                      </p>
                    ) : (
                      ''
                    )}
                  </Box>
                  {(isLargeDevice || isExtraLargeDevice) && (
                    <Box className="featuresProvided">
                      <span></span>
                      <span className={index !== 1 ? `greyBg` : ''}>
                        {' '}
                        <SvgCheckNew />
                      </span>
                      <span>
                        <SvgCheckNew />
                      </span>
                      <span className={index !== 1 ? `greyBg` : ''}>
                        {index === 0 ? <SVGCross /> : <SvgCheckNew />}
                      </span>
                      <span>{index === 0 ? <SVGCross /> : <SvgCheckNew />}</span>
                      <span className={index !== 1 ? `greyBg` : ''}>
                        {index === 2 ? <SvgCheckNew /> : <SVGCross />}
                      </span>
                      <span>{index === 2 ? <SvgCheckNew /> : <SVGCross />}</span>
                      <span className={index !== 1 ? `greyBg` : ''}>
                        {index === 2 ? <SvgCheckNew /> : <SVGCross />}
                      </span>
                    </Box>
                  )}

                  {(isSmallDevice || isMediumDevice) && (
                    <Box className="featuresProvided">
                      <span>
                        {' '}
                        <SvgCircularCheck className="blueCheckSvg" />{' '}
                        {t(translations.SERVICE_PACKAGES.ACCESSTOSALES)}{' '}
                      </span>
                      <span>
                        <SvgCircularCheck className="blueCheckSvg" />
                        <Box><b style={{ marginRight: '2px' }}>€{process.env.REACT_APP_DEFAULT_DISCOUNT} </b> {t(translations.SERVICE_PACKAGES.DISCOUNT400)}</Box>
                      </span>
                      {index !== 0 && (
                        <>
                          <span>
                            {' '}
                            <SvgCircularCheck className="blueCheckSvg" />{' '}
                            {t(translations.SERVICE_PACKAGES.PRIORITYSUPPORT)}
                          </span>
                          <span>
                            {' '}
                            <SvgCircularCheck className="blueCheckSvg" />{' '}
                            {t(translations.SERVICE_PACKAGES.CPIOFFERED)}
                          </span>
                        </>
                      )}
                      {index === 2 && (
                        <>
                          <span>
                            {' '}
                            <SvgCircularCheck className="blueCheckSvg" />{' '}
                            {t(translations.SERVICE_PACKAGES.REGOFFERED)}
                          </span>
                          <span>
                            {' '}
                            <SvgCircularCheck className="blueCheckSvg" />
                            {t(translations.SERVICE_PACKAGES.DELIVERY50OFF)}
                          </span>
                          <span>
                            {' '}
                            <SvgCircularCheck className="blueCheckSvg" />{' '}
                            {t(translations.SERVICE_PACKAGES.VALETSERVICE)}
                          </span>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            );
          })}
      </Box>
    </>
  );
};

export default CreditFeatures;
