import { handleActions } from 'redux-actions';
import {
  userProfileSaveRequest,
  userProfileSaveSuccess,
  userProfileSaveFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  userAddressSaveRequest,
  userAddressSaveSuccess,
  userAddressSaveFailure,
  changeEmailRequest,
  changeEmailSuccess,
  changeEmailFailure,
  changeEmailActivateAccountRequest,
  changeEmailActivateAccountSuccess,
  changeEmailActivateAccountFailure,
  userProfileImageRequest,
  userProfileImageSuccess,
  userProfileImageReset,
  userProfileImageFailure,
  updateUserPrivacyRequest,
  updateUserPrivacySuccess,
  updateUserPrivacyFailure,
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataFailure,
  getUsersPermissionsRequest,
  getUsersPermissionsSuccess,
  getUsersPermissionsFailure,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountFailure,
  getCreditPlansRequest,
  getCreditPlansSuccess,
  getCreditPlansFailure,
  getPromoCodeFailure,
  getPromoCodeSuccess,
  getPromoCodeRequest,
  creditsHistoryRequest,
  creditsHistorySuccess,
  creditsHistoryFailure,
  creditsBalanceRequest,
  creditsBalanceSuccess,
  creditsBalanceFailure,
  creditsPaymentRequest,
  creditsPaymentSuccess,
  creditsPaymentFailure,


} from '../actions/user-actions';

const initialState = {
  loading: false,
  profileUpdated: false,
  profileUpdatedError: {},
  profileUpdatedData: {},
  addressUpdated: false,
  addressUpdatedFailData: {},
  addressUpdatedData: {},
  imageUpdated: false,
  imageUpdatedError: {},
  updatedImage: {},
  passwordUpdated: false,
  emailSend: false,
  emailSendErrors: {},
  saveError: false,
  activateAccountSuccess: {},
  activateAccountError: {},
  passwordUpdatedErrors: {},
  userData: {},
  userPrivacySuccess: false,
  permissionData: [],
  permissionError: {},
  deleteAccountStatus: false,
  userPrivacyFailure: {},
  userPrivacySuccessData: {},
  allCreditPlans: [],
  allPromoCodes: {},
  creditHistoryError: {},
  creditHistoryList: [],
  creditBalanceError: {},
  creditBalance: 0,
  creditHistoryLastPage: 0,
  creditPayment: {},
  creditPaymentError: {}
};

const user = handleActions(
  {
    [userProfileSaveRequest]: state => ({
      ...state,
      profileUpdated: false,
      loading: true,
    }),
    [userProfileSaveSuccess]: (state, { payload }) => ({
      ...state,
      profileUpdated: true,
      profileUpdatedData: payload,
      loading: false,
    }),
    [userProfileSaveFailure]: (state, { payload }) => ({
      ...state,
      profileUpdated: false,
      profileUpdatedError: payload.data,
      loading: false,
    }),
    [userAddressSaveRequest]: state => ({
      ...state,
      loading: true,
    }),
    [userAddressSaveSuccess]: (state, { payload }) => ({
      ...state,
      addressUpdated: true,
      addressUpdatedData: payload,
      loading: false,
    }),
    [userAddressSaveFailure]: (state, { payload }) => ({
      ...state,
      addressUpdated: false,
      loading: false,
      addressUpdatedFailData: payload.data
    }),
    [changePasswordRequest]: state => ({
      ...state,
      loading: true,
      passwordUpdated: false,
      passwordUpdatedErrors: {}
    }),
    [changePasswordSuccess]: (state, { payload }) => ({
      ...state,
      passwordUpdated: true,
      loading: false,
    }),
    [changePasswordFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      passwordUpdatedErrors: payload.data
    }),
    [changeEmailRequest]: state => ({
      ...state,
      loading: true,
      emailSend: false,
      emailSendErrors: {}
    }),
    [changeEmailSuccess]: (state) => ({
      ...state,
      emailSend: true,
      loading: false,
    }),
    [changeEmailFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      emailSend: false,
      emailSendErrors: payload.data
    }),
    [changeEmailActivateAccountRequest]: state => ({
      ...state,
      loading: true,
      activateAccountSuccess: {},
      activateAccountError: {}
    }),
    [changeEmailActivateAccountSuccess]: (state, { payload }) => ({
      ...state,
      activateAccountSuccess: payload.data,
      loading: false,
    }),
    [changeEmailActivateAccountFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      activateAccountError: payload.data
    }),
    [userProfileImageRequest]: state => ({
      ...state,
      loading: true,
      imageUpdated: false,
      imageUpdatedError: {},
    }),
    [userProfileImageSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      updatedImage: payload,
      imageUpdated: true
    }),
    [userProfileImageFailure]: (state, { payload }) => ({
      ...state,
      imageUpdatedError: payload,
      loading: false,
    }),
    [userProfileImageReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      imageUpdated: false,
      imageUpdatedError: {},
    }),
    [updateUserPrivacyRequest]: state => ({
      ...state,
      loading: true,
      userPrivacySuccess: false
    }),
    [updateUserPrivacySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      userPrivacySuccess: true,
      userPrivacySuccessData: payload.data.user
    }),
    [updateUserPrivacyFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      userPrivacyFailure: payload
    }),
    [getUserDataRequest]: state => ({
      ...state,
      loading: true,
      userData: {}
    }),
    [getUserDataSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      userData: payload.data.user
    }),
    [getUserDataFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
    }),
    [getUsersPermissionsRequest]: state => ({
      ...state,
      permissionsLoading: true,
      permissionData: [],
      permissionError: {},
    }),
    [getUsersPermissionsSuccess]: (state, { payload }) => ({
      ...state,
      permissionsLoading: false,
      permissionData: payload,
      permissionError: {},
    }),
    [getUsersPermissionsFailure]: (state, { payload }) => ({
      ...state,
      permissionError: payload,
      permissionsLoading: false,
    }),

    [deleteAccountRequest]: state => ({
      ...state,
      loading: true,
      deleteAccountStatus: false,
      deleteAccountError: {}
    }),
    [deleteAccountSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteAccountStatus: true,
      deleteAccountError: {}
    }),
    [deleteAccountFailure]: (state, { payload }) => ({
      ...state,
      deleteAccountStatus: false,
      deleteAccountError: payload.data,
      loading: false,
    }),

    [getCreditPlansRequest]: state => ({
      ...state,
      loading: true,
      allCreditPlans: []
    }),
    [getCreditPlansSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      allCreditPlans: payload.items,
      promoCode: payload.promo_code
    }),
    [getCreditPlansFailure]: (state, { payload }) => ({
      ...state,
      allCreditPlans: payload,
      loading: false,
    }),
    [getPromoCodeRequest]: state => ({
      ...state,
      loading: true,
      allPromoCodes: {}
    }),
    [getPromoCodeSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      allPromoCodes: payload
    }),
    [getPromoCodeFailure]: (state, { payload }) => ({
      ...state,
      allPromoCodes: payload,
      loading: false,
    }),
    [creditsHistoryRequest]: state => ({
      ...state,
      loading: true,
      creditHistoryList: [],
      creditHistoryError: {}
    }),
    [creditsHistorySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      creditHistoryList: payload.data.data,
      creditHistoryLastPage: payload.data.last_page,
      creditHistoryError: {}
    }),
    [creditsHistoryFailure]: (state, { payload }) => ({
      ...state,
      creditHistoryList: [],
      creditHistoryError: payload.data,

      loading: false,
    }),
    [creditsBalanceRequest]: state => ({
      ...state,
      loading: true,
      creditBalance: 0,
      creditBalanceError: {}
    }),
    [creditsBalanceSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      creditBalance: payload.data.item,
      creditBalanceError: {}
    }),
    [creditsBalanceFailure]: (state, { payload }) => ({
      ...state,
      creditBalance: 0,
      creditBalanceError: payload.data,

      loading: false,
    }),
    [creditsPaymentRequest]: state => ({
      ...state,
      loading: true,
      creditPayment: {},
      creditPaymentError: {}
    }),
    [creditsPaymentSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      creditPayment: payload.order,
      creditPaymentError: {}
    }),
    [creditsPaymentFailure]: (state, { payload }) => ({
      ...state,
      creditPayment: {},
      creditPaymentError: payload.data,

      loading: false,
    }),
  },
  initialState,
);

export default user;
