import styled from "styled-components";
import { color } from "styles/StyleConstants";

export const Wrapper = styled.div`
  .title{
    text-align: center;
    font-size: 32px;
  }
  .accountSelect{
    h5{
      letter-spacing: 0.5px;
    }
  }
  .private{
    border-bottom: 1px solid ${color.colorGray};
  }

  .scrolable-view {
    overflow-y: auto;
    padding: 2px 0 2px;
   }
  .form-check-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 20px;
    width: 20px;
    border: 3px solid #fff; 
    border-radius:10px;
    outline: 1px solid #000;/* Set the outline color to black */
    cursor: pointer;
    padding: 2px;
    margin: auto;
  }
  .form-check-input:checked {
    background-color: black; /* Change the background color to black when checked */
  }
  .switch_account  img {
      width: 100%;
      max-width: 36px;
      height: 36px;
      border-radius: 50%;
    }
   .loader {
      width: 100%;
      position: absolute;
    }
    .switch_wrapper {
      display: grid;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #eee;
      padding: 12px 8px;
      column-gap: 10px;
      margin-bottom: 8px;
      grid-template-columns: 87% 10%;
    }
    .switch_user {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  .switch_user h5 {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: .02em;
    font-weight: 400;
    margin: 0px;
    span{
      display: block;
    }
    div{
      display: inline;
    }
  }
  .switch_account {
    background: #FBFBFF;
    border-radius: 6px;
    min-width: 320px;    
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px 0px 0px 0px;
    }
   .switch_head svg{
      position: absolute;
      right: 10px;
      top: 10px;
    }
     .switch_head {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: .02em;
      padding: 12px;
    }
  
  .switch_company {
    padding: 12px 30px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
  }
  .switch_company span:last-child {
      color: #6579F4;
      font-size: 18px;
      padding-left: 12px;
      line-height: 22px;
    }       

  .sign_out {
    padding: 12px 30px;        
  }
  .sign_out span:last-child {
      color: #000000;
      font-size: 18px;
      padding-left: 12px;
      line-height: 22px;
    }
  .fixed-bottom {
    position: sticky;
    bottom: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    left: 0;
  }
  .no-border{
    border: none;
  }
  @media(max-width:992px){
    background-color: #F2F2F2;
    .switch_head{
      position: sticky;
      background-color: #F2F2F2;
      top: 0;
      z-index: 2;
    }
    .subsidiaryContainer{
      height: calc(100vh - 135px);
      overflow-x: auto;
    }
    .scrolable-view{
      padding: 0 8px;
      min-height: calc(50vh - 94px);
    }
    .switch_wrapper{
      background-color: white;
    }
    .scrolable-view{
      padding: 0 8px;
      min-height: calc(50vh - 94px);
    }
    .switch_wrapper{
      background-color: white;
    }
  }
`