import { all } from 'redux-saga/effects';
import { authSagas } from './auth-saga';
import { userSagas } from './user-saga';
import { companySagas } from './company-saga';
import { showcaseSaga } from './showcase-saga';
import { publicSaga } from './public-saga'
import { vehicleSagas } from './vehicle-saga';
import { chatsaga } from './chat-saga';
import { licensePlateSaga } from './licensePlate-saga';
import { publicProfileSaga } from './publicProfile-saga ';
import { onlineUserssaga } from './onlineUser-saga';
import { myAdsSaga } from './myAds-saga';
import { favoriteSagas } from './favorite-saga';
export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...userSagas,
    ...companySagas,
    ...showcaseSaga,
    ...publicSaga,
    ...vehicleSagas,
    ...chatsaga,
    ...licensePlateSaga,
    ...publicProfileSaga,
    ...onlineUserssaga,
    ...myAdsSaga,
    ...favoriteSagas
  ]);
}
