import React, { useEffect, useMemo, useState } from 'react'
import { Ad } from '../../interface/interface'
import ImageCarousel from 'app/components/imageCarousel'
import { Box, CircularProgress, Divider, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { adsTypeName, bidTYpes, getCurrentPrice, showAuctionsTo } from 'config/constants'
import { translations } from 'locales/translations'
import { getSubsidiaryStored, numberFormat, productTypes, trophy } from 'config/variables'
import { getURL, isAdFavourite } from 'config/appConfig'
import { routes } from 'routes/routes'
import { chatCases, createUserSubchat } from 'config/chatConfig'
import { BikeStepIcon, CarIcon } from 'app/Component2/Layouts/AdsLayouts/component/AdsListing/VehicleTypeIcon'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import Counter from '../counter/counter'
import Bid from '../bid/bid'
import AuctionEndAnimation from 'app/Component2/GlobalComponent/auctionEndingAnimation'
import AuctionSwitchProfile from 'app/Component2/Layouts/AdsLayouts/component/AdsListing/AuctionSwitchProfile'
import BidsTimerUserDetails from '../BidsTimerUserDetails'
import { PublicAdsWrapper } from '../../style/PublicAdsWrapper'




const PublicAd = ({ adDetail, removeAdsAuction, showUserDetails }: Ad) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const subsidiaryId = getSubsidiaryStored();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);

    const [openModalProgress, setOpenModalProgress] = useState<boolean>(false);
    const [openModalTrophyInfo, setOpenModalTrophyInfo] = useState<boolean>(false);

    const [isFocused, _isFocused] = useState<boolean>(false)
    const [bid, _bid] = useState<number>(() => {
        let difference = 100
        if (adDetail && Object.keys(adDetail).length > 0) {
            if (adDetail?.best_manual_bid_relation?.amount) {
                return adDetail.best_manual_bid_relation.amount + difference
            }
        }
        return difference
    })

    const [autoBid, _autoBid] = useState<boolean>(false)


    const [auctionTime, _auctionTime] = useState<string>()
    const [isActive, _isActive] = useState<boolean>(false)
    const [updateTimer, _updateTimer] = useState<boolean>(false)

    const [isContestEnding, _isContestEnding] = useState<boolean>(false)
    const [contestEndingMessage, _contestEndingMessage] = useState<string>('')

    const shareUrl = getURL(routes.vehicleRoutes.auctionAds + "/" + adDetail.slug);
    const { userData } = useSelector((state: RootStateOrAny) => state.user)

    const title = `${t(translations.webTexts.SHARED_FROM_CARTOBIKE)}` + '\n\n' +
        `${adDetail.owner_vehicle.full_title}` + '\n' +
        `${t(translations.ads.FIRSTUSE) + ':'} ${adDetail.owner_vehicle.new_vehicle ? t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW) : adDetail.owner_vehicle.vehicle_first_register_month && adDetail.owner_vehicle.vehicle_first_register_year ? " " + adDetail.owner_vehicle.vehicle_first_register_month + '/' + adDetail.owner_vehicle.vehicle_first_register_year : ""}` + '\n' +
        `${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_KM)}: ${numberFormat(adDetail.owner_vehicle.vehicle_mileage)} km` + '\n' +
        `${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_COUNTRY)}: ${adDetail.auction.auction_event.country.fullname}
        `+ '\n';

    const imageAltText = adDetail.owner_vehicle.full_title

    const currentPrice = useMemo(() => {
        return getCurrentPrice(adDetail, loggedIn, subsidiaryId, userData)
    }, [adDetail.id, loggedIn, subsidiaryId, adDetail?.best_bid?.amount, adDetail?.best_manual_bid_relation?.amount, userData])


    useEffect(() => {
        if (autoBid) {
            _autoBid(false)
        }
    }, [subsidiaryId])


    // Setting counter time and check if auction is active or not
    useEffect(() => {
        if (adDetail && Object.keys(adDetail).length > 0) {
            const auctionStartin = new Date(adDetail.auction.start).getTime();
            const auctionEndTime = new Date(adDetail.end_auction).getTime();
            const auctionEndTimeTemp = adDetail.end_auction;
            const currentTime = new Date().getTime();

            // initially counter will be of start time
            let counterTime = adDetail.auction.start;

            // Sets active as true and counter time as end auction time if auction has started ( start is greater than current time )
            if (currentTime > auctionStartin) {
                counterTime = auctionEndTimeTemp;
                _isActive(true);
            }
            // Sets active as false if current time get greater than auction end time
            if (currentTime > auctionEndTime) {
                _isActive(false);
            }
            _auctionTime(counterTime)
            _updateTimer(false)
        }
    }, [adDetail.end_auction, updateTimer])


    const handleChat = () => {
        if (loggedIn) {
            createUserSubchat(adDetail.id, adDetail.owner_vehicle_id, chatCases.ADS_DETAIL_PAGE_CHAT, dispatch)
        }
    }

    const handleNavigate = () => {
        history.push(routes.vehicleRoutes.auctionAds + "/" + adDetail.slug)
    }

    // according to the permission show user details section accordingly 
    const handleDisplayBiding = (adDetail) => {
        if (loggedIn) {
            if (!userData.profile_is_complete) return showAuctionsTo.UNCOMPLETE_PROFILE;
            if (adDetail.display_for == showAuctionsTo.DEALER && (subsidiaryId == null || subsidiaryId == undefined)) return showAuctionsTo.DEALER;
            if (adDetail.display_for == showAuctionsTo.PRIVATE && subsidiaryId) return showAuctionsTo.PRIVATE
            return showAuctionsTo.ALL
        }
        return showAuctionsTo.LOGGEDIN

    }

    return (
        <PublicAdsWrapper className='flexRow mainContainer auction-ads'>
            <Box className={(isLargeDevice || isExtraLargeDevice ? 'flexRow' : 'flexColumn') + ' adContainer'} id={adDetail.slug}>
                <Box className='imageContainer'>
                    <ImageCarousel adType={adsTypeName.auctionAds} images={adDetail?.owner_vehicle?.media ?? []} imageAltText={imageAltText} kmCount={adDetail?.owner_vehicle?.battery_range_max ?? 0} adId={adDetail.id} title={title} linkToShare={shareUrl} handleNavigate={handleNavigate} url={routes.vehicleRoutes.auctionAds + '/' + adDetail.slug} showLikeButton={false} showShareButton={true} />
                </Box>
                <Box className='adDetailContainer'>
                    <Box>
                        <Link to={{ pathname: routes.vehicleRoutes.auctionAds + "/" + adDetail.slug, state: adDetail.is_favorite }} onClick={() => localStorage.setItem('prevPath', location.pathname + location.search)}>
                            <Tooltip title={adDetail?.owner_vehicle?.full_title ?? "-"} placement='top-start'>
                                <h2 className='ad-name pointer'> {adDetail?.owner_vehicle?.full_title ?? "-"}</h2>
                            </Tooltip>
                        </Link>
                    </Box>
                    <Box>
                        {adDetail?.owner_vehicle?.product_type_id === productTypes.car ?
                            <CarIcon
                                regiterDate={
                                    adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW)
                                        : adDetail?.owner_vehicle?.new_vehicle === false && adDetail?.owner_vehicle?.vehicle_first_register_month != null ? adDetail?.owner_vehicle?.vehicle_first_register_month + "/" + adDetail?.owner_vehicle?.vehicle_first_register_year : ""
                                }
                                milege={adDetail?.owner_vehicle?.vehicle_mileage || 0}
                                gearBox={
                                    adDetail?.owner_vehicle?.gearbox?.name
                                }
                                power={
                                    adDetail?.owner_vehicle?.power_cv ? adDetail?.owner_vehicle?.power_cv + ' CV' : null
                                }
                                fuel={
                                    adDetail?.owner_vehicle?.fuel?.name
                                }
                            />
                            :
                            <BikeStepIcon
                                regiterDate={
                                    adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW)
                                        : adDetail?.owner_vehicle?.new_vehicle === false && adDetail?.owner_vehicle?.vehicle_first_register_month != null ? adDetail?.owner_vehicle?.vehicle_first_register_month + "/" + adDetail?.owner_vehicle?.vehicle_first_register_year : ""
                                }
                                gearBox={adDetail?.owner_vehicle?.gearbox?.name || ""}
                                topSpeed={adDetail?.owner_vehicle?.top_speed ? adDetail?.owner_vehicle?.top_speed + ' km/h' : ''}
                                isNew={adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW) : adDetail?.owner_vehicle?.vehicle_first_register_month || adDetail?.owner_vehicle?.vehicle_first_register_year ? t(translations.summaryVehicleScreen.USED) : null}
                                fuel={adDetail?.owner_vehicle?.is_electric ? t(translations.summaryVehicleScreen.ELECTRIC) : ''}
                                betterType={adDetail?.owner_vehicle?.battery_charging_time || ""}
                                power={adDetail?.owner_vehicle?.power_cv ? adDetail?.owner_vehicle?.power_cv + ' CV' : null}
                                frameSize={adDetail?.owner_vehicle?.frame_size?.name || ""}
                                bodyVehicle={adDetail?.owner_vehicle?.body_vehicle?.name || ""}
                                milege={adDetail?.owner_vehicle?.vehicle_mileage || null}
                                isMotorCycle={adDetail?.owner_vehicle?.product_type_id === productTypes.motorcycles}
                                motoBikefuel={adDetail?.owner_vehicle?.fuel?.name || ""}

                            />
                        }
                    </Box>
                    {!isSmallDevice && !isMediumDevice && <BidsTimerUserDetails
                        currentPrice={currentPrice}
                        setOpenModalProgress={setOpenModalProgress}
                        isActive={isActive}
                        adDetail={adDetail}
                        removeAdsAuction={removeAdsAuction}
                        handleChat={handleChat}
                        handleDisplayBiding={handleDisplayBiding}
                        auctionTime={auctionTime}
                        setOpenModalTrophyInfo={setOpenModalTrophyInfo}
                        openModalProgress={openModalProgress}
                        openModalTrophyInfo={openModalTrophyInfo}
                        _contestEndingMessage={_contestEndingMessage}
                        _isContestEnding={_isContestEnding}
                        isFocused={isFocused}
                        _isFocused={_isFocused}
                        bid={bid}
                        _bid={_bid}
                        autoBid={autoBid}
                        _autoBid={_autoBid}
                        _updateTimer={_updateTimer}
                        showUserDetails={showUserDetails}
                    />
                    }
                </Box>
            </Box>
            {(isSmallDevice || isMediumDevice) && <BidsTimerUserDetails
                currentPrice={currentPrice}
                setOpenModalProgress={setOpenModalProgress}
                isActive={isActive}
                adDetail={adDetail}
                removeAdsAuction={removeAdsAuction}
                handleChat={handleChat}
                handleDisplayBiding={handleDisplayBiding}
                auctionTime={auctionTime}
                setOpenModalTrophyInfo={setOpenModalTrophyInfo}
                openModalProgress={openModalProgress}
                openModalTrophyInfo={openModalTrophyInfo}
                _contestEndingMessage={_contestEndingMessage}
                _isContestEnding={_isContestEnding}
                isFocused={isFocused}
                _isFocused={_isFocused}
                bid={bid}
                _bid={_bid}
                autoBid={autoBid}
                _autoBid={_autoBid}
                _updateTimer={_updateTimer}
                showUserDetails={showUserDetails}
            />
            }

            {!isSmallDevice && !isMediumDevice &&
                <>
                    {handleDisplayBiding(adDetail) === showAuctionsTo.ALL ? <Box className='bidTimer flexColumn justifyContentStart alignItemsCenter'>
                        <Bid adDetail={adDetail} isActive={isActive} isFocused={isFocused} _isFocused={_isFocused} bid={bid} _bid={_bid} autoBid={autoBid} _autoBid={_autoBid} />
                        <Counter adDetail={adDetail} auctionTime={auctionTime} removeAdsAuction={removeAdsAuction} _isContestEnding={_isContestEnding} _contestEndingMessage={_contestEndingMessage} _updateTimer={_updateTimer} />
                    </Box>
                        : <Box className='bidTimer switchProfile flexColumn justifyContentStart alignItemsCenter'>
                            <AuctionSwitchProfile subsidiaryId={subsidiaryId} adDetail={adDetail} isActive={isActive} />
                            <Counter adDetail={adDetail} auctionTime={auctionTime} removeAdsAuction={removeAdsAuction} _isContestEnding={_isContestEnding} _contestEndingMessage={_contestEndingMessage} _updateTimer={_updateTimer} />
                        </Box>
                    }
                </>
            }

            {/* Animation appears when an auction is ending */}
            {
                isContestEnding && <AuctionEndAnimation endingMessage={contestEndingMessage} />
            }
        </PublicAdsWrapper>
    )
}

export default PublicAd