import PublicLayout from 'app/Component2/Layouts/PublicLayout'
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import { Box } from '@mui/material'
import { translations } from 'locales/translations'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { localstorageKey } from 'config/constants'
import { useEffect } from 'react'
import { hasPermissions, PERMISSIONS } from 'config/permission'
import { useDispatch, useSelector } from 'react-redux'
import PermissionComponent from 'app/Component2/GlobalComponent/PermissionComponent'
import { creditsBalanceRequest } from 'store/actions/user-actions'
import { CreditPageWrapper } from './style/styling'
import { SVGBulb } from 'app/components/svgicons/svg'
import { routes } from 'routes/routes'
import CreditFeatures from './components/creditFeatures'

const CreditPage = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch();

    const getPreviousUrl = localStorage.getItem(localstorageKey.buyCreditPage)

    const permissionList = useSelector((state: any) => state.user.permissionData)
    const hasReadPermission = hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.READ)

    const handleGoBack = () => {
        if (getPreviousUrl) {
            history.replace(getPreviousUrl)
            return;
        }
        history.goBack();
    }

    useEffect(() => {
        dispatch(creditsBalanceRequest())
    }, [])

    return (
        <PublicLayout
            showNavbar={true}
            hideHeader={false}
            backButton={<span className='back-button' onClick={handleGoBack}><SvgMobileLeftArrow /></span>}
        >
            <CreditPageWrapper>
                {hasReadPermission ?
                    <>
                        <Box className='header'>
                            <h1>{t(translations.SERVICE_PACKAGES.SERVICEPACKAGES)}</h1>
                            <p>*{t(translations.SERVICE_PACKAGES.SERVICEPACKAGESINFO)}</p>
                        </Box>

                        <CreditFeatures />

                        <Box className='termsConditions'>
                            <p>*
                                <Trans
                                    i18nKey={t(translations.SERVICE_PACKAGES.ACKNOWLEDGEMENT)}
                                    components={{
                                        a: <Link to={routes.publicRoutes.terms} className='anchor' />,
                                        a1: <Link to={routes.publicRoutes.legal_documents} className='anchor' />
                                    }}
                                />
                            </p>
                            <p>{t(translations.SERVICE_PACKAGES.TERMS)}</p>

                        </Box>
                        <Box className='featuresContainer'>
                            <Box className='flexRow'>
                                <SVGBulb />
                                <p>
                                    {t(translations.SERVICE_PACKAGES.EXCLUSIVEACCESS)}
                                </p>
                            </Box>
                            <ul>
                                <li>{t(translations.SERVICE_PACKAGES.FINDUSERBYLICENSEPLATE)}</li>
                                <li>
                                    <Trans
                                        i18nKey={t(translations.SERVICE_PACKAGES.HOTDEALPARTICIPATIONOFFER)}
                                        components={{
                                            span: <span className='red' />
                                        }}
                                    />
                                </li>
                                <li>{t(translations.SERVICE_PACKAGES.HOTDEALPARTICIPATION)}</li>
                            </ul>


                        </Box>
                    </>
                    :
                    <PermissionComponent />
                }

            </CreditPageWrapper>
        </PublicLayout>
    )
}

export default CreditPage