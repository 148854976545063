import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const CreditPageWrapper = styled.div`
  font-family: Rubik;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 14px ;
  gap: 30px;  
  display: flex;
  flex-direction: column;
  min-height: 495px;
  p,h2,h1{
    margin: 0;
  }
  .header{
    margin: 48px 0 0px 0;
    h1{
      font-size: 40px;
      font-weight: 600;
      line-height: 29.16px;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 10px;
    }
    p{
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 50px;
      color: #334155;
    }

  }
  .termsConditions{
    max-width: 40%;
    p{
      font-family: Rubik;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 28px;
      color: #475569;
    }
  }
  .anchor{
    color: #5667D0;
    text-decoration: underline;
  }
  .featuresContainer{
      background-color: #FAFAFA;
      display: flex;
      flex-direction: column;
      padding: 24px;
      svg{
        width : 40px;
        height : 40px;
        margin-right: 10px;
      }
      p{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        max-width: 350px;
      }
      ul{
        margin-left: 27px;
        li{
          font-family: Rubik;
          font-size: 15px;
          font-weight: 400;
          line-height: 25.5px;
          letter-spacing: 0.015em;
          color: #666666;
          .red{
            color: #FF0202
          }
        }
      }
  }
  .plansContainer{
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
  }
  .credit-purchase-section {
    background: #e4f9e9;
    display: flex;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--Color-Success-600, #0d7c36);
    flex-direction: row;
    margin: 10px 0;
    align-items: center;
    font-family: 'Rubik';
    .credit-purchase-top-bar {
      width: 50%;
      flex-direction: row;
      flex: 1;
      span {
        color: var(--Color-Success-600,#16A34A);
      }
    }
    .credit-coupon-section {
      width: 50%;
      display: flex;
      flex-direction: column;
      color: var(--Color-Success-600,#16A34A);
      p {
        float: right;
        display: block;
        text-align: right;
      }
      span {
        float: right;
        display: block;
        border: 1px dashed var(--Color-Success-600, #0d7c36);
        padding: 5px 20px;
        border-radius: 5px;
      }
    }
}
  .planSection{
    width: 25%;
    padding-top: 32px;
    position: relative;
    .plan{
      display: flex;
      flex-direction: column;
      min-height: 305px;
      padding: 0 24px;
      .planName{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 56px;
        p{
          font-family: Rubik;
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
        }
      }
      .planPrice{
        font-family: Rubik;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
        margin-top: 10px;
      }
      .credits{
          font-family: 'Rubik';
          font-size: 18px;
          font-weight: 600;
          line-height: 29.16px;
          letter-spacing: 0.015em;
          text-align: left;
        }
        .validity{
          span:first-child {
            padding: 0px;
          }
          font-family: Rubik;
          font-size: 15px;
          font-weight: 350;
          line-height: 24.3px;
          letter-spacing: 0.015em;
        }
        .validity-days {
          font-weight: 500;
          text-transform: lowercase;
        }
    }
    span{
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #334155;
      padding: 0 24px;
    }
  }
  .popularTag{
    background-color: #EAB308;
    color: white;
    border-radius: 6px 6px 0 0;
    position: absolute;
    top: -30px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding: 5px 25px;
    left:50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .featuresProvided{
    display: flex;
    flex-direction: column;
    span{
      min-height: 50px;
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
  .bluebg{
    background-color: #F1F5F9;
  }
  .sectionHeader, .featuresProvided .sectionHeader {
    font-family: Rubik;
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    color: #475569;
  }

  .sectionHeader{
    line-height: 56px;
  }
  button{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    border-radius: 4px;
    margin-top: 30px;
    min-height: 44px;
  }
  .blackButton{
    background-color: #1E293B;
    color: white;
  }
  .whiteButton{
    color: #475569;
    border: 1px solid #CBD5E1;
  }
  .validity-days{
    padding: 0 !important;
  }
  .mostBought{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #334155;
    margin-top: 12px;
  }
  .bold{
    font-weight: bolder !important;
    margin-right: 4px !important;
    padding: 0 !important;
  }
  .rightBorder{
    border-right: 1px solid #E2E8F0;
  }
  @media (min-width :993px){
    .greyBg{
      background-color: #FAFAFA;
    }
  }
  
  @media (max-width :992px){
    gap: 20px;
    .featuresProvided {
      span {
        line-height: 25px;
        padding-top: 25px;
      }
    }
    .header{
      margin: 0;
      h1{
        font-size: 21px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.02em;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .mt-10{
      margin-top: 10px;
    }
    .plansContainer{
      flex-direction:column;
      gap:28px;
      border: none;
      .planSection{
        width: 100%;
        border: 1px solid #E2E8F0;
        border-radius: 30px;
        .planName {
          p {
           font-weight: 600;
          }
        }
        .planPrice {
          font-size: 36px;
          line-height: 44px;
        }
        .credits, .validity {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        .validity {
          span:first-child {
            color: #64748B;
            padding: 0px;
          }
          font-weight: 400;
        }
      }
    }
    .planSection{
      padding: 16px 0 32px 0px;
      .plan{
        min-height: auto;
      }
    }
    .planSection:first-child {
      margin-bottom: 20px;
    }
    .termsConditions{
      max-width: 100%;
      p {
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 10px
      }
    } 
    .featuresContainer{
      ul{
        margin-left: 0;
        li {
          font-size: 12px;
          line-height: 20px;
        }
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .blueCheckSvg{
      margin-right: 10px;
      min-width: 24px;
      min-height: 24px;
    }
  }
  @media screen and (max-width: 1320px) {
    .scetionHeader, .featuresProvided .sectionHeader {
      font-size: 21px;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 768px) {
    .credit-purchase-section {
      flex-direction: column;
      .credit-purchase-top-bar, .credit-coupon-section {
        width: 100%
      }
      .credit-coupon-section {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        p {
          text-align: left;
          width: 50%
        }
        div {
          width: 50%
        }
      }
    }
  }
`