import { useEffect, useState } from 'react'
import { SvgChevronLeft, SvgClose } from 'app/components/svgicons/svg'
import { useWindowSize } from 'app/Hooks/windowResize'
import moment from 'moment'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getInvoiceDetailFailure, getInvoiceDetailRequest, getInvoiceDetailReset } from 'store/actions/company-actions'
import PropTypes from 'prop-types';
import { CountriesDrop1, getSubsidiaryStored, numberFormat } from 'config/variables'
import download from 'downloadjs'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Toaster } from 'services/Toaster'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader'
import { routes } from 'routes/routes'
import { Box, Tooltip } from '@mui/material'
import { InvoiceWrapper } from './style/InvoiceWrapper'
import { SvgMobileLeftArrow, SvgpdfIcon } from 'app/components/svgicons/svgNew'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import { translations } from 'locales/translations'
import Api from 'services/axios'
import UserLayout from 'app/Component2/Layouts/UserLayout/UserLayout'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PayNowButton from './PayNowButton'
import { PERMISSIONS, hasPermissions } from 'config/permission'
import PermissionComponent from 'app/Component2/GlobalComponent/PermissionComponent'


const InvoicPropCompoent = (props: any) => {

    //  local states
    const [billDetails, setBillDetails] = useState<any>();
    const [streetNumber, setStreetNumber] = useState<string>();
    const [route, setRoute] = useState<string>();
    const [locality, setLocality] = useState<string>();
    const [postalCode, setPostalCode] = useState<string>();
    const [vatNumber, setVatNumber] = useState<string>();
    const [country, setCountry] = useState<string>();
    const [clientSecret, setClientSecret] = useState<string>();
    const [paymentMethod, setPaymentMethod] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false)

    // redux states
    const { userData } = useSelector((state: RootStateOrAny) => state.user)
    const { invoiceData, subsidiaryDetails, invoiceDetailError } = useSelector((state: any) => state.company)
    const { hideDetailInvoice } = props

    // hooks
    const [mobile] = useWindowSize();
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch();

    // params from url
    const { invoice_id: invoiceId } = useParams();

    // 
    const subId = getSubsidiaryStored();
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)
    const permissionList = useSelector((state: any) => state.user.permissionData)

    // check weather user have permissions to view this invoice or not
    const isAccessible = subId ? hasPermissions(permissionList, PERMISSIONS.FEATURES.INVOICE_SUBSCRIPTION, PERMISSIONS.ACTIONS.READ) : true;


    // function to get user addresses
    const getAddress = (subsidiaryId) => {
        if (subsidiaryId) {
            return subsidiaryDetails?.address_principal ?? {}
        }
        return userData?.addresses?.length > 0 ? userData.addresses[0] : {}
    }

    useEffect(() => {
        return (() => {
            dispatch(getInvoiceDetailReset())
        })
    }, [])

    useEffect(() => {
        if (invoiceId) {
            dispatch(getInvoiceDetailRequest({
                id: invoiceId,
                subsidiaryId: subId
            }))
        }
    }, [invoiceId])

    useEffect(() => {
        if (Object.keys(invoiceData).length > 0) {
            setBillDetails(invoiceData.invoice)
            if ('paymentIntent' in invoiceData) {
                if (invoiceData.paymentIntent !== null && 'item' in invoiceData.paymentIntent) {
                    setClientSecret(invoiceData.paymentIntent.item.client_secret)
                    if (invoiceData.paymentIntent.item.payment_method !== null) {
                        setPaymentMethod(invoiceData.paymentIntent.item.payment_method.id)
                    }
                }
            }
        }

    }, [invoiceData])



    useEffect(() => {
        if (invoiceDetailError && Object.keys(invoiceDetailError).length > 0) {
            Toaster.error(invoiceDetailError?.data?.message)
            dispatch(getInvoiceDetailFailure({}))
        }
    }, [])

    useEffect(() => {
        // set address details for loggedIn user
        const address = getAddress(subId)
        setVatNumber(subsidiaryDetails.vat_number || '0');
        if (address && Object.keys(address).length > 0) {
            setStreetNumber(address.street_number || '');
            setRoute(address.route || '');
            setLocality(address.locality || '');
            setPostalCode(address.postal_code || '');
            getCountry(address.country_id)
        }
    }, [subId, userData.id])

    const getCountry = (id) => {
        CountriesDrop1().then(data => {
            data.map((country, i) => {
                if (country.id == id) {
                    setCountry(country.fullname.toLowerCase())
                }
            });
        })
    }

    // function  for downloading pdf
    const downloadPdf = (id) => {
        let download_url = `${routes.companyRoutes.invoices}/${id}/download`;
        // API request for download invoice in pdf format
        Api.get(download_url, { responseType: 'blob' })
            .then((response) => {
                const content = response.headers['content-type'];
                download(response.data, `${billDetails ? billDetails.item.number : 'invoice'}.pdf`, content)
            })
    }


    return (
        <UserLayout
            title={t(translations.invoiceScreen.INVOICES)}
        >
            {isAccessible ?
                <>
                    {loading && <ContentLoader />}
                    {billDetails ?
                        <InvoiceWrapper className="bill-props-wrapper">
                            <div className="bill-header-section">
                                <div className="bill_banner">
                                    <SvgMobileLeftArrow onClick={() => history.goBack()} className='pointer' />
                                    <div className="bill-left-icon d-inline pointer" onClick={() => hideDetailInvoice()}>
                                        {mobile ?
                                            <SvgChevronLeft />
                                            :
                                            <SvgClose />
                                        }
                                    </div>


                                    <Tooltip disableFocusListener title={t(translations.invoiceScreen.DOWNLOAD_INVOICE)} placement="left">
                                        <ButtonCustom textBtn={t(translations.invoiceScreen.DOWNLOAD_INVOICE)} icon={<SvgpdfIcon />} clickFunction={() => downloadPdf(invoiceId)} style={{
                                            'span': {
                                                'svg': {
                                                    width: 25,
                                                    height: 25
                                                }
                                            },
                                        }} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Box className="invoice-detail-body">
                                <Box className='action-box'>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            fontSize: "20px",
                                            pb: "12px"
                                        }}
                                    >
                                        <span>{t(translations.invoiceScreen.DATE)}: {moment(billDetails.item.created * 1000).format("DD/MM/YYYY")}</span>
                                        <span >{billDetails.item.number}</span>
                                    </Box>
                                    <Elements stripe={stripePromise} options={{ locale: `${i18n.language}` }}>
                                        <PayNowButton invoiceDetail={billDetails.item} clientSecret={clientSecret} paymentMethod={paymentMethod} setLoading={setLoading} invoiceData={invoiceData} />
                                    </Elements>
                                </Box>
                                <Box className="address-vat d-flex justify-content-between pb-3 px-2">
                                    <h2>{billDetails?.item?.customer_name}</h2>
                                    <Box className="invoice-address">
                                        <Box>
                                            {billDetails?.item?.customer_address?.line1 && (
                                                <>
                                                    {billDetails.item.customer_address.line1}<br />
                                                </>
                                            )}
                                            {billDetails?.item?.customer_address?.line2 && (
                                                <>
                                                    {billDetails.item.customer_address.line2}<br />
                                                </>
                                            )}
                                            {billDetails?.item?.customer_address?.city && (
                                                <>
                                                    {billDetails.item.customer_address.city}<br />
                                                </>
                                            )}
                                            {(billDetails?.item?.customer_address?.state && billDetails?.item?.customer_address?.postal_code) && (
                                                <>
                                                    {billDetails.item.customer_address.state} {billDetails.item.customer_address.postal_code}
                                                </>
                                            )}
                                            <Box>{billDetails?.item?.customer_address?.country && (
                                                <>
                                                    {billDetails.item.customer_address.country}<br />
                                                </>
                                            )} </Box>
                                        </Box>
                                        {subId && <Box>
                                            {t(translations.ads.VAT)}: {billDetails?.item?.vat_number}
                                        </Box>}
                                    </Box>
                                </Box>
                                <Box className={`bill-item-wrapper py-3 ${mobile && 'px-2'}`}>
                                    {billDetails?.item?.lines?.data && Object.keys(billDetails?.item?.lines?.data).length > 0 ?
                                        <>
                                            <label className="plan-name mb-1"><b>{t(translations.invoiceScreen.PLANNAME)} </b></label>
                                            {billDetails.item.lines.data.map((billDetailDescription, i) => (
                                                <Box key={i} className="plan_details">
                                                    <Box className='bill-item d-flex justify-content-between'>
                                                        <Box className='bill-item-validity-and-name'>
                                                            {billDetailDescription.description}
                                                        </Box>
                                                        {billDetails.item.subscription && <label className={`plan-name ${i + 1 !== billDetails.item.lines.data.length ? 'mb-3' : ''}`}>{t(translations.webTexts.PERIOD)} ( {moment(billDetailDescription.period.start * 1000).format("DD/MM/YYYY")} - {moment(billDetailDescription.period.end * 1000).format("DD/MM/YYYY")})</label>}
                                                    </Box>
                                                    <Box className="bill-item-amount align-self-center">€ {
                                                        numberFormat(((billDetailDescription.amount || 0) / 100).toFixed(2))}</Box>
                                                </Box>
                                            ))}
                                        </> : null
                                    }
                                </Box>
                                <div className={`principal-total-wrapper py-2 ${mobile && 'px-2'}`}>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{t(translations.invoiceScreen.SUBTOTAL)} </label>
                                        <span>€ {numberFormat(((billDetails.item.subtotal || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    {billDetails?.item?.total_discount_amounts?.length > 0 ?
                                        billDetails.item.total_discount_amounts[0].amount != 0 ?
                                            <div className='d-flex justify-content-between mb-1'>
                                                <label>{t(translations.invoiceScreen.DISCOUNT)} </label>
                                                <span>{
                                                    billDetails.item.total_discount_amounts.length > 0 ?
                                                        '- €' + " " + numberFormat((billDetails.item.total_discount_amounts[0].amount / 100).toFixed(2))
                                                        : null
                                                }
                                                </span>
                                            </div>
                                            : null
                                        : null
                                    }
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label> {t(translations.invoiceScreen.TOTAL_EXCLUDING_TAX)} </label>
                                        <span>€ {numberFormat(((billDetails.item.total_excluding_tax || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{t(translations.ads.VAT)}</label>
                                        <span>€ {numberFormat(((billDetails.item.tax || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{t(translations.invoiceScreen.INVOICETOTAL)}</label>
                                        <span>€ {numberFormat(((billDetails.item.amount_due || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <label>{t(translations.invoiceScreen.PAID)}</label>
                                        <span> € {numberFormat(((billDetails.item.amount_paid || 0) / 100).toFixed(2))}</span>
                                    </div>
                                </div>
                                <div className="grand-total py-3">
                                    <div className={`d-flex justify-content-between ammount_box ${mobile && 'px-2'}`}>
                                        <label>{t(translations.invoiceScreen.AMOUNTDUE)} </label>
                                        <span className={`${((billDetails.item.amount_due || 0) / 100) - ((billDetails.item.amount_paid || 0) / 100) == 0 ? 'text-success' : null}`} >€
                                            {numberFormat((((billDetails.item.amount_due || 0) / 100) - ((billDetails.item.amount_paid || 0) / 100)).toFixed(2))}
                                        </span>
                                    </div>
                                </div>
                            </Box>
                        </InvoiceWrapper>
                        : null
                    }
                </>
                :
                <PermissionComponent innerCom={true} />
            }
        </UserLayout >)
}

InvoicPropCompoent.propTypes = {
    invoiceDetialId: PropTypes.any,
    getUpdatedData: PropTypes.func,
    hideDetailInvoice: PropTypes.func,
    billId: PropTypes.any,
    paySucceded: PropTypes.func
}


export default InvoicPropCompoent;