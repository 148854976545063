import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { CountryDropDown } from 'app/Component2/GlobalComponent/CountryDropDown';
import { EmailValidationRegex, NumberRepetitionValidationRegex, PhoneValidationRegex, Strings, becompartnerScreen, getMobileOperatingSystem, getSubsidiaryStored, onlyNumberType } from 'config/variables';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PartnersRequest, getPartnerOptionRequest } from 'store/actions/public-actions';
import { colorNew } from 'styles/StyleConstants';
import Checkbox from '@mui/material/Checkbox';
import { Toaster } from 'services/Toaster';
import { translations } from 'locales/translations';
import PublicLayout from 'app/Component2/Layouts/PublicLayout';

export const BecomePartner = () => {
    const { t } = useTranslation()
    //country code && prefix of Belgium
    let defaultcountry = '2';
    let defaultCountryPrefix = '+32'
    // Text area limit
    let textLimit = 500;
    // states
    const [phone, _phone] = useState<string>('')
    const [phoneError, _phoneError] = useState<string>('')
    const [email, _email] = useState<string>('')
    const [emailError, _emailError] = useState<string>('')
    const [companyName, _companyName] = useState<string>('')
    const [companyNameError, _companyNameError] = useState<string>('')
    const [shortMessage, _shortMessage] = useState<string>('')
    const [informationChecked, _informationChecked] = useState<boolean>(false)
    const [subjectId, _subjectId] = useState<number | string>('')
    const [salutationId, _salutationId] = useState<number | string>('')
    const [salutationError, _salutationError] = useState<string>('')
    const [subjectError, _subjectError] = useState<string>('')
    const [countryCode, _countryCode] = useState<any>(defaultCountryPrefix)
    const [countryId, _countryId] = useState<any>(defaultcountry);
    const [, _countryError] = useState<string>('');
    const [dataSubmitted, _dataSubmitted] = useState<boolean>(false);
    const { partnerOptionSalutation, partnerOptionSubject, partnersError, partners } = useSelector((state) => state.publicReducer)
    const dispatch = useDispatch();
    const textareaRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Hooks
    useEffect(() => {
        if (Object.keys(partnerOptionSalutation).length < 1) {
            //  here for salutation drop down ata
            dispatch(getPartnerOptionRequest({ type: becompartnerScreen.salutation }))
        }
        if (Object.keys(partnerOptionSubject).length < 1) {
            //  here for subject drop down data
            dispatch(getPartnerOptionRequest({ type: becompartnerScreen.subject }))
        }
    }, [])

    useEffect(() => {
        if (Object.keys(partnersError).length > 0) {
            if (partnersError.data.email) {
                _emailError(partnersError.data.email)
            }
        }
        if (Object.keys(partners).length > 0) {
            if (partners.message && dataSubmitted) {
                Toaster.success(partners.message)
                _dataSubmitted(false)
                _subjectId('')
                _salutationId('')
                _email('')
                _phone('')
                _shortMessage('')
                _informationChecked(false)
                _companyName('')
            }
        }
    }, [partnersError, partners])

    // Example usage: scroll to the bottom on initial render or whenever content changes
    useEffect(() => {
        scrollToBottom();
    }, [shortMessage])

    // Function to scroll the textarea to the bottom
    const scrollToBottom = () => {
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    };

    const handleChange = () => {
        _informationChecked(!informationChecked)
    }
    const handleChangeYouAre = () => {
        _salutationError('')
    }
    const handleChangeSubject = () => {
        _subjectError('')
    }
    const handleSubmitData = () => {
        _dataSubmitted(true)
        if (!salutationId) {
            _salutationError(Strings.required)
        }
        if (!countryId) {
            _countryError(Strings.required)
        }
        if (!subjectId) {
            _subjectError(Strings.required)
        }
        if (!companyName) {
            _companyNameError(Strings.required)
        }
        if (!phone) {
            _phoneError(Strings.required)
        }
        // Phone number validation
        if (phone) {
            if (!PhoneValidationRegex.phone.test(phone)) {
                _phoneError(Strings.phone);
                return false;
            }
            if (NumberRepetitionValidationRegex.phone.test(phone)) {
                _phoneError(Strings.phoneNumberRepetition);
                return false;
            }
            _phoneError('');
        }
        if (!EmailValidationRegex.email.test(email) && email) {
            _emailError(Strings.email);
            return false;
        }
        if (!email) {
            _emailError(Strings.required)
        }
        if (!salutationId || !countryId || !subjectId || !companyName || !phone || !email) {
            return false
        }
        dispatch(PartnersRequest({ country_id: countryId, salutation_id: salutationId, subject_id: subjectId, company_name: companyName, email: email, phone: phone, message: shortMessage, event_notifications: informationChecked ? 1 : 0 }))
    }
    const countryhandler = (data) => {
        _countryCode(data.code);
        _countryId(data.id);
    }

    return (
        <PublicLayout
            meta={t(translations.FOOTER.FOOTER_PARTNER) + ' - Cartobike'}
            description={t(translations.webTexts.BECOME_PARTNER_MESSAGE)}
            image={process.env.REACT_APP_BASE_URL + '/content.jpg'}
            type="website"
            showNavbar={true}
            hideHeader={false}
        >
            <Box
                sx={{
                    margin: "24px",
                    border: "solid 1px #cccccc6b",
                    padding: '30px',
                    borderRadius: "2px"
                }}
            >
                <Box sx={{
                    borderBottom: 'solid 1px #ccccccb8',
                    paddingBottom: '30px'

                }}>
                    <h2>{t(translations.webTexts.PARTNER)}</h2>
                    <Typography sx={{
                        fontSize: "20px",
                        color: colorNew.colorGrayDark2
                    }}>
                        {t(translations.webTexts.BECOME_PARTNER_MESSAGE)}
                    </Typography>
                </Box>
                <Box sx={{
                    maxWidth: "450px",
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: "50px auto 0",
                    gap: '16px',
                    '.required-self': {
                        color: colorNew.colorRed
                    }
                }}>
                    <Box sx={{}}>
                        <FormControl fullWidth className='member_field'>
                            <InputLabel id="demo-simple-select-label">{t(translations.webTexts.YOU_ARE) + "*"}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t(translations.webTexts.YOU_ARE) + "*"}
                                value={salutationId}
                                onChange={handleChangeYouAre}
                                inputProps={{ 'aria-label': ' label' }}
                            >
                                {/* <MenuItem value={''}></MenuItem>              */}
                                {partnerOptionSalutation?.items?.length > 0 && partnerOptionSalutation.items.map((salutation, i) => (
                                    <MenuItem onClick={() => _salutationId(salutation.id)} value={salutation.id}>{salutation.name}</MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                        <span className="required-self">{salutationError}</span>
                    </Box>
                    <Box>
                        <FormControl fullWidth className='member_field'>
                            <InputLabel id="demo-simple-select-label">{t(translations.webTexts.SUBJECT) + "*"}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t(translations.webTexts.SUBJECT) + "*"}
                                onChange={handleChangeSubject}
                                value={subjectId}
                            >
                                {/* <MenuItem value={''}></MenuItem>                      */}
                                {partnerOptionSubject?.items?.length > 0 && partnerOptionSubject.items.map((subject, i) => (
                                    <MenuItem onClick={() => _subjectId(subject.id)} value={subject.id}>{subject.name}</MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                        <span className="required-self">{subjectError}</span>
                    </Box>
                    <form>
                        <Box className="company">
                            <TextField
                                type="text"
                                fullWidth
                                id="inputEmail"
                                label={t(translations.createCompanyScreen.COMPANYNAME) + '*'}
                                className="form-control"
                                placeholder={t(translations.createCompanyScreen.COMPANYNAME)}
                                name="companyName"
                                value={companyName}
                                onChange={e => {
                                    _companyNameError('')
                                    _companyName(e.target.value)
                                }}
                            />
                            <span className="required-self">{companyNameError}</span>
                        </Box>
                        <Box className="email" sx={{ mt: 2 }}>
                            <TextField
                                type="email"
                                fullWidth
                                id="inputEmail"
                                label={t(translations.formText.Email) + '*'}
                                className="form-control"
                                aria-describedby="email"
                                placeholder="pro-mail@company.com"
                                name="emailPro"
                                value={email}
                                onChange={e => {
                                    _emailError('')
                                    _email(e.target.value.toLowerCase())
                                }}
                            />
                            <span className="required-self">{emailError}</span>
                        </Box>
                        <Box className="input-wrapper">
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    '.MuiFormControl-root': {
                                        marginBottom: 0,
                                    },
                                    ' > .MuiBox-root': {
                                        mr: 3
                                    },
                                    '.country-drop': {
                                        border: `1px solid rgb(0 0 0 / 23%)`,
                                        height: '100%',
                                        '.icon-hold': {
                                            'img': {
                                                width: 60,
                                                height: 31,
                                            }
                                        }
                                    }
                                }}
                            >
                                <CountryDropDown
                                    countryhandler={countryhandler}
                                    classes={`s-hide-fullname s-hide-name d-hide-fullname d-hide-name s-hide-code`}
                                    default={countryId}
                                />
                                <TextField
                                    id="outlined-basic"
                                    type={getMobileOperatingSystem() === 'iOS' ? 'tel' : 'number'}
                                    label={t(translations.formText.Phone) + '*'}
                                    variant="outlined" value={phone}
                                    onChange={(e) => {
                                        _phoneError('')
                                        _phone(e.target.value)
                                    }}
                                    onKeyDown={(evt) => onlyNumberType(evt)}

                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
                                    }}
                                    sx={{
                                        width: '100%'
                                    }}
                                />

                            </Box>
                            <span className="required-self">{phoneError}</span>
                        </Box>
                        <Box sx={{
                            mt: 2,
                            position: "relative",

                            'textarea': {
                                resize: 'none',
                                width: "100%",
                                height: '170px',
                                border: `1px solid rgb(0 0 0 / 23%)`,
                                borderRadius: 1,
                                outline: 'none',
                                padding: "16px 14px 30px 16px"
                            },
                        }}>
                            <Box sx={{
                                height: '170px',
                            }}>
                                <textarea ref={textareaRef} name="shortMessage" placeholder={t(translations.formText.DESCRIPTION_MESSAGE)} value={shortMessage} maxLength={500}
                                    onChange={(e: any) => {
                                        if (e.target.value.length < 501) {
                                            _shortMessage(e.target.value)
                                        }
                                    }}
                                    onPaste={(e: any) => {
                                        //    Message should not exceeded more then 500 words on pasting.
                                        if (e.target.value.length < 501) {
                                            _shortMessage(e.target.value)
                                        } else {
                                            _shortMessage(e.target.value.slice(0, e.target.maxLength))
                                        }
                                    }}
                                >
                                </textarea>
                            </Box>
                            <Box sx={{
                                display: 'block',
                                position: "absolute",
                                bottom: "1px",
                                right: "16px",
                                background: "white",
                                width: 'calc(100% - 17px)',
                                padding: "1px 16px 1px 16px",
                                textAlign: "right"

                            }}>
                                <div className='count'>
                                    <span>{shortMessage.length}/{textLimit}</span>
                                </div>
                            </Box>

                        </Box>

                        <Box className='popup_des'
                            sx={{
                                display: 'flex',
                                marginTop: '15px',
                                ' svg:not([fill])': {
                                    color: colorNew.primaryColor,
                                    mr: 1
                                },
                                'span': {
                                    padding: '0px',
                                    alignItems: 'baseline'
                                }
                            }}
                        >
                            <Checkbox checked={informationChecked} onChange={handleChange}
                                aria-label="Checkbox demo" color={'error'}
                                value="1"
                                size="medium"
                            />
                            <Typography> {t(translations.webTexts.BE_INFORMED)}</Typography>
                        </Box>
                        <ButtonCustom clickFunction={() => handleSubmitData()}
                            textBtn={t(translations.ads.SEND)} btnColor="primary"
                            style={{
                                width: '100%',
                                maxWidth: "200px",
                                padding: '0px 15px 0px',
                                fontSize: '24px',
                                borderRadius: '0px',
                                marginTop: '30px',
                                mb: 2,
                                textTransform: "uppercase",
                                float: "right",

                            }}
                        />
                    </form>
                </Box>
            </Box>
        </PublicLayout>
    )
}

export default BecomePartner;