import styled from 'styled-components';
import { colorNew } from 'styles/StyleConstants';

export const PublicAdsWrapper = styled.div`
  span {
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    color: #000;
  }
  input:focus {
    outline: none;
  }
  .filterBox {
    width: 20%;
  }
  .filterBox > #filterContainer {
    position: sticky;
    top: 135px;
  }
  .listContainer {
    width: 80%;
  }
  .homeMainContainer {
    max-width: 2640px;
    width: 100%;
    margin: 0 auto;
  }
  .not-found-wrapper {
    width: 100% !important;
    padding: 0 !important;
    border: 0 !important;
    .not-found-message {
      max-width: 100% !important;
    }
  }

  .scrollTop {
    position: fixed;
    background: #e3e3e3;
    width: 50px;
    height: 50px;
    bottom: 90px;
    right: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scrollTop svg {
    transform: rotate(90deg);
  }
  // -------------------------------------------------Header-------------------------------------------------
  .header {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0.42px;
    margin: 0;
    width: 100%;
  }
  .header-mobile {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.42px;
    margin-top: 6px;
  }
  .headerCount {
    color: var(--text-deep-gray, #666);
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 0;
  }
  .top-heading {
    height: 45px;
    position: relative;
    align-items: center;
    padding: 0 10px;
  }
  .top-heading > .back-to-page {
    position: absolute;
    left: 15px;
    top: 20px;
  }
  .searchBox {
    border-bottom: 1px solid #ccc;
    color: var(--text-deep-gray, #666);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.3px;
  }
  .searchBox svg {
    width: 15px;
  }
  .searchBox input {
    color: var(--text-deep-gray, #666);
    font-size: 18px;
    font-weight: 400;
    border: none;
    min-height: 22px;
    max-width: 150px;
    letter-spacing: 0.36px;
    padding: 10px 0;
  }
  .sortBox {
    display: flex;
    align-items: center;
  }
  .sortBox span {
    color: var(--text-deep-gray, #666);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-right: 5px;
  }
  .sortBox select {
    cursor: pointer;
    color: var(--text-deep-gray, #666);
    background: white;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.3px;
    min-height: 40px;
    max-width: 150px;
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .filterIcon {
    margin-left: 5px;
    width: 30px;
    height: 30px;
    background: #6579f4;
    padding: 2px;
    border-radius: 5px;
  }
  .filterIcon svg path {
    fill: white;
  }
  .mobileSearchBox {
    width: 100%;
    gap: 5px;
  }
  .mobileSearchBox svg {
    height: 20px;
  }
  .mobileSearchBox span {
    color: ${colorNew.colorGrayOnButton};
  }
  .mobileSearchBox input {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    background: #f8f8f8;
    color: ${colorNew.colorGrayOnButton};
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 0px;
    &.car {
      margin-bottom: 0;
    }
  }

  .filterTags {
    background-color: #ffffff;
    z-index: 9;
    position: sticky;
    top: 125px;
    padding: 0px 15px 5px;
  }

  // ......................... backlink used cars , popular cars
  .filterWrapper {
    &.popularCars {
      margin: 20px 20px 80px 20px;
    }
    &.usedCars {
      margin: 50px 20px 20px 20px;
    }
  }

  .imageContainer {
    max-width: 300px;
    min-width: 300px;
    position: relative;
  }

  // -------------------------------------------------Ads-------------------------------------------------
  .adDetailContainer {
    width: 100%;
  }
  .imageContainer {
    img {
      border-radius: 4px;
    }
  }
  .discountBox{
    background-image: linear-gradient(to right, #F46592 , #3A56FF);
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .discountBoxTextContainer{
      display: flex;
      flex-direction: column;
      p{
        margin: 0;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: white;
      }
      span{
        font-family: Rubik;
        font-size: 10px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: white;
      }
    }
    .discountButton{
      background-color: white;
      border-radius:2px;
      color: #000;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-transform: none;
    }
  }
  .adsListing {
    margin-top: 0;
    padding-top: 0;
  }

  .adContainer {
    width: 100%;
    min-width: 700px;
    margin-top: 16px;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    padding: 16px;
  }
  .adContainerLoader {
    max-width: 70%;
    min-width: 700px;
    text-align: center;
  }
  .adContainerLoader svg circle {
    stroke: #6579f4;
  }
  .ad-name {
    margin: 0px;
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.5px;
    text-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .price p {
    margin: 0;
    color: #363b39;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.42px;
  }
  .price span {
    color: var(--text-deep-gray, #666);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.26px;
  }
  .price .benefitInformationContainer {
    margin-left: 5px;
    display: inline;
  }

  @media  (max-width: 768px) {
    .discountBox {
      .discountBoxTextContainer{     
      p{       
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;        
      }
      span{
        font-size: 10px;
        font-weight: 400;
        line-height: 18px;
      }    
    }    
    }   
  }
  @media (max-width: 992px) {
    .filterBox {
      width: 0%;
      min-width: 0px;
      display: none;
    }
    .share-icon {
      bottom: 12px !important;
      right: 12px !important;
      width: 32px !important;
      height: 32px !important;
      padding: 0 7px !important;
    }
    .likeIcon {
      bottom: 12px !important;
      right: 54px !important;
      width: 32px !important;
      height: 32px !important;
      padding: 0 7px !important;
    }
    .listContainer {
      width: 100%;
    }
    .adsListing {
      margin-top: 0;
      padding-top: 0;
    }
    .adContainer {
      align-items: center;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      padding: 10px 0;
      & > div {
        padding: 0 10px;
      }
    }

    .adContainerLoader {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
    .user-detail-wrapper {
      padding: 0 !important;
    }
    .adDetailContainer {
      width: 100%;
      padding: 0 !important;
      & > div {
        padding: 0 10px;
      }
    }
    .pagination-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .pagination-container ul {
      gap: 8px;
    }
    .discountBox{
      border-radius: 0;
      flex-direction: row;
      padding: 5px; 

    .discountBoxTextContainer{
      display: flex;
      flex-direction: column;
      align-items: start;
    }
    .discountButton{
      margin-top: 0px;
    }
  }
  }
  @media (min-width: 769px) and (max-width: 992px) {
    .filterBox {
      position: sticky;
    }
    .listContainer {
      margin-left: 0;
    }
    .adsListing {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .adContainer {
      margin-top: 0;
    }
    .imageContainer {
        max-width: 100%;
        min-width: 100%;
      }
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    .filterBox {
      width: 25%;
    }
    .listContainer {
      width: 75%;
    }
    
  }
  @media (max-width: 768px) {
    .header {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    .adsListing {
      padding: 0 10px;
    }
    .filterWrapper {
      &.popularCars {
        margin: 20px;
      }
      &.usedCars {
        margin: 32px 20px 20px 20px;
      }
    }
    .imageContainer {
      max-width: 350px;
      img {
        border-radius: 2px;
      }
    }
    .listContainer {
      width: 100%;
      max-width: 100%;
      margin-left: 0px;
    }
    .button-wrapper button {
      height: 36px !important;
      padding: 5px;
    }
    .adsListing {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 575px) {
    .imageContainer {
      max-width: 100%;
      min-width: 100%;
    }
  }
  @media screen and (min-width: 1440px) {
    .adsListing {
      width: 80%;
    }
  }
  @media screen and (min-width: 993px) {
  .discountBox{
    position: absolute;
    bottom: 0;}
    .display-discount .likeIcon {
      bottom: 55px;
    }
    .display-discount .share-icon {
      bottom: 100px;
    }
  }
`;
