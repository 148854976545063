import { Box, Grid } from '@mui/material'
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { productTypes } from 'config/variables'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { translations } from 'locales/translations'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SizeDetail = ({ item }) => {
    const { t } = useTranslation();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <>
            {item?.owner_vehicle?.product_type_id !== productTypes.car &&
                <Grid item xl={4} sm={6} xs={12}>
                    <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.size())}</DetailsTitle>
                    <Box
                        className={`vehicle-size ${!isSmallDevice && 'grid_color_odd'}`}
                        sx={{
                            'span': {
                                fontWeight: 400
                            },
                        }}
                    >
                        {item?.owner_vehicle?.product_type_id === productTypes.step &&
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(translations.webTexts.SEATS)}</label>
                                <span>{item?.owner_vehicle?.number_of_seats ? t(translations.ads.YES) : t(translations.ads.NO)}</span>
                            </DetailListing>
                        }
                        {item?.owner_vehicle?.folding || item?.owner_vehicle?.folding === 0 || item?.owner_vehicle?.folding === null ?
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(translations.summaryVehicleScreen.FOLDING)}</label>
                                <span>{item.owner_vehicle.folding ? t(translations.ads.YES) : t(translations.summaryVehicleScreen.NO)}</span>
                            </DetailListing>
                            : null}
                        {item?.owner_vehicle?.product_type_id === productTypes.step && <DetailListing className='vehicle-detail-label'
                        >
                            <label>{t(translations.summaryVehicleScreen.CUSTOM_HANDLEBAR_HEIGHT)}</label>
                            <span>{item?.owner_vehicle?.custom_handlebar_height ? t(translations.ads.YES) : t(translations.summaryVehicleScreen.NO)}</span>
                        </DetailListing>}
                        {item?.owner_vehicle?.product_type_id === productTypes.bike && <>
                            {item?.owner_vehicle?.sex_info && <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(translations.summaryVehicleScreen.SEX)}</label>
                                <span>{item?.owner_vehicle?.sex_info ? item?.owner_vehicle?.sex_info.name : null}</span>
                            </DetailListing>}
                        </>}
                    </Box>
                </Grid>
            }
            {item?.owner_vehicle?.product_type_id === productTypes.car &&
                <Grid item xl={4} sm={6} xs={12}>
                    <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.size())}</DetailsTitle>
                    <Box className={`${!isSmallDevice && 'grid_color_odd'}`}
                        sx={{
                            'span': {
                                fontWeight: 400
                            },
                        }}
                    >
                        {item?.owner_vehicle?.product_type_id === productTypes.car ? <>
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(...lan.lengthMm())}</label>
                                <span>{item?.owner_vehicle?.length_number_cm ? item?.owner_vehicle?.length_number_cm + ' mm' : "- mm"}</span>
                            </DetailListing>
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(...lan.widthMm())}</label>
                                <span>{item?.owner_vehicle?.width_number_cm ? item?.owner_vehicle?.width_number_cm + ' mm' : '- mm'}</span>
                            </DetailListing>
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(...lan.heigtMm())}</label>
                                <span>{item?.owner_vehicle?.height_cm ? item?.owner_vehicle?.height_cm + ' mm' : '- mm'}</span>
                            </DetailListing>
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(...lan.wheelbaseMm())}</label>
                                <span>{item?.owner_vehicle?.wheelbase_cm ? item?.owner_vehicle?.wheelbase_cm + ' mm' : '- mm'}</span>
                            </DetailListing>
                            <DetailListing className='vehicle-detail-label'
                            >
                                <label>{t(...lan.clearanceMm())}</label>
                                <span>{item?.owner_vehicle?.ground_clearance_cm ? item?.owner_vehicle?.ground_clearance_cm + ' mm' : '- mm'}</span>
                            </DetailListing></> :
                            <>
                                <DetailListing className='vehicle-detail-label'
                                >
                                    <label>{t(...lan.weight())}</label>
                                    <span>{item?.owner_vehicle?.weight_gross_kg ? item?.owner_vehicle?.weight_gross_kg + ' kg' : '- kg'}</span>
                                </DetailListing>
                                {item?.owner_vehicle?.product_type_id === productTypes.step && <DetailListing className='vehicle-detail-label'
                                >
                                    <label>{t(translations.summaryVehicleScreen.MAX_WEIGHT_ALLOWED)}</label>
                                    <span>{item?.owner_vehicle?.max_weight_allowed ? item?.owner_vehicle?.max_weight_allowed + ' kg' : "-kg"}</span>
                                </DetailListing>}
                                <DetailListing className='vehicle-detail-label'
                                >
                                    <label>{t(translations.summaryVehicleScreen.PACKAGING) + "(W X L X H)"}</label>
                                    <span>{item?.owner_vehicle?.packaging_height && item?.owner_vehicle?.packaging_width && item?.owner_vehicle?.packaging_length ? item?.owner_vehicle?.packaging_width + "cm X " + item?.owner_vehicle?.packaging_length + "cm X " + item?.owner_vehicle?.packaging_height + "cm" : "-"}</span>
                                </DetailListing>
                            </>}
                    </Box>
                </Grid>
            }
            {item?.owner_vehicle?.product_type_id !== productTypes.car &&
                (item?.owner_vehicle?.packaging_height || item?.owner_vehicle?.packaging_width || item?.owner_vehicle?.packaging_length) ?
                <Grid item xl={4} sm={6} xs={12}>
                    <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.size())}</DetailsTitle>
                    <Box className={`${!isSmallDevice && 'grid_color_odd'}`}>
                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(translations.summaryVehicleScreen.PACKAGING) + "(W X L X H)"}</label>
                            <span>{item?.owner_vehicle?.packaging_height && item?.owner_vehicle?.packaging_width && item?.owner_vehicle?.packaging_length ? item?.owner_vehicle?.packaging_width + "cm X " + item?.owner_vehicle?.packaging_length + "cm X " + item?.owner_vehicle?.packaging_height + "cm" : '-'}</span>
                        </DetailListing>
                    </Box>
                </Grid>
                : null
            }</>
    )
}

export default SizeDetail