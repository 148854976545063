
import { getAcceptLanguage } from 'config/variables';
import { localstorageKey } from 'config/constants';
import { getFiltersRequest } from 'store/actions/auth-actions';
import { envVariables } from 'config/appConfig';
import { ApiPaths } from 'services/ApiPaths';

// Get Data From Local Storage
const getCarFilterList: any = localStorage.getItem(localstorageKey.carFilterdata)
export const getCarFilterListObj = JSON.parse(getCarFilterList || '{}');
const getBikeFilterList: any = localStorage.getItem(localstorageKey.bikeFilterdata)
export const getBikeFilterListObj = JSON.parse(getBikeFilterList || '{}');
const getStepFilterList: any = localStorage.getItem(localstorageKey.stepFilterdata)
export const getStepFilterListObj = JSON.parse(getStepFilterList || '{}');
const getVehicleTypeData: any = localStorage.getItem(localstorageKey.vehicleTypeData)
export const getVehicleTypeDataObj = JSON.parse(getVehicleTypeData || '{}');

export const setFilters = async (dispatch) => {
  const localStorageTime = parseInt(localStorage.getItem(localstorageKey.deleteFilterData) || '0');

  fetch(`${envVariables.apiUrl}${ApiPaths.public.filters_change}`, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
      'Cache-Control': 'max-age=0',  // Ensure fresh data
    },
  })
    .then(res => res.json())
    .then((res) => {
      const lastFilterUpdateTime = res?.data?.filter_changed_time && res.data.filter_changed_time !== null ? new Date(res?.data?.filter_changed_time).getTime() : new Date().getTime();
      const currentLan = localStorage.getItem(localstorageKey.i18nextLng);

      // call filter APi
      // - when filter change time is greater then the localstoragetime (i.e deleteFilterData time)
      // - when there is no time stored in localstorage
      // - when language in filter and localstorage are not same
      if ((localStorageTime && localStorageTime < lastFilterUpdateTime) || (localStorageTime == 0) || (getCarFilterListObj.lang !== currentLan)) {
        localStorage.removeItem(localstorageKey.slidersFetched)
        localStorage.removeItem(localstorageKey.sliderData)
        localStorage.removeItem(localstorageKey.partnerSliderData)

        dispatch(getFiltersRequest({ time: lastFilterUpdateTime }))
        return
      }
    })
    .catch(err => {
      throw new Error(err)
    })
}


