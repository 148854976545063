import { ReactComponent as FaLock } from './icons/faLock.svg';
import { ReactComponent as FaPlusCircle } from './icons/faPlusCircl.svg';
import { ReactComponent as FaSetting } from './icons/faSetting.svg';
import { ReactComponent as FaHome } from './icons/faHome.svg';
import { ReactComponent as FaEnvelop } from './icons/faEnveLop.svg';
import { ReactComponent as FaEnvelopLite } from './icons/faEnvelopLite.svg';
import { ReactComponent as FaPhone } from './icons/faPhone.svg';
import { ReactComponent as FaCopy } from './icons/faCopy.svg';
import { ReactComponent as FaLawHammer } from './icons/faLawHammer.svg';
import { ReactComponent as FaUserAdd } from './icons/faUserAdd.svg';
import { ReactComponent as FaChevronRight } from './icons/faChevronRight.svg';
import { ReactComponent as FaChevronLeft } from './icons/faChevronLeft.svg';
import { ReactComponent as FaCalender } from './icons/faCalender.svg';
import { ReactComponent as FaBill } from './icons/faBill.svg';
import { ReactComponent as FaUser } from './icons/faUser.svg';
import { ReactComponent as FaDoc2 } from './icons/faDoc2.svg';
import { ReactComponent as FaPhoneV } from './icons/faPhoneV.svg';
import { ReactComponent as FaCreditCard2 } from './icons/faCreditCard2.svg';
import { ReactComponent as FaEdit } from './icons/faEdit.svg'
import { ReactComponent as FaBell } from './icons/faBell.svg'
import { ReactComponent as FaBars } from './icons/faBars.svg'
import { ReactComponent as FaSearchLite } from './icons/faSearchLite.svg'
import { ReactComponent as faPlusLite } from './icons/faPlusLite.svg'
import { ReactComponent as FaShare } from './icons/faShare.svg'
import { ReactComponent as FaTrash } from './icons/faTrash.svg'
import { ReactComponent as FaCircleCut } from './icons/faCircleCut.svg'
import { ReactComponent as FaTower } from './icons/faTower.svg'
import { ReactComponent as FaClose } from './icons/faClose.svg'
import { ReactComponent as FaTrashRed } from './icons/faTrashRed.svg'
import { ReactComponent as FaDotsVertical } from './icons/faDotsVertical.svg'
import { ReactComponent as FaDesktop } from './icons/faDesktop.svg'
import { ReactComponent as FaMobile } from './icons/faMobile.svg'
import { ReactComponent as FaChevronDown } from './icons/faChevronDown.svg'
import { ReactComponent as FaHeart } from './icons/faHeart.svg'
import { ReactComponent as FaCloudUpload } from './icons/faCloudUpload.svg'
import { ReactComponent as FaCheckDark } from './icons/faCheckDark.svg'
import { ReactComponent as FaCheck } from './icons/faCheck.svg'
import { ReactComponent as FaRedClock } from './icons/faRedClock.svg'
import { ReactComponent as FaCamera } from './icons/faCamera.svg'
import { ReactComponent as FaCamera2 } from './icons/faCamera2.svg'
import { ReactComponent as FaHalfStart2 } from './icons/faHalfStar2.svg'
import { ReactComponent as FaTools } from './icons/faTools.svg'
import { ReactComponent as FaStarPlus } from './icons/faStarPlus.svg'
import { ReactComponent as FaMapTarget } from './icons/faMapTarget.svg'
import { ReactComponent as FaAlarmClock } from './icons/alermclock.svg'
import { ReactComponent as FaMapPaper } from './icons/faMapPaper.svg'
import { ReactComponent as FaCarBattry } from './icons/faCar-Battery.svg'
import { ReactComponent as FaBattaryClock } from './icons/faBatteryClock.svg'
import { ReactComponent as FaWheen } from './icons/faWheel.svg'
import { ReactComponent as FaFrameCycle } from './icons/faframeCycle.svg'
import { ReactComponent as FaGearIcon } from './icons/GearBox.svg'
import { ReactComponent as FaPumpIcon } from './icons/Pump.svg'
import { ReactComponent as FaRoadIcon } from './icons/Road.svg'
import { ReactComponent as FaMeterIcon } from './icons/Meter.svg'
import { ReactComponent as FaCheckDocs } from './icons/checkDocs.svg'
import { ReactComponent as FaInfo2 } from './icons/faInfo2.svg'
import { ReactComponent as Fakm } from './icons/fakm.svg'
import { ReactComponent as usesId } from './icons/userid.svg'
import { ReactComponent as chatIcon } from './icons/chatIcon.svg'
import { ReactComponent as addIcon } from './icons/addVehicleIcon.svg'
import { ReactComponent as notificationIcon } from './icons/notificationIcon.svg'
import { ReactComponent as searchIcon } from './icons/searchIcon.svg'
import { ReactComponent as chatDetailIcon } from './icons/chatDetailIcon.svg'
import { ReactComponent as chatIconSelected } from './icons/chatIconSelected.svg'
import { ReactComponent as selectedHomeIcon } from './icons/selectedHomeIcon.svg'
import { ReactComponent as removeIcon } from './icons/removeIcon.svg'
import { ReactComponent as naviagteIcon } from './icons/navigate.svg'
import { ReactComponent as likedIcon } from './icons/like.svg'
import { ReactComponent as unlikedIcon } from './icons/unlike.svg'
import { ReactComponent as newSearchIcon } from './NewIcons/searchIcon.svg'
import { ReactComponent as deleteIcon } from './NewIcons/Delete.svg';
import { ReactComponent as invalidCrossIcon } from './NewIcons/InvalidCrossIcon.svg';
import { ReactComponent as validCheckIcon } from './NewIcons/validIcon.svg';
import { ReactComponent as notificationIconNotAllowd } from './NewIcons/notificationIconNotAllowd.svg';
import { ReactComponent as shopHome } from './icons/home.svg';
import { ReactComponent as shopAbout } from './icons/about.svg';
import { ReactComponent as shopContact } from './icons/contacts.svg';
import { ReactComponent as shopServices } from './icons/services.svg';
import { ReactComponent as sold } from './icons/sold.svg';
import { ReactComponent as play } from './icons/Play.svg';
import { ReactComponent as helpCenter } from './icons/helpCenter.svg';
import { ReactComponent as handShake } from './icons/handShake.svg';
import { ReactComponent as bulb } from './icons/bulb.svg';
import { ReactComponent as Featured } from './icons/Featured.svg';
import { ReactComponent as cross } from './icons/cross.svg';
import { ReactComponent as check } from './icons/check.svg';
import { ReactComponent as circularCheck } from './icons/circularCheck.svg';


export const SvgInvalidCrossIcon = invalidCrossIcon;
export const SvgNotificationIconNotAllowd = notificationIconNotAllowd;
export const SvgValidCheckIcon = validCheckIcon;
export const SvgSearchIcon = searchIcon;
export const SvgChatDetailIcon = chatDetailIcon;
export const SvgChatIconSelected = chatIconSelected;
export const SvgSelectedHomeIcon = selectedHomeIcon;
export const SvgRemoveIcon = removeIcon;
export const SvgNavigateIcon = naviagteIcon;
export const SvgLiked = likedIcon;
export const SvgUnliked = unlikedIcon;
// export const SvgSelectedNotificationIcon = selectedNotificationIcon;
export const SvgChatIcon = chatIcon;
export const SvgAddVehicleIcon = addIcon;
export const SvgNotification = notificationIcon;
export const SvgusesId = usesId;
export const SvgFaLock = FaLock;
export const SvgPlusCircle = FaPlusCircle;
export const SvgSetting = FaSetting;
export const SvgHome = FaHome;
export const SvgEnvelop = FaEnvelop;
export const SvgEnvelopLite = FaEnvelopLite;
export const SvgPhone = FaPhone;
export const SvgCopy = FaCopy;
export const SvgLawHammer = FaLawHammer;
export const SvgUserAdd = FaUserAdd;
export const SvgChevronRight = FaChevronRight;
export const SvgChevronLeft = FaChevronLeft;
export const SvgCalender = FaCalender;
export const SvgBill = FaBill;
export const SvgUser = FaUser;
export const SvgDoc2 = FaDoc2;
export const SvgPhoneV = FaPhoneV;
export const SvgCreditCard2 = FaCreditCard2;
export const SvgEdit = FaEdit;
export const SvgBell = FaBell;
export const SvgSearchLite = FaSearchLite;
export const SvgPlusLite = faPlusLite;
export const SvgBars = FaBars;
export const SvgShare = FaShare;
export const SvgTrash = FaTrash;
export const SvgCircleCut = FaCircleCut;
export const SvgTower = FaTower;
export const SvgClose = FaClose;
export const SvgTrashRed = FaTrashRed;
export const SvgDotsVertical = FaDotsVertical;
export const SvgDesktop = FaDesktop;
export const SvgMobile = FaMobile;
export const SvgChevronDown = FaChevronDown;
export const SvgHeart = FaHeart;
export const SvgCloudUpload = FaCloudUpload;
export const SvgCheckDark = FaCheckDark;
export const SvgCheck = FaCheck;
export const SvgRedClock = FaRedClock;
export const SvgCamera = FaCamera;
export const SvgCamera2 = FaCamera2;
export const SvgHalfStart2 = FaHalfStart2;
export const SvgTools = FaTools;
export const SvgStarPlus = FaStarPlus;
export const SvgMapTarget = FaMapTarget;
export const SvgAlarmClock = FaAlarmClock;
export const SvgMapPaper = FaMapPaper;
export const SvgCarBattry = FaCarBattry
export const SvgBattaryClock = FaBattaryClock
export const SvgWheen = FaWheen
export const SvgFrameCycle = FaFrameCycle
export const SvgGearIcon = FaGearIcon
export const SvgPumpIcon = FaPumpIcon
export const SvgRoadIcon = FaRoadIcon
export const SvgMeterIcon = FaMeterIcon
export const SvgCheckDocs = FaCheckDocs
export const SvgInfo2 = FaInfo2
export const Svgkm = Fakm
export const SvgDelete = deleteIcon
export const NewSearchIcon = newSearchIcon
export const SvgshopHome = shopHome
export const SvgshopAbout = shopAbout
export const SvgshopContact = shopContact
export const SvgshopServices = shopServices
export const SvgSold = sold
export const SvgPlay = play
export const SVGHelpCenter = helpCenter
export const SVGHandShake = handShake
export const SVGBulb = bulb
export const SVGFeatured = Featured
export const SVGCross = cross
export const SvgCheckNew = check
export const SvgCircularCheck = circularCheck
