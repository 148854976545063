import { put, takeEvery } from "redux-saga/effects";
import { ApiPaths } from "services/ApiPaths";
import Api from "services/axios";
import { addAdInFavouriteFailure, addAdInFavouriteRequest, addAdInFavouriteSuccess, removeAdFromFavouriteFailure, removeAdFromFavouriteRequest, removeAdFromFavouriteSuccess } from "store/actions/favorite-actions";

function* _addAdInFavouriteRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.favourite + ApiPaths.vehicles.addVehicles + '/' + payload.id;
    const { data } = yield Api.post(uri, {});
    const row = data.data;
    yield put(addAdInFavouriteSuccess(row));
  } catch (e) {
    const error = e.response
    error['data']["id"] = payload.id
    yield put(addAdInFavouriteFailure(error));
  }
}

function* _removeAdFromFavouriteRequest({ payload }) {
  try {
    let uri = ApiPaths.vehicles.favourite + ApiPaths.vehicles.addVehicles + '/' + payload.id;
    const { data } = yield Api.delete(uri, {});
    const row = data.data;
    row['id'] = payload.id
    yield put(removeAdFromFavouriteSuccess(row));
  }
  catch (e) {
    const error = e.response
    error['data']["id"] = payload.id
    yield put(removeAdFromFavouriteFailure(error));
  }
}

export const favoriteSagas = [
  takeEvery(addAdInFavouriteRequest, _addAdInFavouriteRequest),
  takeEvery(removeAdFromFavouriteRequest, _removeAdFromFavouriteRequest),
]