import { Box, Checkbox, FormControlLabel, RadioGroup } from '@mui/material'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import React, { useEffect, useMemo, useState } from 'react'
import { MINIMUM_YEAR, checkKey, removeIDfromSlug, stringToArray, vehicleTypeStatus } from '../filterConfig'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import SearchDropdown from '../../CustomFields/searchDropdown'
import SearchDropdownMultipleInput from '../../CustomFields/searchDropdownMultipleInput'
import TextMultipleInput from 'app/components/CustomFields/textMultipleInput'
import { urlParamInterface } from 'config/interface'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getVehicleModelListRequest } from 'store/actions/vehicle-actions'
import { getProductTypeId, vehicles } from 'config/variables'
import { routes } from 'routes/routes'
import SearchDropdownForSlug from 'app/components/CustomFields/searchDropdownForSlug'
import { getParamQueries } from 'config/appConfig'
import { Toaster } from 'services/Toaster'

const KeyData = ({ filterData, updateURL }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()

    const { vehicle, brand_id, model_id, country_id, ads_type, slug }: urlParamInterface = useParams();
    const params: urlParamInterface = useParams();
    const history = useHistory();

    const { modelList } = useSelector((state: RootStateOrAny) => state.vehicle)

    const [isMyAdsPage, _isMyAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.myAds))

    const [updatedModelList, _updatedModelList] = useState<Array<Object>>([])
    const [leftDropdownValues, _leftDropdownValues] = useState<Array<number>>([])
    const [rightDropdownValues, _rightDropdownValues] = useState<Array<number>>([])

    const [vehicleStatus, _vehicleStatus] = useState<Nullable<number>>()
    // Public Ads
    const [brand, _brand] = useState<Object>({})
    const [model, _model] = useState<Object>({})
    // My Ads
    const [brandID, _brandID] = useState<Nullable<number>>()
    const [modelID, _modelID] = useState<Nullable<number>>()
    const [body, _body] = useState<Nullable<number>>()
    const [frame, _frame] = useState<Nullable<number>>()
    const [transmission, _transmission] = useState<Array<number>>([])
    const [color, _color] = useState<Array<number>>([])
    const [fuel, _fuel] = useState<Array<number>>([])
    const [mileageMin, _mileageMin] = useState<Nullable<number>>()
    const [mileageMax, _mileageMax] = useState<Nullable<number>>()
    const [firstRegistrationStart, _firstRegistrationStart] = useState<Nullable<number>>()
    const [firstRegistrationend, _firstRegistrationend] = useState<Nullable<number>>()

    const productType = useMemo(() => getProductTypeId(vehicle), [vehicle])

    useEffect(() => {
        let year = new Date().getFullYear()
        let yearsArray: number[] = []
        for (let i = year; i > MINIMUM_YEAR; i--) {
            yearsArray.push(i)
        }
        _leftDropdownValues(yearsArray)
        _rightDropdownValues(yearsArray)
    }, [])

    useEffect(() => {
        if (brand_id && (checkKey(brand_id, productType, 'brand')?.name != 'brand_slug')) {
            _model({})
            _brand({})
            return
        }
        if (!brand_id) {
            _brand({})
            _model({})
        }
    }, [brand_id])

    useEffect(() => {
        if (!brand_id && !model_id) {
            _model({})
        }
        if (brand_id && !model_id && (checkKey(brand_id, productType, 'model')?.name != 'model_slug')) {
            _model({})
            return
        }
        if (model_id && (checkKey(model_id, productType, 'model')?.name != 'model_slug')) {
            _model({})
        }
    }, [brand_id, model_id, country_id])

    // Requesting API call for Models whenever brand gets changed
    useEffect(() => {
        if ((brand && Object.keys(brand).length > 0) || brandID) {
            let filter = {
                'product_type_id': productType,
            }
            if (brand) {
                filter['brand_id'] = brandID ?? brand.id
            }
            dispatch(getVehicleModelListRequest({ filter }))
        }
    }, [brand?.id, brandID])

    // Updating response of model list API (changing keyname only from model_name to name)
    useEffect(() => {
        let models: object[] = []
        modelList.forEach(model => {
            models.push({
                brand_id: model.brand_id,
                id: model.id,
                name: model.model_name,
                modix_id: model.modix_id,
                product_category_id: model.product_category_id,
                slug: model.slug
            })
        });
        _updatedModelList(models)
    }, [modelList])


    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        const payload = getParamQueries(location.search)
        _vehicleStatus(payload.new_vehicle ?? null)
        _firstRegistrationStart(payload.registration_start ?? null)
        _firstRegistrationend(payload.registration_end ?? null)
        _mileageMin(payload.vehicle_mileage_start ?? null)
        _mileageMax(payload.vehicle_mileage_end ?? null)
        _body(payload.body_vehicle_id ?? null)
        _frame(payload.frame_size_id ?? null)
        _brandID(payload.brand_id ?? null)
        _modelID(payload.model_id ?? null)
        stringToArray(_color, payload.outside_color_id ?? null)
        stringToArray(_transmission, payload.gearbox_id ?? null)
        stringToArray(_fuel, payload.fuel_id ?? null)
    }, [location.search])

    useEffect(() => {
        if (Object.keys(filterData).length > 0) {
            if (brand_id && filterData?.brandList?.items) {
                const existence = filterData.brandList.items.find((brand) => removeIDfromSlug(brand.slug) == removeIDfromSlug(brand_id) || removeIDfromSlug(brand.slug) == brand_id)
                if (existence) {
                    _brand(existence)
                }
            }
        }
    }, [filterData, brand_id])

    useEffect(() => {
        if (model_id && updatedModelList) {
            const existence = updatedModelList.find((model) => removeIDfromSlug(model.slug) == removeIDfromSlug(model_id) || removeIDfromSlug(model.slug) == model_id)
            if (existence) {
                _model(existence)
            }
        }
    }, [updatedModelList])


    const handleVehicleStatus = (status) => {
        if (vehicleStatus == status) {
            _vehicleStatus(null)
            updateURL('new_vehicle', '')
            return
        }
        _vehicleStatus(status)
        updateURL('new_vehicle', status)
    }

    const handleBrand = (option) => {
        _model({})
        _updatedModelList([])
        if (brand && brand.slug == option.slug) {
            let url = location.pathname + location.search
            let newUrl = url.replace(`/${brand.slug}`, '')
            if (model && model.slug) {
                newUrl = newUrl.replace(`/${model.slug}`, '')
            }
            history.replace(newUrl)
            return
        }
        _brand(option)
        adBrandSlug(option.slug)
    }

    const handleModel = (option) => {
        if (model && model.slug == option.slug) {
            let url = location.pathname + location.search
            let newUrl = url.replace(`/${model.slug}`, '')
            history.replace(newUrl)
            return
        }
        _model(option)
        adModelSlug(option.slug)
    }

    const handleBrandID = (id) => {
        _modelID(null)
        updateURL('model_id', '')
        if (brandID == id) {
            _brandID(null)
            updateURL('brand_id', '')

            return
        }
        _brandID(id)
        updateURL('brand_id', id)
    }

    const handleModelID = (id) => {
        if (modelID == id) {
            _modelID(null)
            updateURL('model_id', '')
            return
        }
        _modelID(id)
        updateURL('model_id', id)
    }

    const handleBody = (id) => {
        if (body == id) {
            _body(null)
            updateURL('body_vehicle_id', '')
            return
        }
        _body(id)
        updateURL('body_vehicle_id', id)
    }

    const handleTransmission = (selectedValues) => {
        let values = []
        if (selectedValues.length > 0) {
            selectedValues.forEach(transmission => {
                values.push(transmission.id)
            })
        }
        _transmission(values)
        updateURL('gearbox_id', values)
    }

    const handleColor = (selectedValues) => {
        let values = []
        if (selectedValues.length > 0) {
            selectedValues.forEach(color => {
                values.push(color.id)
            })
        }
        _color(values)
        updateURL('outside_color_id', values)
    }

    const handleFuel = (selectedValues) => {
        let values = []
        if (selectedValues.length > 0) {
            selectedValues.forEach(fuel => {
                values.push(fuel.id)
            })
        }
        _color(values)
        updateURL('fuel_id', values)
    }

    const handleMileageMin = (value) => {
        _mileageMin(value)
        if (mileageMax && mileageMax < parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MIN_ERROR))
            return
        }
        updateURL('vehicle_mileage_start', value)
    }

    const handleMileageMax = (value) => {
        _mileageMax(value)
        if (mileageMin && mileageMin > parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MAX_ERROR))
            return
        }
        updateURL('vehicle_mileage_end', value)
    }

    const handleFirstRegistrationStart = (value) => {
        if (firstRegistrationStart == value) {
            _firstRegistrationStart(null)
            updateURL('registration_start', '')
            return
        }
        _firstRegistrationStart(value)
        if (firstRegistrationend && firstRegistrationend < parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MIN_ERROR))
            return
        }
        updateURL('registration_start', value)
    }

    const handleFirstRegistrationEnd = (value) => {
        if (firstRegistrationend == value) {
            _firstRegistrationend(null)
            updateURL('registration_end', '')
            return
        }
        _firstRegistrationend(value)
        if (firstRegistrationStart && firstRegistrationStart > parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MAX_ERROR))
            return
        }
        updateURL('registration_end', value)
    }

    const handleFrame = (value) => {
        _frame(value)
        updateURL('frame_size_id', value)
    }

    // Function to add model slug in url when filter is applied
    const adModelSlug = (modelSlug) => {
        let url = ''
        if (location.pathname.includes(routes.vehicleRoutes.publicAds)) {
            url = routes.vehicleRoutes.publicAds + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:brand_id?/:model_id?/:country_id?`
        }
        if (location.pathname.includes(routes.vehicleRoutes.auctionAd)) {
            url = routes.vehicleRoutes.auctionAd + '/:vehicle/:id/:brand_id?/:model_id?/:country_id?'
        }
        if (location.pathname.includes(routes.publicRoutes.shopURL)) {
            url = `${routes.publicRoutes.shopURL}/${slug}/${ads_type}/:vehicle/:brand_id?/:model_id?`
        }
        const path = generatePath(
            url,
            {
                ...params,       // <-- shallow copy in the existing param values
                model_id: modelSlug,
            },
        );
        const payload = getParamQueries(location.search)
        delete payload['page']
        history.replace(`${path}?${new URLSearchParams(payload).toString()}`)
    }

    // Function to add brand slug in url when filter is applied
    const adBrandSlug = (brandSlug) => {
        let url = ''
        if (location.pathname.includes(routes.vehicleRoutes.publicAds)) {
            url = routes.vehicleRoutes.publicAds + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:brand_id?/:model_id?/:country_id?`
        }
        if (location.pathname.includes(routes.vehicleRoutes.auctionAd)) {
            url = routes.vehicleRoutes.auctionAd + '/:vehicle/:id/:brand_id?/:model_id?/:country_id?'
        }
        if (location.pathname.includes(routes.publicRoutes.shopURL)) {
            url = `${routes.publicRoutes.shopURL}/${slug}/${ads_type}/:vehicle/:brand_id?/:model_id?`
        }
        const newParams = { ...params }
        delete newParams['model_id'];
        const path = generatePath(
            url,
            {
                ...newParams,       // <-- shallow copy in the existing param values
                brand_id: brandSlug,
            },
        );
        const payload = getParamQueries(location.search)
        delete payload['page']
        history.replace(`${path}?${new URLSearchParams(payload).toString()}`)
    }


    return (
        <Box sx={{ gap: '24px' }} className='flexColumn'>

            <Box className='flexRow justifyContentBetween'>
                <FormControlLabel control={<Checkbox checked={vehicleStatus == vehicleTypeStatus.NEW} onChange={() => handleVehicleStatus(vehicleTypeStatus.NEW)} />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_NEW)} sx={{ mr: 0, ml: 0 }} className='filterCheckBox' labelPlacement="start" />
                <FormControlLabel control={<Checkbox checked={vehicleStatus == vehicleTypeStatus.USED} onChange={() => handleVehicleStatus(vehicleTypeStatus.USED)} />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_USED)} sx={{ mr: '-13px' }} className='filterCheckBox' labelPlacement="start" />
            </Box>

            {/* Brand field for public ads and showcase page */}
            {!isMyAdsPage &&
                <SearchDropdownForSlug title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_BRAND)} list={filterData?.brandList?.items ?? []} value={brand} handleChange={(option) => handleBrand(option)} />
            }

            {/* Brand field for My ads */}
            {isMyAdsPage &&
                <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_BRAND)} list={filterData?.brandList?.items ?? []} value={brandID} handleChange={(id) => handleBrandID(id)} showIcon={false} />
            }

            {/* Appears only when brand is selected */}
            {/* Brand field for public ads */}
            {!isMyAdsPage && brand && Object.keys(brand).length > 0 && <SearchDropdownForSlug title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MODEL)} list={updatedModelList ?? []} value={model} handleChange={(option) => handleModel(option)} />}

            {/* Brand field for public ads */}
            {brandID && isMyAdsPage && updatedModelList.length > 0 && <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MODEL)} list={updatedModelList ?? []} value={modelID} handleChange={(id) => handleModelID(id)} showIcon={false} />}

            <SearchDropdownMultipleInput title={t(translations.ads.YEAR)} labelFirstField={t(translations.FILTER_COMPONENT.FROM)} labelSecondField={t(translations.FILTER_COMPONENT.TO)} listfirstField={leftDropdownValues} listsecondField={rightDropdownValues} handleFirstField={(value) => handleFirstRegistrationStart(value)} handleSecondField={(value) => handleFirstRegistrationEnd(value)} firstFieldValue={firstRegistrationStart} secondFieldValue={firstRegistrationend} />

            <TextMultipleInput title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KM)} labelFirstField={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)} labelSecondField={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)} handleFirstField={(value) => handleMileageMin(value)} handleSecondField={(value) => handleMileageMax(value)} firstFieldValue={mileageMin} secondFieldValue={mileageMax} />

            <SearchDropdown title={vehicle == vehicles.car ? t(translations.FILTER_COMPONENT.FILTER_COMPONENT_BODY) : vehicle == vehicles.motorcycles ?

                t(translations.FILTER_COMPONENT.TYPE_OF_MOTOR_CYCLE) : t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FRAME)} list={filterData?.bodiesList?.items ?? []} value={body} handleChange={(id) => handleBody(id)} showIcon={true} />

            <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_COLOR)} list={filterData?.colorList?.items ?? []} value={color} handleChange={(id) => handleColor(id)} multiselect={true} showColor={true} />

            {/* Filter available only for car and motorbike ads */}
            {
                (vehicle == vehicles.car || vehicle == vehicles.motorcycles) &&
                <>
                    <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TRANSMISSION)} list={filterData?.gearList?.items ?? []} value={transmission} handleChange={(id) => handleTransmission(id)} multiselect={true} />
                </>
            }

            {/* Filter available only for bike */}
            {
                (vehicle == vehicles.bike) &&
                <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FRAMESIZE)} list={filterData?.frameList?.items ?? []} value={frame} handleChange={(id) => handleFrame(id)} />
            }

            {/* Filter available only for car and motorbike ads */}
            {
                (vehicle == vehicles.car || vehicle == vehicles.motorcycles) &&
                <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FUEL)} list={filterData?.fuelList?.items ?? []} value={fuel} handleChange={(id) => handleFuel(id)} multiselect={true} />
            }
        </Box>
    )
}

export default KeyData