import axios from 'axios';
import { localstorageKey } from 'config/constants';
import { getAcceptLanguage } from 'config/variables';
import { routes } from 'routes/routes';
import { envVariables } from '../config/appConfig';

const Api = axios.create({
  baseURL: `${envVariables.apiUrl}/`,
});

Api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');
    const subsidiary_id = localStorage.getItem('subsidiary');
    const lang = getAcceptLanguage()
    if (config.url.includes('?')) {
      config.url += `&lang=${lang}`;
    } else {
      config.url += `?lang=${lang}`;
    }
    if (token) {
      config.headers = {
        Authorization: 'Bearer ' + token,
        "Accept-Language": lang,
        'Cache-Control': 'max-age=0',
      };
      if (subsidiary_id && subsidiary_id !== null) {
        config.url += '&subsidiary_id=' + subsidiary_id;
      }
    } else {
      config.headers = {
        "Accept-Language": lang,
      }
    }

    if (process.env.REACT_APP_API_VERSION) {
      config.headers['X-Api-Version'] = process.env.REACT_APP_API_VERSION
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const errorHandler = (error: any) => {
  if (error?.response?.status === 401) {
    let keysToRemove = ["token", "user", "subsidiary", "activeSUbsidiary"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k)
    )
    if (!window.location.pathname.includes(routes.authRoutes.login)) {
      const getCurrentLang = localStorage.getItem(localstorageKey.i18nextLng);
      let newUrl = routes.authRoutes.login
      if (getCurrentLang && getCurrentLang !== null) {
        newUrl = window.location.origin + '/' + getCurrentLang + routes.authRoutes.login;
      }
      window.location.href = newUrl;
    }
  }
  return Promise.reject({ ...error });
};

const successHandler = (response: any) => {
  return response;
};

Api.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default Api;
