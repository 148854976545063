import PublicLayout from "app/Component2/Layouts/PublicLayout";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { Box } from "@mui/material";
import Header from "../../../components/ListingPageHeader";
import { PublicAdsWrapper } from "../style/PublicAdsWrapper";
import { urlParamInterface } from "config/interface";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getPublicAdsRequest } from "store/actions/public-actions";
import { getProductTypeId, getSubsidiaryStored, publicTitle, vehicles } from "config/variables";
import { getMetaDescriptionWithBrand, getMetaDescriptionWithBrandAndCountry, getMetaDescriptionWithBrandAndModel, getMetaDescriptionWithBrandModelCountry, getMetaDescriptionWithCountry, getMetaTitleWithBrand, getMetaTitleWithBrandAndCountry, getMetaTitleWithBrandAndModel, getMetaTitleWithBrandModelCountry, getMetaTitleWithCountry } from "config/translationsConfig";
import { getCountryNameBySlug } from "config/constants";
import PublicAd from "../components/ad/publicAd";
import { AdDetail } from "../interface/interface";
import AdSkeleton from "../components/ad/skeleton";
import { ContentLoader } from "app/components/ContantLoader/ContentLoader";
import { useDeviceType } from "app/Hooks/useMediaQuery";
import NotFound from "app/components/RepeatComponents/NoFound";
import { SvgMobileLeftArrow } from "app/components/svgicons/svgNew";
import Filter from "app/components/Filters";
import { getParamQueries, updateURL } from "config/appConfig";
import { checkKey } from "app/components/Filters/filterConfig";
import PaginationComponent from "../components/ad/Pagination";
import BacklinkWithFilters from 'app/components/GlobalComponent/BacklinkWithFilters'
import BacklinkWithBrands from 'app/components/GlobalComponent/BacklinkWithBrands'
import { getFavoritesList } from "config/utils";
const Feature = (props: any) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation()
    const history = useHistory()
    const subsidiary = getSubsidiaryStored()
    const scrollRef: any = useRef(null)

    const { vehicle, brand_id, model_id, country_id }: urlParamInterface = useParams();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const { publicAdsListLastPage, publicAdsListCurrentPage, loadingPublicAds, publicAdsList } = useSelector((state: RootStateOrAny) => state.publicReducer)
    const { likeDislikeProcessing } = useSelector((state: RootStateOrAny) => state.favorites)
    const { loadingStoreChat } = useSelector((state: RootStateOrAny) => state.chat)
    const { loading, modelList } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { filters } = useSelector((state: RootStateOrAny) => state.componentReducers)
    const { loggedIn } = useSelector((state: any) => state.auth)

    const [search, setSearch] = useState<string>('')
    const [searched, _searched] = useState<boolean>(false)
    const [sort, setSort] = useState<string>('');

    const [scrollFlag, _scrollFlag] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1)
    const [ads, setads] = useState<Array<AdDetail>>([])

    const [showScroll, _showScroll] = useState<boolean>(false)

    const isFilterAvailable = localStorage.getItem('deleteFilterData')
    const [requestedPayload, _requestedPayload] = useState<object>({})


    useEffect(() => {
        window.addEventListener("scroll", handleScroll, true)

        return () => {
            window.removeEventListener("scroll", handleScroll, true)
        }
    }, [scrollFlag, page, publicAdsListLastPage, loadingPublicAds])

    const productType = useMemo(() => getProductTypeId(vehicle), [vehicle])

    useEffect(() => {
        // Scrolling to the record from user navigated to ads page
        if (localStorage.getItem('adsId') && (localStorage.getItem('prevPath') == location.pathname || localStorage.getItem('prevPath') == (location.pathname + location.search))) {
            let id = localStorage.getItem('adsId')
            let element = document.getElementById(id)
            if (element) {
                element.scrollIntoView({ block: "start" })
                setTimeout(() => {
                    localStorage.removeItem('adsId')
                    localStorage.removeItem('prevPath')
                }, 200)
                return;
            }
        }
    }, [ads])

    // Reseting last API call's payload on account switch
    useEffect(() => {
        _requestedPayload({})
    }, [subsidiary])

    // Removing Ads data from localstorage if List data has not been loaded yet
    useEffect(() => {
        if (publicAdsList && publicAdsList.length == 0) {
            localStorage.removeItem('prevPath')
            localStorage.removeItem('adsId')
        }

    }, [])

    const generateName = (name) => {
        let arr = name.split("-")
        let str = arr[0]
        if (arr.length > 2) {
            arr.splice(arr.length - 1, 1)
            str = arr.toString().replaceAll(",", " ")
        }
        return str[0].toUpperCase() + str.slice(1)
    }

    useEffect(() => {
        setSearch('')
        setSort('')
        setPage(1)
    }, [productType])

    // update local api current page with global state of current page(global state is required for current page so that we can persist the current page on unmounting the component)  
    useEffect(() => {
        setPage(publicAdsListCurrentPage)
    }, [publicAdsListCurrentPage])

    useEffect(() => {
        // Redirection to page with applying "with_media" filter if redirection state is present
        if (location.state && location.state?.redirect) {
            history.replace(`${location.pathname}?with_media=1`)
            return
        }

        // Returning if brand/model/country filter was applied and filters were not loaded y

        if (loading || ((brand_id || model_id || country_id) && !isFilterAvailable)) {
            return
        }

        const updatePayloadAndDispatch = () => {
            const prevPath = localStorage.getItem('prevPath');
            const currentPath = location.pathname + location.search;

            // Returning if the previously accessed path and current path are same as data is already loaded
            if (prevPath === currentPath) return;

            const payload = {
                page: 1,
                product_type_id: getProductTypeId(vehicle),
                keyword: search || '',
                sort: sort || '',
                ...getParamQueries(location.search)
            };

            const brandFilter = checkKey(brand_id, productType, 'brand');
            const modelFilter = checkKey(model_id, productType, 'model');
            const countryFilter = checkKey(country_id, productType, 'country');

            if (brand_id) {
                payload[brandFilter?.name === 'country_slug' ? 'country_slug' : 'brand_slug'] = brandFilter?.slug || brand_id;
            }

            if (model_id) {
                if (modelFilter?.name === 'country_slug') {
                    payload['country_slug'] = modelFilter?.slug ?? model_id
                } else {
                    const models = JSON.parse(localStorage.getItem('modelList') || '[]')
                    if (brandFilter?.name == 'brand_slug' && modelFilter?.name != 'country_slug' && models && models.length == 0) {
                        return
                    }
                    payload['model_slug'] = modelFilter?.slug ?? model_id
                }
            }

            if (country_id) {
                payload['country_slug'] = countryFilter?.slug || country_id;
            }

            // requested payload holds last API calls payload
            // In case of multiple API requests , it compares previous with latest payload
            // if it is same it stops API request 
            if (JSON.stringify(payload) == JSON.stringify(requestedPayload)) {
                return
            }

            dispatch(getPublicAdsRequest(payload));
            _requestedPayload(payload)
            window.scrollTo(0, 0);
        };

        updatePayloadAndDispatch();

    }, [location.search, subsidiary, location.state, sort, brand_id, model_id, country_id, loading, isFilterAvailable, modelList])

    useEffect(() => {
        setads(publicAdsList)
    }, [publicAdsList])

    useEffect(() => {
        if (loggedIn) {
            getFavoritesList(publicAdsList, dispatch)
        }
    }, [publicAdsList, loggedIn, subsidiary])

    //Function request for next page data when scrolled to bottom
    const handleScroll = function (e) {
        // called when the window is scrolled.
        setScrollTopButton(window.innerHeight + window.scrollY)
    }


    const setScrollTopButton = (scrollPosition) => {
        if (scrollPosition > 1000) {
            _showScroll(true)
            return
        }
        _showScroll(false)
    }

    // -------------------------------------------------Works for tab view-------------------------------------------------
    const handleChange = (e) => {
        // DO NOT INCLUDE '%' IN SEARCH STRING, it will create problem during encoding and decoding the search string in url 
        if (e.target.value.includes('%')) return;
        setSearch(e.target.value)
        _searched(true)
    }

    useEffect(() => {
        if (isMediumDevice) {
            // If user hasn't changed in search field anything we won't update URL
            if (!searched) {
                return
            }
            const debounce = setTimeout(() => {
                updateURL('keyword', search, history)
            }, 500)
            return () => clearTimeout(debounce)
        }
    }, [search])


    useEffect(() => {
        if (isMediumDevice) {
            let payload = getParamQueries(location.search)
            if (payload.keyword) {
                let str = decodeURI(payload.keyword)
                if (str.includes('+')) {
                    _searched(true)
                    setSearch(str.replaceAll("+", ' '))
                    return
                }
                _searched(true)
                setSearch(str)
                return

            }
            setSearch('')
        }
    }, [location.search])
    // -------------------------------------------------Works for tab view Ends-------------------------------------------------

    // -----------------------------------------------------------------------Code for Meta tags Starts-----------------------------------------------------------------------
    const publicOgDescriptionSpecificAd = (vehicleType) => {
        const brandIsCountry = getCountryNameBySlug(brand_id)
        const modelIsCountry = getCountryNameBySlug(model_id);
        const countryName = getCountryNameBySlug(country_id);
        let string: any = "";
        //if only country selected
        if (brandIsCountry && !country_id) {
            string = getMetaDescriptionWithCountry(vehicle, brandIsCountry.fullname)
            // if only brand and model selected
        } else if (brand_id && model_id && modelIsCountry === undefined) {
            string = getMetaDescriptionWithBrandAndModel(vehicle, generateName(brand_id), generateName(model_id))
            //if only brand and country selected
        } else if (brand_id && (country_id || typeof modelIsCountry === "object")) {
            string = getMetaDescriptionWithBrandAndCountry(vehicle, generateName(brand_id), modelIsCountry.fullname)
            //if only brand is selected
        } else if (brand_id && !model_id && !country_id) {
            string = getMetaDescriptionWithBrand(vehicle, generateName(brand_id))
        }
        //if brand, model and country is selected
        if (country_id && brand_id && model_id) {
            string = getMetaDescriptionWithBrandModelCountry(vehicle, generateName(brand_id), generateName(model_id), countryName?.fullname)
        }

        return string;
    }

    const publicTitleSpecificAd = (vehicleType) => {
        const brandIsCountry = getCountryNameBySlug(brand_id)
        const modelIsCountry = getCountryNameBySlug(model_id);
        const countryName = getCountryNameBySlug(country_id)
        let string: any = "";
        //if only country selected
        if (brandIsCountry && !country_id) {
            string = getMetaTitleWithCountry(vehicle, brandIsCountry.fullname)
            // if only brand and model selected
        } else if (brand_id && model_id && modelIsCountry === undefined) {
            string = getMetaTitleWithBrandAndModel(vehicle, generateName(brand_id), generateName(model_id))
            //if only brand and country selected
        } else if (brand_id && (country_id || typeof modelIsCountry === "object")) {
            string = getMetaTitleWithBrandAndCountry(vehicle, generateName(brand_id), modelIsCountry.fullname)
            //if only brand is selected
        } else if (brand_id && !model_id && !country_id) {
            string = getMetaTitleWithBrand(vehicle, generateName(brand_id))
        }
        if (!brandIsCountry && !modelIsCountry && country_id) {
            //    if user have link already filterd data
            string = getMetaTitleWithBrandModelCountry(vehicle, generateName(brand_id), generateName(model_id), countryName?.fullname)
        }
        return string;
    }

    // -----------------------------------------------------------------------Code for Meta tags Ends -----------------------------------------------------------------------

    return (
        <PublicLayout
            title={t(translations.ads.PUBLICADSSALEORPURCHASE)}
            description={publicOgDescriptionSpecificAd(productType)}
            meta={brand_id || model_id || country_id ? publicTitleSpecificAd(productType) : publicTitle(productType)}
            image={process.env.REACT_APP_BASE_URL + '/content.jpg'}
            descriptionHome={t(translations.ads.METADESCRIPTIONTEXT2)}
            type="website"
            showNavbar={true}
            hideFooter={false}
            hideHeader={isSmallDevice || isMediumDevice ? true : false}
        >

            {/* Loader appears in web and tab view */}
            {!isSmallDevice && (loadingStoreChat || loadingPublicAds || likeDislikeProcessing) ? <ContentLoader /> : null}

            {/* Loader appears in mobile view when filters are not open */}
            {!filters && isSmallDevice && (loadingStoreChat || loadingPublicAds || likeDislikeProcessing) ? <ContentLoader /> : null}

            <PublicAdsWrapper>
                <Box className='homeMainContainer classic-ads' >

                    {(isSmallDevice || isMediumDevice) && <Header
                        setSearch={setSearch}
                        setSort={setSort}
                        sort={sort}
                        search={search}
                        isAuction={false}
                        searched={searched}
                        _searched={_searched}
                        title={brand_id || model_id || country_id ? publicTitleSpecificAd(productType) : publicTitle(productType)}
                    />}

                    <Box className="flexRow">

                        <Box className="filterBox" sx={{ top: isMediumDevice ? Object.keys(getParamQueries(location.search)).length > 0 ? "120px" : "68px" : "" }}>
                            <Filter handleBackFromFilters={() => { return }} keyword={search} handleChangeSearch={handleChange} />
                        </Box>

                        <Box className="listContainer">
                            {(isLargeDevice || isExtraLargeDevice) &&
                                <Header
                                    setSearch={setSearch}
                                    setSort={setSort}
                                    sort={sort}
                                    search={search}
                                    isAuction={false}
                                    searched={searched}
                                    _searched={_searched}
                                    title={brand_id || model_id || country_id ? publicTitleSpecificAd(productType) : publicTitle(productType)}
                                />

                            }

                            <Box className="adsListing scrollBar" sx={{ p: 2 }} ref={scrollRef}>
                                {!loadingPublicAds && publicAdsList.length > 0 && ads.map((ad) => {
                                    return <>
                                        <PublicAd adDetail={ad} key={'publicAd' + ad.id} />
                                    </>
                                })}

                                {
                                    loadingPublicAds ? [1, 2, 3, 4, 5, 6].map((item) => {
                                        return <AdSkeleton key={'AdSkeleton' + item} />
                                    })
                                        : <Box id="adsContainer"></Box>}

                                {publicAdsList.length == 0 && !loadingPublicAds && <Box className="adContainer not-found-wrapper"><NotFound /></Box>}

                            </Box>
                            {publicAdsList.length != 0 ? <Box className={"pagination-container" + (vehicle == vehicles.car ? ' car' : '')}>
                                <PaginationComponent lastPage={publicAdsListLastPage} />
                            </Box> : null}



                            {isSmallDevice && showScroll && <Box className="scrollTop" onClick={() => window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            })}>
                                <SvgMobileLeftArrow />
                            </Box>}

                        </Box>
                    </Box>
                    {
                        vehicle == vehicles.car ?
                            <>
                                <BacklinkWithBrands />
                                <BacklinkWithFilters />
                            </>
                            : null
                    }
                </Box>

            </PublicAdsWrapper>

        </PublicLayout >
    )
}

export default Feature;