import { Box, Grid } from '@mui/material';
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { productTypes, wheelTypes } from 'config/variables';
import { lan } from 'locales/multiPageTranslation/adsLan';
import React from 'react'
import { useTranslation } from 'react-i18next'

const TyresDetail = ({ item }) => {
    const { t } = useTranslation();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <>
            {item?.owner_vehicle?.product_type_id === productTypes.car &&
                <Grid item xl={4} sm={6} xs={12}>
                    <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.tires())}</DetailsTitle>
                    <Box className={`${!isSmallDevice && 'grid_color_odd'}`}>
                        <DetailListing className='vehicle-detail-label' >
                            <label>{t(...lan.frontTires())}</label>
                            <span>
                                {
                                    item?.owner_vehicle?.front_tire?.width ? item?.owner_vehicle?.front_tire?.width + ' / ' : '-/'
                                }
                                {
                                    item?.owner_vehicle?.front_tire?.height ? item?.owner_vehicle?.front_tire?.height + ' / ' : '-/'
                                }

                                {
                                    item?.owner_vehicle?.front_tire?.diameter ? item?.owner_vehicle?.front_tire?.diameter : '-'
                                }

                            </span>
                        </DetailListing>
                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(...lan.backTires())}</label>
                            <span>
                                {
                                    item?.owner_vehicle?.back_tire?.width ? item?.owner_vehicle?.back_tire?.width + ' / ' : '-/'
                                }
                                {
                                    item?.owner_vehicle?.back_tire?.height ? item?.owner_vehicle?.back_tire?.height + ' / ' : '-/'
                                }
                                {
                                    item?.owner_vehicle?.back_tire?.diameter ? item?.owner_vehicle?.back_tire?.diameter : null
                                }
                            </span>
                        </DetailListing>
                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(...lan.runflat())}</label>
                            <span>{item?.owner_vehicle?.tire_runflat === wheelTypes.wheel ? t(...lan.yes()) : t(...lan.no())}</span>
                        </DetailListing>
                        {item?.owner_vehicle?.product_type_id === productTypes.car &&
                            <>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(...lan.saison())}</label>
                                    <span>{item?.owner_vehicle?.tire_type?.name || "-"}</span>
                                </DetailListing>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(...lan.spareWheel())}</label>
                                    <span>{item?.owner_vehicle?.spare_wheel === wheelTypes.wheel ? t(...lan.yes()) : t(...lan.no())}</span>
                                </DetailListing> </>
                        }
                    </Box>
                </Grid>}
        </>
    )
}

export default TyresDetail