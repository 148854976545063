import { Box, Button, Popper, Typography } from '@mui/material';
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import { UserMessage } from '../../interface/interface';
import moment from 'moment';
import ZoomImage from 'app/components/GlobalComponent/ZoomImage';
import { useEffect, useRef, useState } from 'react';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { SvgDoc2 } from 'app/components/svgicons/svg';
import { localstorageKey } from 'config/constants';
import { EmailValidationRegex, PhoneValidationRegex, getSubsidiaryStored } from 'config/variables';
import axios from 'axios';
import download from 'downloadjs'
import { ApiPaths } from 'services/ApiPaths';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { sendMessageRequest } from 'store/actions/chat-action';
import * as ChatConstants from "config/chatConfig"
import { SvgDeliveryTime } from 'app/components/svgicons/svg2';
import { SvgCopy, SvgRetryIcon } from 'app/components/svgicons/svgNew';
import sanitizeHtml from 'sanitize-html';
import useOutsideAlerter from 'app/Hooks/outSideDiv';
import { decodeHTMLEntities, fallbackCopyText } from 'config/utils';
import { Toaster } from 'services/Toaster';




const ChatMessage = ({ self = true, message, userData, time, mediaFiles, recID, currentMessage, chatId, refId }: UserMessage) => {

  const [open, setOpen] = useState<number>(0)
  const { t } = useTranslation();

  const { blockedByUsers, blockedBySubs, sendMessageSuccessData, sendMessageFailureData } = useSelector((state: RootStateOrAny) => state.chat)

  const [isBlocked, _isBlocked] = useState<boolean>(false)
  const [imageUrl, _imageUrl] = useState<Object>({})

  const [waiting, setWaiting] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const ref = useRef()
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popper' : undefined;

  const dispatch = useDispatch()
  const setTimeoutRef = useRef(null);


  const popperProps = {
    open: isOpen,
    anchorEl: {
      getBoundingClientRect: () => ({
        top: cursorPosition.y,
        left: cursorPosition.x,
        right: cursorPosition.x,
        bottom: cursorPosition.y,
        width: 0,
        height: 0,
      }),
    },
    placement: 'bottom-start' as const,
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setCursorPosition({ x: event.clientX, y: event.clientY });
    setAnchorEl(event.currentTarget);
  };

  useOutsideAlerter(ref, () => {
    setAnchorEl(null)
  })

  useEffect(() => {
    setWaiting(false);
    // If API is taking more than 1.5 sec then we will show waiting icon
    if ((recID === undefined || recID === null) && !failed) {
      const timeoutRef = setTimeout(() => {
        setWaiting(true);
      }, 1500);
      setTimeoutRef.current = timeoutRef;
    } else {
      clearTimeout(setTimeoutRef.current);
      setTimeoutRef.current = null; // Clear the reference
    }

    return () => {
      clearTimeout(setTimeoutRef.current);
    };
  }, [recID, failed]);

  // If API has failed, then we will show retry icon by comparing refID of the message
  useEffect(() => {
    if (refId && sendMessageFailureData && sendMessageFailureData.data && sendMessageFailureData.data.refId == refId) {
      setWaiting(false)
      setFailed(true)
    }
  }, [sendMessageFailureData])

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      // for hidding user image if blocked 
      if ((userData.subsidiary_id && blockedBySubs.some(o => o == userData.subsidiary_id)) || (userData.subsidiary_id == null && userData.id && blockedByUsers.some(o => o == userData.id))) {
        _isBlocked(true)
      } else {
        _isBlocked(false)
      }
    }
  }, [userData, blockedByUsers, blockedBySubs])


  const reSendMessage = (msgData) => {
    setFailed(false)
    // Sending message if user is connected to internet        
    // Code for sending data
    let data = {
      chat_id: chatId,
      type: msgData.media && msgData.media.length > 0 ? ChatConstants.attachmentType.FILE_OR_IMAGE : ChatConstants.attachmentType.TEXT,
      message: msgData.message,
      attachment: msgData.media,
      refId: refId
    }
    dispatch(sendMessageRequest(data))
  }


  useEffect(() => {
    if (refId && sendMessageSuccessData.success && sendMessageSuccessData.data.refId == refId) {
      setWaiting(false)
      setFailed(false)
    }
  }, [sendMessageSuccessData])

  useEffect(() => {
    if (mediaFiles?.length > 0) {
      const slides = mediaFiles?.map((image) => {
        return image.mime_type.includes("image") && { src: image.original_url }
      })
      _imageUrl(slides)
    }
  }, [mediaFiles])

  const downloadPdf = (id, message, index) => {
    const token = localStorage.getItem('token');
    let getLanguaget = localStorage.getItem(localstorageKey.i18nextLng);
    let laguagesVariable;
    if (getLanguaget === 'en-GB' || getLanguaget === 'us' || getLanguaget === 'en') {
      laguagesVariable = 'en'
    } else if (getLanguaget === 'fr') {
      laguagesVariable = 'fr'
    } else {
      laguagesVariable = getLanguaget ? getLanguaget : 'fr'
    }
    axios.get(`${process.env.REACT_APP_API_URL}${ApiPaths.chat.chats}/${id}/download` + (getSubsidiaryStored() ? `?subsidiary_id=${getSubsidiaryStored()}` : ''),
      {
        headers: {
          'Content-type': 'application/pdf',
          Authorization: 'Bearer ' + token,
          "Accept-Language": laguagesVariable,
        },
        responseType: 'blob'
      }
    ).then((response) => {
      const content = response.headers['content-type'];
      download(response.data, mediaFiles[index].file_name, content)
    })
  }

  const linkRegex = /\b(?:https?|ftp):\/\/(?:www\.)?[^\s]+\b/g

  // Converts text to hyperlink if any of the link exists in message text   
  const linkGenerator = (matched) => {
    let withProtocol = matched
    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched
    }
    let newStr = '';
    let checkEmail = EmailValidationRegex.email.test(matched)
    let checkPhone = PhoneValidationRegex.phone.test(matched)

    if (checkEmail) {
      withProtocol = matched
      newStr = `<a  className="text-link" href="mailto:${withProtocol} ">${matched}</a>`
      return newStr
    }
    if (checkPhone) {
      withProtocol = matched
      newStr = `<a  className="text-link" href="tel:${withProtocol}">${matched}</a>`
      return newStr
    }
    newStr = `<a  className="text-link" href="${withProtocol}" target="_blank">${matched}</a>`

    return newStr
  }
  // add hyperlinks in the message
  const getMessageHtml = (message) => {
    if (currentMessage.url && currentMessage.full_title) {
      let str = message.replace(currentMessage.full_title, `<a  href="${currentMessage?.url}" target="_blank">${currentMessage.full_title}</a>`)
      return str
    }
    return message
      ? linkRegex.test(message) === true
        ? message.replace(linkRegex, linkGenerator)
        : PhoneValidationRegex.phone.test(message)
          ? message.replace(PhoneValidationRegex.phone, linkGenerator)
          : message
      : ""
  }

  const handleCopy = () => {
    const textToCopy = decodeHTMLEntities(message) || "";

    // Check if the clipboard API is supported
    if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
        })
        .catch((err) => {
          fallbackCopyText(textToCopy);
        });
    } else {
      // Fallback method for older browsers or unsupported environments
      fallbackCopyText(textToCopy);
    }

    Toaster.success(t(translations.CHAT.COPIED))
    setAnchorEl(null);
  };




  return (
    <>
      <Box
        className={(self ? 'selfMessageContainer' : '') + ' messageContainer flexRow'}
      >

        {self && waiting && <SvgDeliveryTime style={{ alignSelf: 'center' }} className='pointer align' title='Waiting' />}

        {self && failed && <SvgRetryIcon style={{ alignSelf: 'center' }} className='pointer align' title='Message Failed ! Retry' onClick={() => reSendMessage(currentMessage)} />}

        {/* Displays User Avatar when message is not of type self */}
        {!self && <Box sx={{ marginTop: '5px' }} className='messageAvataricon'>
          <img src={userData.profile_picture_url && !isBlocked ? userData.profile_picture_url : userPlaceHolder} alt={"Profile-Picture"} />
        </Box>}

        <Box className={`${self ? 'selfMessage' : ''} message flexColumn`}>
          {/* Renders when attachement is available */}
          {mediaFiles && mediaFiles.length > 0 &&
            <>
              <span className="messageInfo" style={{ marginRight: self ? '5px' : '0px' }}>{!self ? (userData.name ? userData.name + ", " : t(translations.CHAT.ANONYMOUS) + ", ") : ""} {moment(time).format("HH:mm")}</span>
              {mediaFiles.map((mediaFile, index) => {
                return (
                  <Box className={(self ? 'selfMessageText' : 'messageFile')} style={{ marginBottom: '5px' }} key={index}>
                    <Box className='fileContainer'>
                      {/* If file type Image */}
                      {mediaFile.mime_type.includes("image") &&
                        <>
                          <Box className='attachment pointer' onClick={() => setOpen(index + 1)}>
                            <img src={mediaFile.medium_url} alt={'Message:Image'} />
                          </Box>
                        </>
                      }

                      {/* If file type other than Image */}
                      {!mediaFile.mime_type.includes("image") &&
                        <Box className='fileMessage'>
                          <span>{mediaFile.file_name}</span>
                          <Box className='flexRow alignItemsCenter'>
                            <SvgDoc2 className={self ? 'fileIconSelf' : 'fileIcon'} style={{ margin: '0 5px 0 10px' }} /> <span>{t(translations.CHAT.FILE)}</span>
                          </Box>
                          {mediaFile.original_url ?
                            <Button className={`${self ? 'selfButton' : 'otherButton'} pointer`} onClick={() => downloadPdf(mediaFile.id, message, index)}>{t(translations.CHAT.DOWNLOAD)}</Button> :
                            <Button className={`${self ? 'selfButton' : 'otherButton'} pointer`} >{t(translations.CHAT.UPLOADING)}</Button>}
                        </Box>
                      }
                    </Box>
                  </Box>
                )
              })}

            </>
          }
          <ZoomImage slides={imageUrl} open={open} setOpen={setOpen} hideThumbnail={true} />
          {/* Renders when text message is available  */}
          {message && <>
            {(!mediaFiles || (mediaFiles && mediaFiles.length === 0)) && <span className="messageInfo" style={{ marginRight: self ? '5px' : '0px' }}>{!self ? (userData.name ? userData.name + ", " : t(translations.CHAT.ANONYMOUS) + ", ") : ""} {moment(time).format("HH:mm")}</span>}
            <Box className={(self ? 'selfMessageText' : 'messageText')} sx={{ marginTop: mediaFiles && Object.keys(mediaFiles).length > 0 ? '5px' : '0px' }} onContextMenu={handleClick}>
              {
                currentMessage?.url ? <>
                  <div>
                    <Typography
                      sx={{
                        padding: '5px',
                        whiteSpace: 'pre-wrap',
                        'a': {
                          color: self ? 'white' : '#6579F4',
                          fontStyle: 'italic',
                          textDecoration: 'underline',
                        }
                      }} dangerouslySetInnerHTML={{ __html: sanitizeHtml(getMessageHtml(message)) }}>

                    </Typography>
                  </div>
                </>
                  : <>
                    <Typography
                      sx={{
                        padding: '5px',
                        whiteSpace: 'pre-wrap',
                        'a': {
                          color: self ? 'white' : '#6579F4',
                          fontStyle: 'italic',
                          textDecoration: 'underline',
                        }
                      }}
                      dangerouslySetInnerHTML={{ __html: sanitizeHtml(getMessageHtml(message)) }}>
                    </Typography>
                  </>
              }
              <Popper id={id} {...popperProps} sx={{ zIndex: 1400 }}>

                <button className='copyButton' onClick={handleCopy} ref={ref} >
                  <SvgCopy className='copySvg' />
                  {t(translations.CHAT.COPY)}
                </button>
              </Popper>
            </Box>
          </>
          }
        </Box>

      </Box>
    </>
  );
};

export default ChatMessage;