import styled from 'styled-components';
import { colorNew } from 'styles/StyleConstants';

export const CarouselWrapper = styled.div`
  .control-arrow {
    height: 50px !important;
    top: 40% !important;
  }
  .carousel img {
    pointer-events: auto;
  }
  .image-container img {
    border: 1px solid ${colorNew.colorGrayButton};
  }
  .carousel.carousel-slider .control-arrow {
    display: none;
    right: 1px;
  }
  .carousel-root:hover {
    .carousel.carousel-slider .control-arrow {
      display: inline-block;
      width: max-content;
      &.control-disabled {
        display: none;
      }
    }
  }
  .contentBox {
    display: flex;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(2px);
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.9);
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .contentBox span {
    margin: 0;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .imageCount {
    top: 10px;
    left: 10px;
    z-index: 1;
    position: absolute;
    padding: 0 10px;
  }
  .kmCount {
    top: 10px;
    right: 10px;
  }
  .kmIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(243, 220, 148, 0.44);
    width: 33px;
    height: 33px;
    margin-left: 5px;
  }
  .shareIcon {
    width: 35px;
    height: 35px;
    bottom: 65px;
    right: 12px;
    cursor: pointer;
    padding: 5px !important;
  }
  .shareIcon svg path {
    fill: rgba(255, 255, 255, 0.9);
  }
  .likeIcon {
    width: 35px;
    height: 35px;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    padding: 5px !important;
  }
  @media screen and (min-width: 993px) {
    .display-discount .likeIcon {
      bottom: 55px;
    }
    .display-discount .share-icon {
      bottom: 100px;
    }
  }
  .actionBox {
    position: absolute;
    width: 70%;
    height: 100%;
    display: flex;
    left: 15%;
    top: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
  }
  .carousel.carousel-slider .control-arrow {
    background: rgb(0 0 0 / 47%);
    opacity: 1;
    width: max-content;
  }

  @media (max-width: 992px) {
    .image-container img {
      border-radius: 2px;
    }
    .carousel.carousel-slider .control-arrow {
      display: none;
    }
    .carousel-root:hover {
      .carousel.carousel-slider .control-arrow {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    .image-container img {
      aspect-ratio: 4 /3;
      object-fit: contain;
    }
  }
`;
