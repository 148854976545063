import { Ad } from '../../interface/interface'
import ImageCarousel from 'app/components/imageCarousel'
import { Box, Button, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { adsTypeName } from 'config/constants'
import { translations } from 'locales/translations'
import { numberFormat, productTypes } from 'config/variables'
import { getURL } from 'config/appConfig'
import { routes } from 'routes/routes'
import UserDetails from 'app/components/UserDetails'
import { chatCases, createUserSubchat } from 'config/chatConfig'
import { BikeStepIcon, CarIcon } from 'app/Component2/Layouts/AdsLayouts/component/AdsListing/VehicleTypeIcon'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { PublicAdsWrapper } from '../../style/PublicAdsWrapper'
import InfoModal from 'app/components/InfoModal/info'
import { handleBuyCredits } from 'config/utils'

const PublicAd = ({ adDetail, showUserDetails = true }: Ad) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const displayDiscountBox = adDetail?.user_data?.hotdeal_discount !== undefined && adDetail?.user_data?.hotdeal_discount !== null ? adDetail?.user_data?.hotdeal_discount : 0;

    const { loggedIn } = useSelector((state: any) => state.auth);

    const shareUrl = getURL(routes.vehicleRoutes.publicAds + "/" + adDetail.slug);

    const title = `${t(translations.webTexts.SHARED_FROM_CARTOBIKE)}` + '\n\n' +
        `${adDetail.owner_vehicle.full_title}` + '\n' +
        `${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_PRICE) + ": € " + numberFormat(adDetail.total_price)}` + '\n' +
        `${t(translations.ads.FIRSTUSE) + ':'} ${adDetail.owner_vehicle.new_vehicle ? t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW) : adDetail.owner_vehicle.vehicle_first_register_month && adDetail.owner_vehicle.vehicle_first_register_year ? " " + adDetail.owner_vehicle.vehicle_first_register_month + '/' + adDetail.owner_vehicle.vehicle_first_register_year : ""}` + '\n' +
        `${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_KM)}: ${numberFormat(adDetail.owner_vehicle.vehicle_mileage)} km` + '\n' +
        `${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_COUNTRY)}: ${adDetail.user_data.limited_address.country}` + '\n';

    const imageAltText = adDetail.owner_vehicle.full_title

    const handleChat = () => {
        if (loggedIn) {
            createUserSubchat(adDetail.id, adDetail.owner_vehicle_id, chatCases.ADS_DETAIL_PAGE_CHAT, dispatch)
        }
    }

    const handleNavigate = () => {
        history.push(routes.vehicleRoutes.publicAds + "/" + adDetail.slug)
    }

    const discountBox = () => {
        return <Box className='discountBox'>
            <Box className='discountBoxTextContainer'>
                <p>{t(translations.PUBLIC_ADS_SCREEN.DISCOUNT_HEAD, { "price": process.env.REACT_APP_DEFAULT_DISCOUNT })}</p>
                <span>{t(translations.PUBLIC_ADS_SCREEN.DISCOUNT_TEXT)}</span>
            </Box>
            <Button className='discountButton' onClick={() => handleBuyCredits(history, history.location.pathname)}>
                {t(translations.PUBLIC_ADS_SCREEN.DISCOUNT_BUTTON)}
            </Button>
        </Box>
    }

    return (
        <PublicAdsWrapper className={`${(isLargeDevice || isExtraLargeDevice) ? 'flexRow' : 'flexColumn'} adContainer ${Boolean(displayDiscountBox) ? 'display-discount' : ''}`} id={adDetail.slug}>
            <Box className='imageContainer'>
                <ImageCarousel adType={adsTypeName.classicAds} images={adDetail?.owner_vehicle?.media ?? []} imageAltText={imageAltText} kmCount={adDetail?.owner_vehicle?.battery_range_max ?? 0} adId={adDetail.id} title={title} linkToShare={shareUrl} handleNavigate={handleNavigate} url={routes.vehicleRoutes.publicAds + '/' + adDetail.slug} showLikeButton={true} showShareButton={true} displayHotDealBanner={Boolean(displayDiscountBox)} />
                {Boolean(displayDiscountBox) ? discountBox() : null}
            </Box>
            <Box sx={{ px: isSmallDevice ? 0 : 2 }} className='adDetailContainer'>
                <Box sx={{ pb: 1 }}>
                    <Link to={routes.vehicleRoutes.publicAds + "/" + adDetail.slug} onClick={() => localStorage.setItem('prevPath', location.pathname + location.search)}>
                        <Tooltip title={adDetail?.owner_vehicle?.full_title ?? "-"} placement='top-start'>
                            <h2 className='ad-name pointer '> {adDetail?.owner_vehicle?.full_title ?? "-"}</h2>
                        </Tooltip>
                    </Link>
                </Box>
                <Box>
                    {adDetail?.owner_vehicle?.product_type_id === productTypes.car ?
                        <CarIcon
                            regiterDate={
                                adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW)
                                    : adDetail?.owner_vehicle?.new_vehicle === false && adDetail?.owner_vehicle?.vehicle_first_register_month != null ? adDetail?.owner_vehicle?.vehicle_first_register_month + "/" + adDetail?.owner_vehicle?.vehicle_first_register_year : ""
                            }
                            milege={
                                adDetail?.owner_vehicle?.vehicle_mileage !== null ? `${adDetail?.owner_vehicle?.vehicle_mileage} ` : null
                            }
                            gearBox={
                                adDetail?.owner_vehicle?.gearbox?.name
                            }
                            power={
                                adDetail?.owner_vehicle?.power_cv ? adDetail?.owner_vehicle?.power_cv + ' CV' : null
                            }
                            fuel={
                                adDetail?.owner_vehicle?.fuel?.name
                            }
                        />
                        :
                        <BikeStepIcon
                            regiterDate={
                                adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW)
                                    : adDetail?.owner_vehicle?.new_vehicle === false && adDetail?.owner_vehicle?.vehicle_first_register_month != null ? adDetail?.owner_vehicle?.vehicle_first_register_month + "/" + adDetail?.owner_vehicle?.vehicle_first_register_year : ""
                            }
                            gearBox={adDetail?.owner_vehicle?.gearbox?.name || ""}
                            topSpeed={adDetail?.owner_vehicle?.top_speed ? adDetail?.owner_vehicle?.top_speed + ' km/h' : ''}
                            isNew={adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW) : adDetail?.owner_vehicle?.vehicle_first_register_month || adDetail?.owner_vehicle?.vehicle_first_register_year ? t(translations.summaryVehicleScreen.USED) : null}
                            fuel={adDetail?.owner_vehicle?.is_electric ? t(translations.summaryVehicleScreen.ELECTRIC) : ''}
                            motoBikefuel={adDetail?.owner_vehicle?.fuel?.name || ""}
                            betterType={adDetail?.owner_vehicle?.battery_charging_time || ""}
                            power={adDetail?.owner_vehicle?.power_cv ? adDetail?.owner_vehicle?.power_cv + ' CV' : null}
                            frameSize={adDetail?.owner_vehicle?.frame_size?.name || ""}
                            bodyVehicle={adDetail?.owner_vehicle?.body_vehicle ? adDetail?.owner_vehicle?.body_vehicle?.name : ""}
                            milege={adDetail?.owner_vehicle?.vehicle_mileage || null}
                            isMotorCycle={adDetail?.owner_vehicle?.product_type_id === productTypes.motorcycles}

                        />
                    }
                </Box>
                <Box className='price' style={{ marginTop: '10px' }}>
                    <p> {`€ ${numberFormat(Math.trunc(adDetail?.total_price)) ?? 0}`} {adDetail.owner_vehicle.vehicle_deductible_vat ? <span> {t(translations.ads.VAT_INCLUDED)}
                        <InfoModal header={t(translations.HOT_DEALS.HOTDEAL_VAT_HEAD)} content={t(translations.HOT_DEALS.CLASSIC_VAT_DESC)} />
                    </span> : null}</p>
                </Box>
                <UserDetails userDetail={adDetail.user_data} adId={adDetail.id} handleChat={handleChat} showOnlyMessage={true} showUserDetails={showUserDetails} />
            </Box>
        </PublicAdsWrapper>
    )
}

export default PublicAd