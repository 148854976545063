import { ReactComponent as siteLogo } from './NewDesign/siteLogo.svg'
import { ReactComponent as siteLogoBW } from './NewDesign/siteLogobw.svg'
import { ReactComponent as notificationIcon } from './NewDesign/notificationIcon.svg'
import { ReactComponent as chatIcon } from './NewDesign/chatIcon.svg'
import { ReactComponent as desktopIcon } from './NewDesign/desktopIcon.svg'
import { ReactComponent as mobilPhoneIcon } from './NewDesign/mobilePhoneIcon.svg'
import { ReactComponent as bike } from './NewDesign/bike.svg'
import { ReactComponent as car } from './NewDesign/car.svg'
import { ReactComponent as step } from './NewDesign/step.svg'
import { ReactComponent as cross } from './NewDesign/cross.svg'
import { ReactComponent as star1 } from './NewDesign/star1.svg'
import { ReactComponent as pump } from './NewDesign/pump.svg'
import { ReactComponent as setting } from './NewDesign/settingCircle.svg'
import { ReactComponent as kmNew } from './NewDesign/kmNew.svg'
import { ReactComponent as share } from './NewDesign/share.svg'
import { ReactComponent as warning } from './NewDesign/warning.svg'
import { ReactComponent as file } from './NewDesign/file.svg'
import { ReactComponent as plus } from 'app/components/svgicons/NewDesign/plus.svg'
import { ReactComponent as minus } from 'app/components/svgicons/NewDesign/minus.svg'
import { ReactComponent as announce } from 'app/components/svgicons/NewDesign/announce.svg'
import { ReactComponent as auction } from 'app/components/svgicons/NewDesign/auction.svg'
import { ReactComponent as star } from 'app/components/svgicons/NewDesign/star.svg'
import { ReactComponent as envelop } from 'app/components/svgicons/NewDesign/envelop.svg'
import { ReactComponent as calender } from 'app/components/svgicons/NewDesign/calender.svg'
import { ReactComponent as translation } from 'app/components/svgicons/NewDesign/translation.svg'
import { ReactComponent as iconBell } from 'app/components/svgicons/NewDesign/iconBell.svg'
import { ReactComponent as calenderAdsIcon } from 'app/components/svgicons/NewDesign/AdsIcon/calenderAds.svg'
import { ReactComponent as fuelIcon } from 'app/components/svgicons/NewDesign/AdsIcon/fuel.svg'
import { ReactComponent as GearBoxIon } from 'app/components/svgicons/NewDesign/AdsIcon/GearBox.svg'
import { ReactComponent as kmCircleIcon } from 'app/components/svgicons/NewDesign/AdsIcon/kmCircle.svg'
import { ReactComponent as meterIcon } from 'app/components/svgicons/NewDesign/AdsIcon/meter.svg'
import { ReactComponent as medalIcon } from 'app/components/svgicons/NewDesign/AdsIcon/medal1.svg'
import { ReactComponent as supportIcon } from 'app/components/svgicons/NewDesign/AdsIcon/support.svg'
import { ReactComponent as heartIcon } from 'app/components/svgicons/NewDesign/AdsIcon/heartIcon.svg'
import { ReactComponent as clickIcon } from 'app/components/svgicons/NewDesign/AdsIcon/clickIcon.svg'
import { ReactComponent as phoneIcon } from 'app/components/svgicons/NewDesign/AdsIcon/phoneIcon.svg'
import { ReactComponent as SearchIcon } from 'app/components/svgicons/NewDesign/AdsIcon/SearchIcon.svg'
import { ReactComponent as verticalDots } from 'app/components/svgicons/NewDesign/verticalDots.svg'
import { ReactComponent as trashIcon } from 'app/components/svgicons/NewDesign/trashIcon.svg'
import { ReactComponent as editPencil } from 'app/components/svgicons/NewDesign/editPencil.svg'
import { ReactComponent as soldBag } from 'app/components/svgicons/NewDesign/soldBag.svg'
import { ReactComponent as pdfIcon } from 'app/components/svgicons/NewDesign/psfIocn.svg'
import { ReactComponent as infoIcon } from 'app/components/svgicons/NewDesign/infoIcon.svg'
import { ReactComponent as onSaleIcon } from 'app/components/svgicons/NewDesign/onSale.svg'
import { ReactComponent as forsaleWhite } from 'app/components/svgicons/NewDesign/forsaleWhite.svg'
import { ReactComponent as email } from 'app/components/svgicons/NewDesign/email.svg'
import { ReactComponent as mobile } from 'app/components/svgicons/NewDesign/mobile.svg'
import { ReactComponent as checkup } from 'app/components/svgicons/NewDesign/checkup.svg'
import { ReactComponent as expertise } from 'app/components/svgicons/NewDesign/expertise.svg'
import { ReactComponent as schedule } from 'app/components/svgicons/NewDesign/schedule.svg'
import { ReactComponent as down } from 'app/components/svgicons/NewDesign/down.svg'
import { ReactComponent as mobileFrame } from 'app/components/svgicons/NewDesign/mobileFram.svg'
import { ReactComponent as office } from 'app/components/svgicons/NewDesign/office.svg'
import { ReactComponent as closeIcon } from 'app/components/svgicons/NewDesign/closeIcon.svg'
import { ReactComponent as tagIcon } from 'app/components/svgicons/NewDesign/tagIcon.svg'
import { ReactComponent as trophyIcon } from 'app/components/svgicons/NewDesign/trophyIcon.svg'
import { ReactComponent as homeicon } from 'app/components/svgicons/NewDesign/homeicon.svg'
import { ReactComponent as userILineIcon } from 'app/components/svgicons/NewDesign/userILineIcon.svg'
import { ReactComponent as userHammerIcon } from 'app/components/svgicons/NewDesign/userHammerIcon.svg'
import { ReactComponent as faqIcon } from 'app/components/svgicons/NewDesign/faqIcon.svg'
import { ReactComponent as vehicleBody } from 'app/components/svgicons/NewDesign/filterICon/bodyicon.svg'
import { ReactComponent as brandIcon } from 'app/components/svgicons/NewDesign/filterICon/brandicon.svg'
import { ReactComponent as countryicon } from 'app/components/svgicons/NewDesign/filterICon/countryicon.svg'
import { ReactComponent as damageicon } from 'app/components/svgicons/NewDesign/filterICon/damageicon.svg'
import { ReactComponent as driving_rangeicon } from 'app/components/svgicons/NewDesign/filterICon/driving_rangeicon.svg'
import { ReactComponent as engine_powericon } from 'app/components/svgicons/NewDesign/filterICon/engine_powericon.svg'
import { ReactComponent as first_useicon } from 'app/components/svgicons/NewDesign/filterICon/first_useicon.svg'
import { ReactComponent as fuelicon } from 'app/components/svgicons/NewDesign/filterICon/fuelicon.svg'
import { ReactComponent as max_speedicon } from 'app/components/svgicons/NewDesign/filterICon/max_speedicon.svg'
import { ReactComponent as modelicon } from 'app/components/svgicons/NewDesign/filterICon/modelicon.svg'
import { ReactComponent as no_seatsicon } from 'app/components/svgicons/NewDesign/filterICon/no_seatsicon.svg'
import { ReactComponent as preview_owenericon } from 'app/components/svgicons/NewDesign/filterICon/preview_owenericon.svg'
import { ReactComponent as price_1icon } from 'app/components/svgicons/NewDesign/filterICon/price_1icon.svg'
import { ReactComponent as mapIconTarget } from 'app/components/svgicons/NewDesign/filterICon/mapIconTarget.svg'
import { ReactComponent as transmissionicon } from 'app/components/svgicons/NewDesign/filterICon/transmissionicon.svg'
import { ReactComponent as checkIcon } from 'app/components/svgicons/NewDesign/filterICon/checkIcon.svg'
import { ReactComponent as electricIcon } from 'app/components/svgicons/NewDesign/filterICon/electricIcon.svg'
import { ReactComponent as coloricon } from 'app/components/svgicons/NewDesign/filterICon/coloricon.svg'
import { ReactComponent as seller } from 'app/components/svgicons/NewDesign/filterICon/seller.svg'
import { ReactComponent as public_charging } from 'app/components/svgicons/NewDesign/filterICon/public_charging.svg'
import { ReactComponent as home_charging } from 'app/components/svgicons/NewDesign/filterICon/home_charging.svg'
import { ReactComponent as chevronRight } from 'app/components/svgicons/NewDesign/filterICon/chevronRight.svg'
import { ReactComponent as checkICon } from 'app/components/svgicons/NewDesign/checkICon.svg'
import { ReactComponent as mapTarget } from 'app/components/svgicons/NewDesign/mapTarget.svg'
import { ReactComponent as about } from 'app/components/svgicons/NewDesign/about.svg'
import { ReactComponent as stock } from 'app/components/svgicons/NewDesign/stock.svg'
import { ReactComponent as services } from 'app/components/svgicons/NewDesign/services.svg'
import { ReactComponent as contacts } from 'app/components/svgicons/NewDesign/contacts.svg'
import { ReactComponent as gearBox } from 'app/components/svgicons/NewDesign/gearBox.svg'
import { ReactComponent as facebookIcon } from 'app/components/svgicons/NewDesign/socialIcon/facebookIcon.svg'
import { ReactComponent as instaIcon } from 'app/components/svgicons/NewDesign/socialIcon/instaIcon.svg'
import { ReactComponent as snapIcon } from 'app/components/svgicons/NewDesign/socialIcon/snapIcon.svg'
import { ReactComponent as ticktokIcon } from 'app/components/svgicons/NewDesign/socialIcon/ticktokIcon.svg'
import { ReactComponent as twitterIcon } from 'app/components/svgicons/NewDesign/socialIcon/twitterIcon.svg'
import { ReactComponent as chevronIcon } from 'app/components/svgicons/NewDesign/chevronIcon.svg'
import { ReactComponent as battery } from 'app/components/svgicons/NewDesign/battery.svg'
import { ReactComponent as mobile_range } from 'app/components/svgicons/NewDesign/mobile_range.svg'
import { ReactComponent as wifi } from 'app/components/svgicons/NewDesign/wifi.svg'
import { ReactComponent as VectorDownArrow } from 'app/components/svgicons/NewDesign/VectorDownArrow.svg'
import { ReactComponent as VectorDownArrowCircle } from 'app/components/svgicons/NewDesign/VectorDownArrowCircle.svg'
import { ReactComponent as HomeIcon } from 'app/components/svgicons/NewDesign/Home.svg'
import { ReactComponent as EditProfile } from 'app/components/svgicons/NewDesign/EditProfile.svg'
import { ReactComponent as CompanyIcon } from 'app/components/svgicons/NewDesign/CompanyIcon.svg'
import { ReactComponent as CarSettings } from 'app/components/svgicons/NewDesign/CarSettings.svg'
import { ReactComponent as verifiedBrand } from 'app/components/svgicons/NewDesign/verifiedBrand.svg'
import { ReactComponent as Privacy } from 'app/components/svgicons/NewDesign/Privacy.svg'
import { ReactComponent as PrivacyIcon } from 'app/components/svgicons/NewDesign/PrivacyIcon.svg'
import { ReactComponent as fluentIcon } from 'app/components/svgicons/NewDesign/fluentIcon.svg'
import { ReactComponent as LocationIcon } from 'app/components/svgicons/NewDesign/LocationIcon.svg'
import { ReactComponent as MailIcon } from 'app/components/svgicons/NewDesign/MailIcon.svg'
import { ReactComponent as CallIcon } from 'app/components/svgicons/NewDesign/CallIcon.svg'
import { ReactComponent as deleteAccount } from 'app/components/svgicons/NewDesign/deleteAccount.svg'
import { ReactComponent as CarbonSettings } from 'app/components/svgicons/NewDesign/CarbonSettings.svg'
import { ReactComponent as AddCompany } from 'app/components/svgicons/NewDesign/AddCompany.svg'
import { ReactComponent as signOut } from 'app/components/svgicons/NewDesign/signOut.svg'
import { ReactComponent as CircleArrow } from 'app/components/svgicons/NewDesign/CircleArrow.svg'
import { ReactComponent as MobileLeftArrow } from 'app/components/svgicons/NewDesign/MobileLeftArrow.svg'
import { ReactComponent as MobileSort } from 'app/components/svgicons/NewIcons/mobileSort.svg'
import { ReactComponent as bill } from 'app/components/svgicons/NewDesign/bill.svg'
import { ReactComponent as role } from 'app/components/svgicons/NewDesign/role.svg'
import { ReactComponent as subscription } from 'app/components/svgicons/NewDesign/subscription.svg'
import { ReactComponent as payment_method } from 'app/components/svgicons/NewDesign/payment_method.svg'
import { ReactComponent as EpMenu } from 'app/components/svgicons/NewDesign/EpMenu.svg'
import { ReactComponent as FillerIcon } from 'app/components/svgicons/NewDesign/FillerIcon.svg'
import { ReactComponent as Save_Showcase } from 'app/components/svgicons/NewDesign/Save_Showcase.svg'
import { ReactComponent as memSearch } from 'app/components/svgicons/NewDesign/memSearch.svg'
import { ReactComponent as MyAds } from 'app/components/svgicons/NewDesign/MyAds.svg'
import { ReactComponent as ActionPlace } from 'app/components/svgicons/NewDesign/ActionPlace.svg'
import { ReactComponent as Dashboard } from 'app/components/svgicons/NewDesign/dashboard.svg'
import { ReactComponent as Hotdeals } from 'app/components/svgicons/NewDesign/hotdeals.svg'
import { ReactComponent as Coins } from 'app/components/svgicons/NewDesign/coins.svg'
import { ReactComponent as RefundedCoins } from 'app/components/svgicons/NewDesign/RefundedCoins.svg'
import { ReactComponent as SecurityIcon } from 'app/components/svgicons/NewDesign/SecurityIcon.svg'

import { ReactComponent as Ads } from 'app/components/svgicons/NewDesign/ads.svg'
import { ReactComponent as Faq } from 'app/components/svgicons/NewDesign/Faq.svg'
import { ReactComponent as cameraicon } from 'app/components/svgicons/NewDesign/cameraicon.svg'
import { ReactComponent as Legal } from 'app/components/svgicons/NewDesign/Legal.svg'
import { ReactComponent as LanguageIcon } from 'app/components/svgicons/NewDesign/LanguageIcon.svg'
import { ReactComponent as DeleteIcon } from 'app/components/svgicons/NewDesign/DeleteIcon.svg'
import { ReactComponent as DutyDateEdit } from 'app/components/svgicons/NewDesign/DutyDateEdit.svg'
import { ReactComponent as CardEditIcon } from 'app/components/svgicons/NewDesign/CardEditIcon.svg'
import { ReactComponent as checkRound } from 'app/components/svgicons/NewDesign/checkRound.svg'
import { ReactComponent as CameraBlue } from 'app/components/svgicons/NewDesign/cameraBlue.svg'
import { ReactComponent as playStoreLogo } from 'app/components/svgicons/icons/playStore.svg'
import { ReactComponent as appStoreLogo } from 'app/components/svgicons/icons/apple.svg'

import { ReactComponent as bodyIcon } from 'app/components/svgicons/NewDesign/body.svg'
import { ReactComponent as BraketypeIcon } from 'app/components/svgicons/NewDesign/Braketypeicon.svg'
import { ReactComponent as BrandEngineIcon } from 'app/components/svgicons/NewDesign/BrandEngine.svg'
import { ReactComponent as CapacityIcon } from 'app/components/svgicons/NewDesign/CapacityIcon.svg'
import { ReactComponent as ConditionMaintananceIconIcon } from 'app/components/svgicons/NewDesign/conditionmaintananceIcon.svg'
import { ReactComponent as DrivetypeIcon } from 'app/components/svgicons/NewDesign/DrivetypeIcon.svg'
import { ReactComponent as EnginePositionIcon } from 'app/components/svgicons/NewDesign/EnginePosition.svg'
import { ReactComponent as SexIcon } from 'app/components/svgicons/NewDesign/fontisto_intersex.svg'
import { ReactComponent as FrameIcon } from 'app/components/svgicons/NewDesign/frame.svg'
import { ReactComponent as FrameSizeIcon } from 'app/components/svgicons/NewDesign/FramesizeIcon.svg'
import { ReactComponent as GrommetIconsHostMaintenance } from 'app/components/svgicons/NewDesign/grommet-icons_host-maintenance.svg'
import { ReactComponent as HystoryMaintenance } from 'app/components/svgicons/NewDesign/Hystory_maintenance.svg'
import { ReactComponent as AirbagsIcon } from 'app/components/svgicons/NewDesign/Number_of_airbags.svg'
import { ReactComponent as BatteryIcon } from 'app/components/svgicons/NewDesign/Number_of_battery.svg'
import { ReactComponent as DoorIcon } from 'app/components/svgicons/NewDesign/Number_of_door.svg'
import { ReactComponent as SeatsIcon } from 'app/components/svgicons/NewDesign/Number_of_seats.svg'
import { ReactComponent as PackaheSizeIcon } from 'app/components/svgicons/NewDesign/Packaging_size_.svg'
import { ReactComponent as PositionIcon } from 'app/components/svgicons/NewDesign/PositionIcon.svg'
import { ReactComponent as RegisterTionIcon } from 'app/components/svgicons/NewDesign/registrationIcon.svg'
import { ReactComponent as MaterialIcon } from 'app/components/svgicons/NewDesign/simple-icons_materialdesignicons.svg'
import { ReactComponent as Suspension } from 'app/components/svgicons/NewDesign/Suspension.svg'
import { ReactComponent as WeigthIcon } from 'app/components/svgicons/NewDesign/Weight.svg'
import { ReactComponent as EquipmentIconIcon } from 'app/components/svgicons/NewDesign/equipmentIcon.svg'
import { ReactComponent as home_icon } from 'app/components/svgicons/NewDesign/home_icon.svg'
import { ReactComponent as CararcticonsPixelwheels } from 'app/components/svgicons/NewDesign/arcticons_pixelwheels.svg'
import { ReactComponent as DescriptionIcon } from 'app/components/svgicons/NewDesign/cil_description.svg'
import { ReactComponent as Upholstery } from 'app/components/svgicons/NewDesign/upholstery.svg'
import { ReactComponent as Carbody } from 'app/components/svgicons/NewDesign/Carbody.svg'
import { ReactComponent as tireIconBike } from 'app/components/svgicons/NewDesign/tireIcon.svg'
import { ReactComponent as technician } from 'app/components/svgicons/NewDesign/technician.svg'
import { ReactComponent as spark } from 'app/components/svgicons/NewDesign/spark.svg'
import { ReactComponent as nogel } from 'app/components/svgicons/NewDesign/nogel.svg'
import { ReactComponent as arrowSqure } from 'app/components/svgicons/NewDesign/arrowSqure.svg'
import { ReactComponent as loadingTime } from 'app/components/svgicons/NewDesign/loadingTime.svg'
import { ReactComponent as keyHand } from 'app/components/svgicons/NewDesign/keyHand.svg'
import { ReactComponent as earth } from 'app/components/svgicons/NewDesign/earth.svg'
import { ReactComponent as TabFilter } from 'app/components/svgicons/NewDesign/TabFilter.svg'
import { ReactComponent as vehilcecar } from 'app/components/svgicons/NewDesign/vehilcecar.svg'
import { ReactComponent as pending } from 'app/components/svgicons/NewDesign/pending.svg'
import { ReactComponent as NotIcon } from 'app/components/svgicons/NewDesign/NotIcon.svg'
import { ReactComponent as identity } from 'app/components/svgicons/NewDesign/identity.svg'
import { ReactComponent as selfie } from 'app/components/svgicons/NewDesign/selfie.svg'
import { ReactComponent as selfieUser } from 'app/components/svgicons/NewDesign/selfieUser.svg'
import { ReactComponent as verified } from 'app/components/svgicons/NewDesign/verified.svg'
import { ReactComponent as newusedicon } from 'app/components/svgicons/NewDesign/newusedicon.svg'
import { ReactComponent as sideBarToggler } from 'app/components/svgicons/NewDesign/sideBarToggler.svg'
import { ReactComponent as innerToggler } from 'app/components/svgicons/NewDesign/innerToggler.svg'
import { ReactComponent as towerFilter } from 'app/components/svgicons/NewDesign/towerFilter.svg'

import { ReactComponent as AboutUs } from 'app/components/svgicons/NewDesign/AboutUs.svg'
import { ReactComponent as service } from 'app/components/svgicons/NewDesign/service.svg'
import { ReactComponent as contact } from 'app/components/svgicons/NewDesign/contact.svg'
import { ReactComponent as stockBox } from 'app/components/svgicons/NewDesign/stockBox.svg'
import { ReactComponent as closeRound } from 'app/components/svgicons/NewDesign/closeRound.svg'
import { ReactComponent as copyLink } from 'app/components/svgicons/NewDesign/copylink.svg'
import { ReactComponent as copyWhite } from 'app/components/svgicons/NewDesign/copy-white.svg'
import { ReactComponent as copy } from 'app/components/svgicons/NewDesign/copy.svg'
import { ReactComponent as filterMobile } from 'app/components/svgicons/NewDesign/filterMobile.svg'

import { ReactComponent as AboutUsWhite } from 'app/components/svgicons/NewDesign/AboutUsWhite.svg'
import { ReactComponent as serviceWhite } from 'app/components/svgicons/NewDesign/serviceWhite.svg'
import { ReactComponent as contactWhite } from 'app/components/svgicons/NewDesign/contactWhite.svg'
import { ReactComponent as stockBoxWhite } from 'app/components/svgicons/NewDesign/stockBoxWhite.svg'
import { ReactComponent as Users } from 'app/components/svgicons/NewDesign/Users.svg'
import { ReactComponent as Message } from 'app/components/svgicons/NewDesign/message.svg'
import { ReactComponent as svgUserIcon } from 'app/components/svgicons/NewDesign/svgUserIcon.svg'
import { ReactComponent as newmailicon } from 'app/components/svgicons/NewDesign/newmailicon.svg'
import { ReactComponent as switchDisable } from 'app/components/svgicons/NewDesign/switchDisable.svg'
import { ReactComponent as switchActive } from 'app/components/svgicons/NewDesign/switch.svg'
import { ReactComponent as svgWhiteHome } from 'app/components/svgicons/NewDesign/svgWhiteHome.svg'
import { ReactComponent as foldingBike } from 'app/components/svgicons/NewDesign/foldingBike.svg'
import { ReactComponent as eye } from 'app/components/svgicons/NewDesign/eye.svg'
import { ReactComponent as eyeClosed } from 'app/components/svgicons/NewDesign/eye-two.svg'
import { ReactComponent as bikeBody } from 'app/components/svgicons/NewDesign/bikeBody.svg'
import { ReactComponent as stepBody } from 'app/components/svgicons/NewDesign/stepBody.svg'
import { ReactComponent as uploadIcon } from 'app/components/svgicons/NewDesign/upload-icon.svg'
import { ReactComponent as staroutline } from 'app/components/svgicons/NewDesign/staroutline.svg'
import { ReactComponent as bikeType } from 'app/components/svgicons/NewDesign/bikeType.svg'
import { ReactComponent as stepType } from 'app/components/svgicons/NewDesign/stepType.svg'
import { ReactComponent as carType } from 'app/components/svgicons/NewDesign/carType.svg'
import { ReactComponent as motorCycleType } from 'app/components/svgicons/NewDesign/motorBikeType.svg'
import { ReactComponent as chat } from 'app/components/svgicons/NewDesign/myaddsChatIcon.svg'
import { ReactComponent as retryIcon } from 'app/components/svgicons/NewDesign/retry.svg'
import { ReactComponent as bellIcon } from 'app/components/svgicons/NewDesign/bellIcon.svg'
import { ReactComponent as bellIconDesktop } from 'app/components/svgicons/NewDesign/iconBellDesktop.svg'
import { ReactComponent as chatIconDesktop } from 'app/components/svgicons/NewDesign/chatIconDesktop.svg'
import { ReactComponent as licensePlate } from 'app/components/svgicons/NewDesign/licensePlate.svg'
import { ReactComponent as Sell } from 'app/components/svgicons/NewDesign/Sell.svg'
import { ReactComponent as Orders } from 'app/components/svgicons/NewDesign/Orders.svg'
import { ReactComponent as Edit } from 'app/components/svgicons/NewDesign/Edit.svg'
import { ReactComponent as Delete } from 'app/components/svgicons/NewDesign/Delete.svg'
import { ReactComponent as Cancel } from 'app/components/svgicons/NewDesign/Cancel.svg'
import { ReactComponent as Sold } from 'app/components/svgicons/NewDesign/Sold.svg'
import { ReactComponent as Swap } from 'app/components/svgicons/NewDesign/Swap.svg'
import { ReactComponent as EditPrice } from 'app/components/svgicons/NewDesign/EditPrice.svg'
import { ReactComponent as Checkdark } from 'app/components/svgicons/NewDesign/checkDark.svg'

import { ReactComponent as facebookIconDark } from 'app/components/svgicons/NewDesign/socialIcon/facebookNew.svg'
import { ReactComponent as instaIconDark } from 'app/components/svgicons/NewDesign/socialIcon/instagramNew.svg'
import { ReactComponent as snapchatIconDark } from 'app/components/svgicons/NewDesign/socialIcon/snapchatNew.svg'
import { ReactComponent as tiktokIconDark } from 'app/components/svgicons/NewDesign/socialIcon/tiktokNew.svg'
import { ReactComponent as twitterIconDark } from 'app/components/svgicons/NewDesign/socialIcon/twitterNew.svg'
import { ReactComponent as youtubeDark } from 'app/components/svgicons/NewDesign/socialIcon/youtubeNew.svg'
import { ReactComponent as mobilePreview } from 'app/components/svgicons/NewDesign/homePageIcons/mobilePreview.svg'
import { ReactComponent as stockManagement } from 'app/components/svgicons/NewDesign/homePageIcons/stockManagement.svg'
import { ReactComponent as showcase } from 'app/components/svgicons/NewDesign/homePageIcons/showcase.svg'
import { ReactComponent as privateAccess } from 'app/components/svgicons/NewDesign/homePageIcons/PrivateAccess.svg'
import { ReactComponent as unlimitedAds } from 'app/components/svgicons/NewDesign/homePageIcons/unlimitedAds.svg'
import { ReactComponent as siteLogoWithoutName } from 'app/components/svgicons/NewDesign/AdsIcon/siteLogo.svg'
import { ReactComponent as bikesBody } from 'app/components/svgicons/NewDesign/AdsIcon/bikeBody.svg'
import { ReactComponent as bikeFrame } from 'app/components/svgicons/NewDesign/AdsIcon/bikeFrame.svg'
import { ReactComponent as arrow } from 'app/components/svgicons/NewDesign/addVehicleIcon/arrow.svg'
import { ReactComponent as arrowdown } from 'app/components/svgicons/NewDesign/addVehicleIcon/arrowdown.svg'
import { ReactComponent as arrowBack } from 'app/components/svgicons/NewDesign/addVehicleIcon/arrowBack.svg'
import { ReactComponent as checked } from 'app/components/svgicons/NewDesign/addVehicleIcon/checked.svg'
import { ReactComponent as details } from 'app/components/svgicons/NewDesign/addVehicleIcon/details.svg'
import { ReactComponent as search } from 'app/components/svgicons/NewDesign/addVehicleIcon/search.svg'
import { ReactComponent as edit } from 'app/components/svgicons/NewDesign/addVehicleIcon/edit.svg'

import { ReactComponent as condition } from 'app/components/svgicons/NewDesign/addVehicleIcon/condition.svg'
import { ReactComponent as description } from 'app/components/svgicons/NewDesign/addVehicleIcon/description.svg'
import { ReactComponent as dimension } from 'app/components/svgicons/NewDesign/addVehicleIcon/dimension.svg'
import { ReactComponent as equipment } from 'app/components/svgicons/NewDesign/addVehicleIcon/equipment.svg'
import { ReactComponent as tires } from 'app/components/svgicons/NewDesign/addVehicleIcon/tires.svg'
import { ReactComponent as linkIcon } from 'app/components/svgicons/NewDesign/addVehicleIcon/linkIcon.svg'

import { ReactComponent as priceStar } from 'app/components/svgicons/NewDesign/priceStar.svg'
import { ReactComponent as location } from 'app/components/svgicons/NewDesign/location.svg'
import { ReactComponent as calender_icon } from 'app/components/svgicons/NewDesign/calender.svg'
import { ReactComponent as notification_icon } from 'app/components/svgicons/NewDesign/notification.svg'
import { ReactComponent as chat_icon } from 'app/components/svgicons/NewDesign/chatIconHeader.svg'
import { ReactComponent as helpCenter } from 'app/components/svgicons/ProfilePopupIcons/helpCenter.svg'
import { ReactComponent as languageChangeIcon } from 'app/components/svgicons/ProfilePopupIcons/languageChange.svg'
import { ReactComponent as logoutIcon } from 'app/components/svgicons/ProfilePopupIcons/logout.svg'
import { ReactComponent as myAccountIcon } from 'app/components/svgicons/ProfilePopupIcons/myAccountSvg.svg'
import { ReactComponent as userIcon } from 'app/components/svgicons/ProfilePopupIcons/user.svg'
import { ReactComponent as addNewIcon } from 'app/components/svgicons/ProfilePopupIcons/addNew.svg'

import { ReactComponent as CartobikeCredit } from 'app/components/svgicons/NewIcons/creditIcon.svg'
import { ReactComponent as bancontact } from 'app/components/svgicons/NewIcons/bancontactIcon.svg'
import { ReactComponent as visa } from 'app/components/svgicons/NewIcons/visaIcon.svg'



export const SvgBancontact = bancontact;
export const SvgVisa = visa;
export const SvgUserIcon = userIcon;
export const SvgCartobikeCredit = CartobikeCredit;
export const SvgAddNewIcon = addNewIcon;
export const SvgHelpCenter = helpCenter;
export const SvgLanguageChangeIcon = languageChangeIcon;
export const SvgLogoutIcon = logoutIcon;
export const SvgMyAccountIcon = myAccountIcon;
export const SvgCondition = condition;
export const SvgNotificationIconHeader = notification_icon;
export const SvgChatIconHeader = chat_icon;
export const SvgLinkIcon = linkIcon;
export const SvgDescription = description;
export const SvgDimension = dimension;
export const SvgEquipment = equipment;
export const Svgcalender = calender_icon;
export const SvgTires = tires;

export const SvgArrow = arrow;
export const SvgEditNew = edit;
export const SvgSearch = search;
export const Svgdetails = details;
export const SvgChecked = checked;
export const SvgArrowBack = arrowBack;
export const SvgArrowDown = arrowdown;
export const SvglicensePlate = licensePlate;
export const SvgLocation = location;
export const SvgPriceStar = priceStar;
export const SvgSiteLogoWithoutName = siteLogoWithoutName;
export const SvgmobilePreview = mobilePreview;
export const SvgStockManagement = stockManagement;
export const SvgShowcase = showcase;
export const SvgPrivateAccess = privateAccess;
export const SvgUnlimitedAds = unlimitedAds;
export const SvgCopyLink = copyLink;
export const SvgCopy = copy;
export const SvgCopyWhite = copyWhite;
export const SvgBellIcon = bellIcon;
export const SvgBellIconDesktop = bellIconDesktop;
export const SvgChatIconDesktop = chatIconDesktop;
export const SvgMyAdChat = chat;
export const SvgEyeClosed = eyeClosed;
export const SvgEye = eye;
export const SvgSwitch = switchActive;
export const SvgSwitchDisable = switchDisable;
export const SvgNewMailIcon = newmailicon;
export const SvgUserOutline = Users;
export const SvgHomeIcon2 = home_icon;
export const SvgSaveFile = Save_Showcase;
export const SvgBill = bill;
export const SvgRole = role;
export const SvgSubscription = subscription;
export const SvgPaymentMethod = payment_method;
export const SvgAbout = about;
export const SvgCircleArrow = CircleArrow;
export const SvgVectorDownArrow = VectorDownArrow;
export const SvgVectorDownArrowCircle = VectorDownArrowCircle;
export const SvgBattery = battery;
export const SvgMobileRange = mobile_range;
export const SvgWifi = wifi;
export const SvgGearBox = gearBox;
export const SvgStock = stock;
export const SvgServices = services;
export const SvgContacts = contacts;
export const SvgDown = down;
export const SvgMobileFrame = mobileFrame;
export const SvgOffice = office;
export const SvgCheckup = checkup;
export const SvgExpertise = expertise;
export const SvgSchedule = schedule;
export const SvgSiteLogo = siteLogo;
export const SvgSiteLogoBW = siteLogoBW;
export const SvgMobilPhoneIcon = mobilPhoneIcon;
export const SvgDesktopIcon = desktopIcon;
export const SvgNotificationIcon = notificationIcon;
export const SvgChatIcon = chatIcon;
export const SvgRetryIcon = retryIcon;
export const SvgPlaystore = playStoreLogo;
export const SvgAppstore = appStoreLogo;
export const SvgBike = bike;
export const SvgCar = car;
export const SvgStep = step;
export const SVGCross = cross;
export const SVGStarHalf = star1;
export const SVGPump = pump;
export const SVGSetting = setting;
export const SVGKmNew = kmNew;
export const SVGShare = share;
export const SVGWarning = warning;
export const SVGFile = file;
export const SvgPlus = plus;
export const SvgMinus = minus;
export const SvgAnnounce = announce;
export const Svgauction = auction;
export const SvgStar = star;
export const SvgEnvelop = envelop;
export const SvgCalender = calender;
export const SvgTranslation = translation;
export const SvgIconBell = iconBell;
export const SvgCalenderAdsIcon = calenderAdsIcon;
export const SvgFuelIcon = fuelIcon;
export const SvgGearBoxIon = GearBoxIon;
export const SvgkmCircleIcon = kmCircleIcon;
export const SvgMeterIcon = meterIcon;
export const SvgMedalIcon = medalIcon;
export const SvgSupportIcon = supportIcon;
export const SvgHeartIcon = heartIcon;
export const SvgClickIcon = clickIcon;
export const SvgPhoneIcon = phoneIcon;
export const SvgSearchIcon = SearchIcon;
export const SvgverticalDots = verticalDots;
export const SvgTrashIcon = trashIcon;
export const SvgEditPencil = editPencil;
export const SvgSoldBag = soldBag;
export const SvgpdfIcon = pdfIcon;
export const SvgInfoIcon = infoIcon;
export const SvgonSaleIcon = onSaleIcon;
export const SvgforsaleWhite = forsaleWhite;
export const SvgMobile = mobile;
export const SvgEmail = email;
export const SvgCloseIcon = closeIcon;
export const SvgTagIcon = tagIcon;
export const SvgTrophyIcon = trophyIcon;
export const Svghomeicon = homeicon;
export const SvgUserILineIcon = userILineIcon;
export const SvgUserHammerIcon = userHammerIcon;
export const SvgfaqIcon = faqIcon;
export const SvgVehicleBody = vehicleBody
export const SvgBrandIcon = brandIcon
export const SvgCountryicon = countryicon
export const SvgDamageicon = damageicon
export const SvgDriving_rangeicon = driving_rangeicon
export const SvgEngine_powericon = engine_powericon
export const SvgFirst_useicon = first_useicon
export const SvgFuelicon = fuelicon
export const SvgMax_speedicon = max_speedicon
export const SvgModelicon = modelicon
export const SvgNo_seatsicon = no_seatsicon
export const SvgPreview_owenericon = preview_owenericon
export const SvgPrice_1icon = price_1icon
export const SvgmapIconTarget = mapIconTarget
export const Svgtransmissionicon = transmissionicon
export const SvgcheckIcon = checkIcon
export const SvgelectricIcon = electricIcon
export const Svgcoloricon = coloricon
export const SvgchevronRight = chevronRight
export const SvgcheckICon = checkICon
export const SvgmapTarget = mapTarget
export const SvgfacebookIcon = facebookIcon
export const SvginstaIcon = instaIcon
export const SvgsnapIcon = snapIcon
export const SvgticktokIcon = ticktokIcon
export const SvgtwitterIcon = twitterIcon
export const SvgchevronIcon = chevronIcon
export const SvgHomeIcon = HomeIcon
export const SvgEditProfile = EditProfile
export const SvgCompanyIcon = CompanyIcon
export const SvgCarSettings = CarSettings
export const SvgverifiedBrand = verifiedBrand
export const SvgPrivacy = Privacy
export const SvgPrivacyIcon = PrivacyIcon
export const SvgfluentIcon = fluentIcon
export const SvgLocationIcon = LocationIcon
export const SvgMailIcon = MailIcon
export const SvgCallIcon = CallIcon
export const SvgAddCompany = AddCompany
export const SvgHomeCharging = home_charging;
export const SvgSeller = seller;
export const SvgPubliCharging = public_charging;
export const SvgdeleteAccount = deleteAccount;
export const SvgCarbonSettings = CarbonSettings;
export const SvgsignOut = signOut;
export const SvgMobileLeftArrow = MobileLeftArrow;
export const SvgMobileSort = MobileSort;
export const SvgEpMenu = EpMenu;
export const SvgFillerIcon = FillerIcon;
export const SvgmemSearch = memSearch;
export const SvgMyAds = MyAds
export const SvgActionPlaces = ActionPlace
export const SvgDashboard = Dashboard
export const SvgHotdeals = Hotdeals
export const SvgCoins = Coins
export const SvgRefundedCoins = RefundedCoins
export const SvgSecurityIcon = SecurityIcon
export const SvgAds = Ads
export const SvgFaq = Faq
export const Svgcameraicon = cameraicon;
export const SvgLegal = Legal
export const SvgLanguageIcon = LanguageIcon
export const SvgDeleteIcon = DeleteIcon
export const SvgDutyDateEdit = DutyDateEdit
export const SvgCardEditIcon = CardEditIcon
export const SvgbodyIcon = bodyIcon
export const SvgBraketypeIcon = BraketypeIcon
export const SvgBrandEngineIcon = BrandEngineIcon
export const SvgCapacityIcon = CapacityIcon
export const SvgConditionMaintananceIconIcon = ConditionMaintananceIconIcon
export const SvgDrivetypeIcon = DrivetypeIcon
export const SvgEnginePositionIcon = EnginePositionIcon
export const SvgSexIcon = SexIcon
export const SvgFrameIcon = FrameIcon
export const SvgFrameSizeIcon = FrameSizeIcon
export const SvgGrommetIconsHostMaintenance = GrommetIconsHostMaintenance
export const SvgHystoryMaintenance = HystoryMaintenance
export const SvgAirbagsIcon = AirbagsIcon
export const SvgBatteryIcon = BatteryIcon
export const SvgDoorIcon = DoorIcon
export const SvgSeatsIcon = SeatsIcon
export const SvgPackaheSizeIcon = PackaheSizeIcon
export const SvgPositionIcon = PositionIcon
export const SvgRegisterTionIcon = RegisterTionIcon
export const SvgMaterialIcon = MaterialIcon
export const SvgSuspension = Suspension
export const SvgWeigthIcon = WeigthIcon
export const SvgEquipmentIconIcon = EquipmentIconIcon
export const SvgCararcticonsPixelwheels = CararcticonsPixelwheels
export const SvgDescriptionIcon = DescriptionIcon
export const SvgUpholstery = Upholstery
export const SvgCarbody = Carbody
export const SvgtireIconBike = tireIconBike
export const SvgCheckRound = checkRound;
export const SvgCameraBlue = CameraBlue;
export const SvgTechnician = technician;
export const SvgSpark = spark;
export const SvgNogel = nogel;
export const SvgArrowSqure = arrowSqure;
export const SvgLoadingTime = loadingTime;
export const SvgKeyHand = keyHand;
export const SvgEarth = earth;
export const SvgTabFilter = TabFilter
export const Svgvehilcecar = vehilcecar
export const SvgPending = pending;
export const SvgIdentity = identity;
export const SvgSelfie = selfie;
export const SvgSelfieUser = selfieUser;
export const SvgVerified = verified;
export const SvgNotIcon = NotIcon
export const Svgnewusedicon = newusedicon
export const SvgsideBarToggler = sideBarToggler
export const SvginnerToggler = innerToggler
export const SvgtowerFilter = towerFilter
export const SvgBikesBody = bikesBody
export const SvgBikeFrame = bikeFrame
export const SvgSell = Sell
export const SvgDelete = Delete
export const SvgEdit = Edit
export const SvgOrders = Orders
export const SvgCancel = Cancel
export const SvgSold = Sold
export const SvgSwap = Swap
export const SvgEditPrice = EditPrice
export const SvgCheckDark = Checkdark


export const SvgService = service
export const SvgAboutUs = AboutUs
export const SvgContact = contact
export const SvgStockBox = stockBox
export const SvgCloseRound = closeRound
export const SvgFilterMobile = filterMobile

export const SvgAboutUsWhite = AboutUsWhite
export const SvgServiceWhite = serviceWhite
export const SvgContactWhite = contactWhite
export const SvgStockBoxWhite = stockBoxWhite
export const SvgMessage = Message
export const SvgUserIcon2 = svgUserIcon
export const SvgWhiteHome = svgWhiteHome
export const SvgFoldingBike = foldingBike
export const SvgBikeBody = bikeBody
export const SvgStepBody = stepBody
export const SvgUploadIcon = uploadIcon
export const SvgStaroutline = staroutline
export const SvgBikeType = bikeType
export const SvgStepType = stepType
export const SvgCarType = carType
export const SvgMotorCycleType = motorCycleType

export const SvgfacebookIconDark = facebookIconDark
export const SvgInstagramIconDark = instaIconDark
export const SvgSnapIcondark = snapchatIconDark
export const SvgticktokIconDark = tiktokIconDark
export const SvgtwitterIconDark = twitterIconDark
export const SvgYoutubeIconDark = youtubeDark
