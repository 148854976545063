export const ApiPaths = {
  common: {
    countries: '/countries',
    stripeConnect: '/stripe-connect',
  },
  auth: {
    login: '/login',
    logout: '/logout',
    register: '/register',
    activateAccount: '/email/verify',
    sendPasswordResetEmail: '/password/email',
    resetPassword: '/password/reset',
    getUser: '/user',
    getUsers: '/users',
    resendMail: '/email/resend',
    verifiedIdentity: '/verified_identity',
    verifyids: '/verify_ids',
    languages: '/languages',
    changeLanguage: '/change_language',
    addDeviceToke: '/add_device_token',
    removeDeviceToke: '/remove_device_token'
  },
  user: {
    saveProfile: '/user',
    saveAddress: '/addresses',
    changePassword: '/user/password/change',
    changeEmail: '/user/notify_change_email',
    changeEmailActivateAccount: 'user/change_email',
    saveImage: '/users/profile_picture',
    permission: '/permission',
    deleteAccount: '/delete_account',
    creditPlans: '/plans/credit_plans',
    promoCodes: '/promo_codes',
    credits: '/credits',
    wallet_balance: '/credits/wallet_balance',
    getTotals: '/credits/get_totals',
    update_session: '/payments/update_session',
    create_session: '/payments/create_session',
    verifyResetPasswordToken: '/check/reset_password/token',
    subscriptionNew: '/subscriptions/new'
  },
  company: {
    contacts: '/contacts',
    checkVat: '/vat/check_valid',
    checkSlug: '/subsidiaries/available_data',
    createParent: '/parents',
    subsidiary: '/subsidiaries',
    addSubsidiaryAddress: '/addresses',
    addSubsidiaryLogo: '/subsidiaries/logo',
    getPermissions: '/permissions',
    roles: '/roles',
    subsidiaryRoles: '/subsidiaries_roles',
    userSubsidiaries: '/user/subsidiaries',
    subscriptionPlans: '/plans',
    getSubscriptionStatus: '/get_subscription_status',
    features: '/features',
    users: '/users',
    suggestedMembers: '/users_no_member',
    departments: '/departments',
    department: '/department',
    schedules: '/schedules',
    contactFields: '/contact_fields',
    paymentMethods: '/payment_methods',
    stripeCustomers: '/stripe_customers',
    create: '/create',
    checkoutTotal: '/get_totals',
    departmentSubsidiaries: '/department_subsidiaries',
    departmentsubsidiary: 'department_subsidiary',
    availability: '/availability',
    invoice: '/invoices',
    subscriptions: '/subscriptions',
    logoChange: '/subsidiaries',
    verifyInvitation: '/verify_invitation',
    role: '/role',
    resume: '/resume',
    priceChange: '/preview_price_change',
    subscriptionSchedules: '/subscription_schedules',
    notifications: '/notifications',
    readNotification: '/read',
    readNotifications: '/mark_all_read',
    respondNotifications: '/response',
    lastOpened: '/last_opened_at',
    count: '/count',
    setupIntent: '/setup_intent',
    storeNew: 'storeNew',
    euroStandards: 'euro_standards',
    stripeConnect: 'subsidiaries/stripe_connect'
  },
  showcase: {
    showcaseSetting: '/showcase_setting',
    showcaseItem: '/items',
    services: '/services',
  },
  public: {
    adVehicles: '/ad_vehicles',
    home: '/home',
    shop: '/shop',
    silder: '/sliders',
    silderNew: '/sliders_new',
    verifyNomadPromo: '/verify_nomad_promo_code',
    sendPromo: '/send_nomad_promo_code_email',
    languages: '/languages',
    legalDocuments: '/legal_documents',
    faq: '/faqs',
    partner_options: '/partner_options',
    parnters: '/partners',
    filters_change: "filters_change",
    filters: "filters",
    contactUs: "contact_us",
    manage_natification: "/manage_follower_notification",
    hotdeals: "/hotdeals",
    topRatedDealers: "/subsidiaries/top_rated_dealers"
  },
  vehicles: {
    vehicles: '/vehicles',
    brands: '/brands',
    years: '/years',
    types: '/types',
    brand_cars: '/brand_cars',
    model_cars: '/model_cars',
    versions: '/versions',
    version: '/version',
    model_list: '/model_list',
    power: '/power',
    fuels: '/fuels',
    gearboxes: '/gearboxes',
    vehicle_bodies: './vehicle_bodies',
    bodies: '/bodies',
    validate: '/validate',
    equipments: '/equipments',
    interiors: '/interiors',
    vehicleConditions: '/vehicle_conditions',
    tireTypes: '/tire_types',
    tires: '/tires',
    colors: '/colors',
    drivingWheels: '/driving_wheels',
    ownerVehicles: '/owner_vehicles',
    frameSizes: '/frame_sizes',
    addVehicles: '/ad_vehicles',
    putInAuction: '/ad_vehicles/my_ads',
    putInhotDeal: '/ad_vehicles/my_hotdeals',
    auctionPlace: '/auction_place',
    myAdds: '/my_ads',
    myHotDeals: '/my_hotdeals',
    sex: '/sex',
    battery_types: '/battery_types',
    suspensions: '/suspensions',
    brakes: '/brakes',
    engine_positions: '/engine_positions',
    drive_types: '/drive_types',
    engines: '/engines',
    battery_positions: '/battery_positions',
    materials: '/materials',
    auction: '/auctions',
    hotDeals: '/hotdeals',
    offers: '/offers',
    images: '/images',
    bidAcceptReject: '/bid_acceptance',
    sold: '/sold',
    confirmSold: '/sold_confirm',
    customers: '/customers',
    targetAudiences: '/target_audiences',
    cancelReservation: '/cancel_reservation',
    cancelReservationRequestToAdmin: '/cancel_reservation_request_to_admin',
    expertise: '/expertise',
    expertise_sections: '/expertise_sections',
    favourite: '/favorites',
    euroStandars: '/euro_standards',
    confirmVehicle: '/confirm_vehicle_purchased_approval',
    rejectVehicle: '/cancel_sold_vehicle',
    favorites: '/favorites',
    is_favorite: '/is_favorite',
    // target_audiences: '/target_audiences',
  },
  call: {
    call: '/ad_vehicles'
  },
  chat: {
    chats: '/chats',
    users: '/users',
    block: '/block',
    unblock: '/unblock',
    archive: '/archive',
    all: '/all',
    mute: '/mute',
    read: '/read',
    messages: '/messages',
    subsidiaries: '/subsidiaries',
    markReceptionist: "/markReceptionist",
    members: "/members",
    addMember: "/add_member",
    save_setting: "/save_setting",
    blockedBy: "/blocked_by"
  },
  licensePlate: {
    license_plates: "/license_plates"
  },
  publicProfile: {
    reviewsRatings: '/ratings',
    addReviews: '/ratings',
    flagReviews: '/flag',
    updateReviews: '/ratings',
    vehicleHistory: '/owner_vehicles/history',
    profile: '/profile',
    follow: '/follow',
    followers: '/followers/1',
    following: '/followers/2'
  }
};
