import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { DetailsWrapper } from './Details'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import VehiclePrice from './vehicleViewComponent/VehiclePrice'
import VehicleColor from './vehicleViewComponent/VehicleColor'
import VehicleDescription from './vehicleViewComponent/VehicleDescription'
import VehicleIdentification from './vehicleViewComponent/VehicleIdentification'
import { useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import ConditionMaintenance from './ConditionMaintenance'
import { productTypes } from 'config/variables'

const VehicleView = (props: any) => {
    const { item } = props
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <DetailsWrapper>
            <Box className='detail-grid' sx={{
                display: isSmallDevice || isMediumDevice ? 'block !important' : ''
            }}>
                <Box className='price-color-grid' >
                    <Box className='price-grid'
                        sx={{
                            display: isSmallDevice ? "block !important" : ''
                        }}
                    >
                        {/* =============== Price Section ==================== */}
                        <VehiclePrice item={item} />

                        {/*====================== Color Section ================*/}

                        {item?.owner_vehicle?.product_type_id === productTypes.car ?
                            <VehicleColor outside_color={item.owner_vehicle.outside_color} inside_color={item.owner_vehicle.inside_color} interior={item.owner_vehicle.interior} />
                            :
                            <ConditionMaintenance item={item} />
                        }
                    </Box>

                    {/* ====================== Description ========================== */}
                    <Box className='description-grid'
                        sx={{ display: isSmallDevice || isMediumDevice ? 'none' : '' }}  >
                        <VehicleDescription item={item} />
                    </Box>
                </Box>

                {/* ==========================IdentificatioSn section ======================= */}
                <VehicleIdentification item={item} />

                {/* Decription on mobile */}
                <Box className='mobile-description-grid' sx={{
                    display: isSmallDevice || isMediumDevice ? 'block' : 'none'
                }}>
                    <VehicleDescription item={item} />
                </Box>
            </Box>

        </DetailsWrapper>
    )
}

export default VehicleView