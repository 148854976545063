import { lan } from 'locales/multiPageTranslation/adsLan'
import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailListing, DetailsTitle } from '../../../AdsStyledSlice'
import { productTypes } from 'config/variables'
import { translations } from 'locales/translations'
import { DetailsWrapper } from './Details'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { colorNew } from 'styles/StyleConstants'
import { SvgLinkIcon } from 'app/components/svgicons/svgNew'

const ConditionMaintenance = ({ item }) => {
    const { t } = useTranslation()
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <>
            <Box sx={{ flex: 1 }} >
                {item?.owner_vehicle?.product_type_id === productTypes.car ?
                    <DetailsWrapper>
                        <Grid container spacing={2}>
                            <Grid item xl={4} sm={6} xs={12}>
                                <DetailsTitle sx={{ ml: '10px' }}>{t(translations.ads.CONDITION_MAINTENANCE)} </DetailsTitle>
                                <Box className={`${!isSmallDevice && 'grid_color_odd'}`}
                                    sx={{
                                        color: colorNew.colorGrayOnButton,
                                        fontSize: "18px",
                                        fontWeight: 400,
                                        'span': {
                                            whiteSpace: 'nowrap'
                                        },
                                    }}
                                >
                                    <>
                                        <DetailListing className='vehicle-detail-label' >
                                            <label>{t(...lan.controlTecniclaUntil())}</label>
                                            <span>
                                                {item?.owner_vehicle?.vehicle_control_tech_month ? ('0' + item?.owner_vehicle?.vehicle_control_tech_month).slice(-2) + ' / ' : "-"}
                                                {item?.owner_vehicle?.vehicle_control_tech_year ? item?.owner_vehicle?.vehicle_control_tech_year : "-"}
                                            </span>
                                        </DetailListing>
                                        <DetailListing
                                            className='vehicle-detail-label'
                                        >
                                            <label>{t(...lan.lastControlTechnicalAt())}</label>
                                            <span>
                                                {item?.owner_vehicle?.vehicle_last_control_tech_month ? ('0' + item?.owner_vehicle?.vehicle_last_control_tech_month).slice(-2) + ' / ' : '-'}
                                                {item?.owner_vehicle?.vehicle_last_control_tech_year ? item?.owner_vehicle?.vehicle_last_control_tech_year : '-'}
                                            </span>
                                        </DetailListing>
                                        <DetailListing
                                            className='vehicle-detail-label'
                                        >
                                            <label>{t(...lan.replaceBeltDistribution())}</label>
                                            <span>
                                                {item?.owner_vehicle?.vehicle_last_timing_belt_month ? ('0' + item?.owner_vehicle?.vehicle_last_timing_belt_month).slice(-2) + ' / ' : '-'}
                                                {item?.owner_vehicle?.vehicle_last_timing_belt_year ? item?.owner_vehicle?.vehicle_last_timing_belt_year : '-'}
                                            </span>
                                        </DetailListing>
                                    </>
                                    <DetailListing
                                        className='vehicle-detail-label'>
                                        <label>{t(...lan.numberOfOwners())}</label>
                                        <span>{item?.owner_vehicle?.owner_number || "-"}</span>
                                    </DetailListing>
                                    <DetailListing
                                        className='vehicle-detail-label'>
                                        <label>{t(translations.addVehicleScreen.LINK_OF_HISTORY)}</label>
                                        {
                                            item?.owner_vehicle?.km_history_link ?
                                                <a href={item?.owner_vehicle?.km_history_link} target='_blank' rel='noreferrer noopener'>
                                                    <SvgLinkIcon />
                                                </a> : null
                                        }
                                    </DetailListing>
                                    {item?.owner_vehicle?.product_type_id === productTypes.car &&
                                        <DetailListing
                                            className='vehicle-detail-label'>
                                            <label>{t(translations.summaryVehicleScreen.BELT_DISTRIBUTION)}</label>
                                            <span>{item?.owner_vehicle?.belt_distribution ? t(translations.ads.YES) : t(translations.ads.NO)}</span>
                                        </DetailListing>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </DetailsWrapper > :
                    <Box sx={{
                        flex: 1,
                        mb: 2
                    }}>
                        <DetailsTitle sx={{ ml: '10px' }}>{t(translations.summaryVehicleScreen.MAINTENANCE)}</DetailsTitle>
                        <Box className={`${!isSmallDevice && 'grid_color_odd'}`}
                            sx={{
                                color: colorNew.colorGrayOnButton,
                                fontSize: "18px",
                                fontWeight: 400,
                                'span': {
                                    whiteSpace: 'nowrap'
                                },
                                flex: 1
                            }} >

                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(...lan.historyMaintenance())}</label>
                                <span>{item?.owner_vehicle?.maintenance_history ? t(...lan.yes()) : t(...lan.no())}  </span>
                            </DetailListing>
                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(...lan.lastMaintenance())}</label>
                                <span>{item?.owner_vehicle?.last_maintenance_km ? item?.owner_vehicle?.last_maintenance_km + ' km' : "-"}</span>
                            </DetailListing>
                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(translations.FILTER_COMPONENT.PREVIOUS_OWNERS)}</label>
                                <span>{item?.owner_vehicle?.owner_number || "-"}</span>
                            </DetailListing>
                        </Box>
                    </Box>
                }

            </Box>
        </>
    )
}

export default ConditionMaintenance