import { useEffect } from 'react'
import { SvgLiked, SvgUnliked } from '../svgicons/svg'
import { Box } from '@mui/material'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import { addAdInFavouriteRequest, addAdInFavouriteSuccess, removeAdFromFavouriteRequest, removeAdFromFavouriteSuccess, updateFavoriteList } from 'store/actions/favorite-actions'

const FavoriteButton = ({ adId }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()

    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);
    const { favoriteList, likeDislikeProcessing, addAddFavouriteStatus, removeAddFavouriteStatus } = useSelector((state: RootStateOrAny) => state.favorites);

    useEffect(() => {
        if (addAddFavouriteStatus && Object.keys(addAddFavouriteStatus).length > 0) {
            if (adId == addAddFavouriteStatus.item.model_id) {
                const cloneList = { ...favoriteList }
                cloneList[adId] = true
                dispatch(updateFavoriteList(cloneList))
                dispatch(addAdInFavouriteSuccess({}))
            }
        }
    }, [addAddFavouriteStatus])

    useEffect(() => {
        if (removeAddFavouriteStatus && Object.keys(removeAddFavouriteStatus).length > 0) {
            if (adId == removeAddFavouriteStatus.id) {
                const cloneList = { ...favoriteList }
                cloneList[adId] = false
                dispatch(updateFavoriteList(cloneList))
                dispatch(removeAdFromFavouriteSuccess({}))

            }
        }
    }, [removeAddFavouriteStatus])


    const handlefavourite = () => {
        // returning if already in process
        if (likeDislikeProcessing) {
            return;
        }

        if (!loggedIn) {
            history.push(
                `${routes.authRoutes.login}?redirectTo=${encodeURIComponent(
                    location.pathname + location.search
                )}`
            );
            return;
        }

        if (favoriteList && favoriteList[adId]) {
            dispatch(removeAdFromFavouriteRequest({ id: adId }));
            return;
        }
        dispatch(addAdInFavouriteRequest({ id: adId }));
    };

    return (
        <Box
            sx={{ position: 'absolute' }}
            className="contentBox likeIcon"
            onClick={() => handlefavourite()}
        >
            {favoriteList && favoriteList[adId] ? <SvgLiked /> : <SvgUnliked />}{' '}
        </Box>
    )
}

export default FavoriteButton