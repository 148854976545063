import { useEffect, useState } from 'react'
import { SvgChevronLeft, SvgClose } from 'app/components/svgicons/svg'
import { useWindowSize } from 'app/Hooks/windowResize'
import moment from 'moment'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getInvoiceDetailFailure } from 'store/actions/company-actions'
import PropTypes from 'prop-types';
import { commissionStatus, CountriesDrop1, getSubsidiaryStored, numberFormat } from 'config/variables'
import download from 'downloadjs'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Toaster } from 'services/Toaster'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader'
import { routes } from 'routes/routes'
import { Box, Tooltip } from '@mui/material'
import { InvoiceWrapper } from './style/InvoiceWrapper'
import { SvgMobileLeftArrow, SvgpdfIcon } from 'app/components/svgicons/svgNew'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import { translations } from 'locales/translations'
import Api from 'services/axios'
import UserLayout from 'app/Component2/Layouts/UserLayout/UserLayout'
import { loadStripe } from '@stripe/stripe-js'
import { PERMISSIONS, hasPermissions } from 'config/permission'
import PermissionComponent from 'app/Component2/GlobalComponent/PermissionComponent'
import { lan } from '../lan'


const VehicleCommissionInvoiceDetail = (props: any) => {

    //  local states
    const [billDetails, setBillDetails] = useState<any>();
    const [streetNumber, setStreetNumber] = useState<string>();
    const [route, setRoute] = useState<string>();
    const [locality, setLocality] = useState<string>();
    const [postalCode, setPostalCode] = useState<string>();
    const [vatNumber, setVatNumber] = useState<string>();
    const [country, setCountry] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false)
    const [invoiceData, setInvoiceData] = useState<any>()

    // redux states
    const { userData } = useSelector((state: RootStateOrAny) => state.user)
    const { subsidiaryDetails, invoiceDetailError } = useSelector((state: any) => state.company)
    const { hideDetailInvoice } = props

    // hooks
    const [mobile] = useWindowSize();
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch();

    // params from url
    const { invoice_id: invoiceId } = useParams();

    // 
    const subId = getSubsidiaryStored();
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)
    const permissionList = useSelector((state: any) => state.user.permissionData)

    // check weather user have permissions to view this invoice or not
    const isAccessible = subId ? hasPermissions(permissionList, PERMISSIONS.FEATURES.INVOICE_SUBSCRIPTION, PERMISSIONS.ACTIONS.READ) : true;


    // function to get user addresses
    const getAddress = (subsidiaryId) => {
        if (subsidiaryId) {
            return subsidiaryDetails.limited_address
        }
        return userData.limited_address
    }

    useEffect(() => {
        if (invoiceId) {
            setLoading(true)
            Api.get(`${routes.companyRoutes.vehicleSalesInvoices}/${invoiceId}`).then((response) => {
                setInvoiceData(response.data.data)
                setLoading(false)
            }).catch(function (error) {
                setLoading(false);
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    if (resp.data.data.message) {
                        error_message = resp.data.data.message
                    } else {
                        {
                            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                        }
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                Toaster.error(error_message);
            });
        }
    }, [invoiceId])

    useEffect(() => {
        if (invoiceData && Object.keys(invoiceData).length > 0) {
            setBillDetails(invoiceData.invoice)
        }

    }, [invoiceData])



    useEffect(() => {
        if (invoiceDetailError && Object.keys(invoiceDetailError).length > 0) {
            Toaster.error(invoiceDetailError?.data?.message)
            dispatch(getInvoiceDetailFailure({}))
        }
    }, [])

    useEffect(() => {
        // set address details for loggedIn user
        const address = getAddress(subId)
        setVatNumber(subsidiaryDetails.vat_number || '0');
        if (address && Object.keys(address).length > 0) {
            setStreetNumber(address.street_number || '');
            setRoute(address.route || '');
            setLocality(address.locality || '');
            setPostalCode(address.postal_code || '');
            getCountry(address.country_id)
        }
    }, [subId, userData.id])

    const getCountry = (id) => {
        CountriesDrop1().then(data => {
            data.map((country, i) => {
                if (country.id == id) {
                    setCountry(country.fullname.toLowerCase())
                }
            });
        })
    }

    // function  for downloading pdf
    const downloadPdf = (id) => {
        let download_url = `${routes.companyRoutes.vehicleSalesInvoices}/${id}/download`;
        // API request for download invoice in pdf format
        Api.get(download_url, { responseType: 'blob' })
            .then((response) => {
                const content = response.headers['content-type'];
                download(response.data, `${billDetails ? billDetails.invoice_no : 'invoice'}.pdf`, content)
            })
    }


    return (
        <UserLayout
            title={t(translations.invoiceScreen.INVOICES)}
        >
            {isAccessible ?
                <>
                    {loading && <ContentLoader />}
                    {billDetails ?
                        <InvoiceWrapper className="bill-props-wrapper">
                            <div className="bill-header-section">
                                <div className="bill_banner">
                                    <SvgMobileLeftArrow onClick={() => history.goBack()} className='pointer' />
                                    <div className="bill-left-icon d-inline pointer" onClick={() => hideDetailInvoice()}>
                                        {mobile ?
                                            <SvgChevronLeft />
                                            :
                                            <SvgClose />
                                        }
                                    </div>


                                    <Tooltip disableFocusListener title={t(translations.invoiceScreen.DOWNLOAD_INVOICE)} placement="left">
                                        <ButtonCustom textBtn={t(translations.invoiceScreen.DOWNLOAD_INVOICE)} icon={<SvgpdfIcon />} clickFunction={() => downloadPdf(invoiceId)} style={{
                                            'span': {
                                                'svg': {
                                                    width: 25,
                                                    height: 25
                                                }
                                            },
                                        }} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Box className="invoice-detail-body">
                                <Box className='action-box'>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            fontSize: "20px",
                                            pb: "12px"
                                        }}
                                    >
                                        <span>{billDetails.paid_at ? `${t(translations.invoiceScreen.DATE)}: ${moment(billDetails.paid_at).local().format("DD/MM/YYYY")}` : ''}</span>
                                        <span >{billDetails.invoice_no}</span>
                                    </Box>
                                    <Box className="invoice-pay-btn bg-white d-flex justify-content-between p-3">
                                        {billDetails.status == commissionStatus.commissionPaid ? <ButtonCustom textBtn={t(...lan.paid())} btnColor="green" disable={true} />
                                            :
                                            <ButtonCustom textBtn={t(translations.companyScreen.PENDING)} btnColor="orange_white" />}
                                        <Box className='bill-amount-box'>
                                            <Box className='text-center bill-amount-amount'>
                                                <h3>{t(translations.webTexts.AMMOUNT_DUE)}</h3>
                                                <p>€{billDetails.status == commissionStatus.commissionPaid ? numberFormat((0).toFixed(2)) : numberFormat(((billDetails.subtotal || 0) / 100).toFixed(2))}</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="address-vat d-flex justify-content-between pb-3 px-2">
                                    <h2>{billDetails?.seller_details?.name}</h2>
                                    <Box className="invoice-address">
                                        <Box>
                                            {billDetails?.seller_details?.address?.line1 && (
                                                <>
                                                    {billDetails.seller_details.address.line1}<br />
                                                </>
                                            )}
                                            {billDetails?.seller_details?.address?.line2 && (
                                                <>
                                                    {billDetails.seller_details.address.line2}<br />
                                                </>
                                            )}
                                            {billDetails?.seller_details?.address?.city && (
                                                <>
                                                    {billDetails.seller_details.address.city}<br />
                                                </>
                                            )}
                                            {(billDetails?.seller_details?.address?.state && billDetails?.seller_details?.address?.postal_code) && (
                                                <>
                                                    {billDetails.seller_details.address.state} {billDetails.seller_details.address.postal_code}
                                                </>
                                            )}
                                            <Box>{billDetails?.seller_details?.address?.country && (
                                                <>
                                                    {billDetails.seller_details.address.country}<br />
                                                </>
                                            )} </Box>
                                        </Box>
                                        {subId && <Box>
                                            {t(translations.ads.VAT)}: {billDetails?.vat_number ?? ''}
                                        </Box>}
                                    </Box>
                                </Box>

                                <div className={`principal-total-wrapper py-2 ${mobile && 'px-2'}`}>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{t(translations.invoiceScreen.VEHICLE_RESERVATION_SERVICE_AMOUNT)} </label>
                                        <span>€ {numberFormat(((billDetails.payment_session.amount_paid || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{t(translations.invoiceScreen.CTB_COMMISSION)}</label>
                                        <span>€ {numberFormat(((billDetails.commission_amount || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{`${t(translations.ads.VAT)}  (${billDetails.vat} %)`}</label>
                                        <span>€ {numberFormat(((billDetails.payment_session.commission_tax_amount || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <label>{t(translations.invoiceScreen.INVOICETOTAL)}</label>
                                        <span>€ {numberFormat(((billDetails.subtotal || 0) / 100).toFixed(2))}</span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <label>{t(translations.invoiceScreen.PAID)}</label>
                                        <span> € {numberFormat(((billDetails.subtotal || 0) / 100).toFixed(2))}</span>
                                    </div>
                                </div>
                                <div className="grand-total py-3">
                                    <div className={`d-flex justify-content-between ammount_box ${mobile && 'px-2'}`}>
                                        <label>{t(translations.invoiceScreen.AMOUNTDUE)} </label>
                                        <span className={`${billDetails.status == 2 ? 'text-success' : 'text-error'}`} >€
                                            {billDetails.status == commissionStatus.commissionPaid ? numberFormat((0).toFixed(2)) : numberFormat(((billDetails.subtotal || 0) / 100).toFixed(2))}
                                        </span>
                                    </div>
                                </div>
                            </Box>
                        </InvoiceWrapper>
                        : null
                    }
                </>
                :
                <PermissionComponent innerCom={true} />
            }
        </UserLayout >)
}

export default VehicleCommissionInvoiceDetail;