import { createAction } from 'redux-actions';

export const updateFavoriteList = createAction('UPDATE_FAVORITE_LIST');

export const addAdInFavouriteRequest = createAction('ADD_AD_IN_FAVOURITE_REQUEST');
export const addAdInFavouriteSuccess = createAction('ADD_AD_IN_FAVOURITE_SUCCESS');
export const addAdInFavouriteFailure = createAction('ADD_AD_IN_FAVOURITE_FAILURE');

export const removeAdFromFavouriteRequest = createAction('REMOVE_AD_FROM_FAVOURITE_REQUEST');
export const removeAdFromFavouriteSuccess = createAction('REMOVE_AD_FROM_FAVOURITE_SUCCESS');
export const removeAdFromFavouriteFailure = createAction('REMOVE_AD_FROM_FAVOURITE_FAILURE');
