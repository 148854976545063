import React, { useRef } from 'react'
import PublicLayout from 'app/Component2/Layouts/PublicLayout'
import { ContactUsWrappper } from './styles/contactUsStyle'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, InputAdornment } from '@mui/material'
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import SearchDropdown from 'app/components/CustomFields/searchDropdown'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { localstorageKey, userTypeId, fieldsArray } from 'config/constants';
import { EmailValidationRegex, PhoneValidationRegex, getMobileOperatingSystem, onlyNumberType, Strings, sendContactUsRequest, NumberRepetitionValidationRegex } from 'config/variables';
import { CountryDropDown } from 'app/Component2/GlobalComponent/CountryDropDown';
import { Toaster } from 'services/Toaster'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader'
import { RootStateOrAny, useSelector } from 'react-redux'

const ContactUs = () => {
    const defaultCountryPrefix = '+32'
    const defaultcountry = '2';
    const { t } = useTranslation();
    const history = useHistory();
    const [subjectArr, setSubjectArr] = useState<any>()
    const [selectedSubject, setSelectedSubject] = useState<any>(null);
    const [errors, setErrors] = useState<any>({});
    const [countryCode, _countryCode] = useState<any>(defaultCountryPrefix)
    const [countryId, _countryId] = useState<any>(defaultcountry);
    const [sendingMssgLoading, setSendingMssgLoading] = useState<boolean>(false);
    const { loadingForce } = useSelector((state: RootStateOrAny) => state.vehicle)
    const [contactUsErrors, setContactUsErrors] = useState<any>(null);

    // form states
    const [contactForm, setContactForm] = useState<any>({
        type: userTypeId.PRIVATE as string | number,
        first_name: "",
        last_name: "",
        company_name: "",
        email: "",
        phone: "",
        country_id: countryId,
        subject_id: "",
        message: "",
    });

    //getting subject data from locastorage based on user type selected.
    React.useEffect(() => {
        const data = localStorage.getItem(localstorageKey.contactSubjects)
        if (data) {
            const subjects = JSON.parse(data)
            const typeId = contactForm.type === userTypeId.PRIVATE ? subjects[userTypeId.PRIVATE] : subjects[userTypeId.DEALER]
            setSubjectArr(typeId);
            handleEmptyFields()
            setErrors({})
        }
    }, [contactForm.type, loadingForce]);

    React.useEffect(() => {
        if (contactUsErrors?.data && Object.keys(contactUsErrors?.data)) {
            Object.keys(contactUsErrors?.data).map((field) => {
                setErrors((err) => ({
                    ...err,
                    [field]: contactUsErrors?.data[field][0]
                }));
            })
        }
    }, [contactUsErrors])

    // removing error when field is validated
    const removeError = (key) => {
        const error = { ...errors };
        if (error[key]) {
            delete error[key];
            setErrors(error);
        }
    }

    const validateForm = () => {
        const newErrors = {};
        // Validate required fields
        fieldsArray.forEach((field) => {
            if (!contactForm[field]) {
                newErrors[field] = Strings.required;
            }
        });

        // Validate email format
        if (!EmailValidationRegex.email.test(contactForm.email) && contactForm.email) {
            newErrors['email'] = Strings.email;
        }

        // Validate phone format (example: allowing only digits)
        if (contactForm.phone && !(PhoneValidationRegex.phone.test(contactForm.phone))) {
            newErrors['phone'] = Strings.phone;
        }

        // Validate phone format (all digits cannot be same validation)
        if (contactForm.phone && NumberRepetitionValidationRegex.phone.test(contactForm.phone)) {
            newErrors['phone'] = Strings.phoneNumberRepetition;
        }

        // Validate company_name if type dealer is selected
        if (contactForm.type === userTypeId.DEALER && !contactForm.company_name) {
            newErrors['company_name'] = Strings.required;
        }

        // Set errors and return true if there are errors
        setErrors(newErrors);
        return Object.keys(newErrors).length > 0;
    };

    //handling selection of subject and setting id in contactForm state 
    const handleSubject = (id) => {
        if (id) {
            setSelectedSubject(id)
            setContactForm((prevForm) => ({
                ...prevForm,
                subject_id: id
            }))
            removeError('subject_id')
        }
    }

    //handling all fields length and required validations with translations as well
    const handleFieldValidation = (field, value, maxLength, fieldNameTranslationKey) => {
        if (value.length > maxLength) {
            setErrors((err) => ({
                ...err,
                [field]: t(translations.validationAndPopText.FIELD_LENGTH_VALIDATION, { fieldText: t(fieldNameTranslationKey), maxLength })
            }));

            // Handle pasting limitation
            if (value.length > maxLength) {
                const data = value.substring(0, maxLength);
                setContactForm((prevForm) => ({
                    ...prevForm,
                    [field]: data,
                }));
            }
            return true;
        } else if (value.length === 0) {
            setErrors((err) => ({
                ...err,
                [field]: Strings.required
            }));
        }
        return false;
    };

    const handleChange = (field, value) => {
        if (field === 'message' && value.length > 500) {
            const data = value.substring(0, 500);
            setContactForm((prevForm) => ({
                ...prevForm,
                [field]: data,
            }));
            return
        } else if (['first_name', 'last_name', 'company_name'].includes(field)) {
            const fieldNameTranslationKey = {
                company_name: translations.verificationScreen.COMPANYNAME,
                first_name: translations.profileScreen.FIRSTNAME,
                last_name: translations.profileScreen.LASTNAME
            }[field];
            if (handleFieldValidation(field, value, 50, fieldNameTranslationKey)) {
                return;
            }
        }

        removeError(field);
        setContactForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));
    };

    // selection of user type (you are) radio buttons
    const handleUserChange = (val) => {
        setContactForm((prevForm) => ({
            ...prevForm,
            type: val
        }))
    }

    //Empty the states after message sent successfully
    const handleEmptyFields = () => {
        setSelectedSubject(null)
        setContactForm((prev) => ({
            ...prev,
            first_name: "",
            last_name: "",
            company_name: "",
            email: "",
            phone: "",
            subject_id: "",
            message: "",
        }))
    }
    // handling Submitting message
    const handleSendMssg = () => {
        // if there is any errors it will return true
        if (validateForm()) {
            return;
        }

        //loading when api hits
        setSendingMssgLoading(true)

        // Create a new object without the 'company_name' key
        const { company_name, ...newContactForm } = contactForm;

        // company_name is removed when private is selected
        const payload = contactForm.type == userTypeId.PRIVATE ? newContactForm : contactForm

        //API call 
        sendContactUsRequest(payload)
            .then((res: any) => {
                setSendingMssgLoading(false)
                handleEmptyFields();
                Toaster.success(res.data.message)
            })
            .catch((err: any) => {
                setSendingMssgLoading(false)
                setContactUsErrors(err.response.data)
                Toaster.error(err.response.data.message)
            })
    };

    //selecting country
    const countryhandler = (data) => {
        _countryCode(data.code);
        _countryId(data.id);
        setContactForm((prev) => ({
            ...prev,
            country_id: data.id
        }))
    }

    return (
        <>
            {sendingMssgLoading && <ContentLoader />}
            <PublicLayout
                meta={`${t(translations.webTexts.CONTACT_US)} - CarToBike`}
                description={`${t(translations.webTexts.CONTACT_US_DESCRIPTION)} - CarToBike`}
                image={process.env.REACT_APP_BASE_URL + '/content.jpg'}
                type="website"
                showNavbar={true}
                hideHeader={false}
            >
                <ContactUsWrappper>
                    <Box className='contact-form'>
                        <Box sx={{ padding: { xs: '0px 0px 24px 0px', md: '16px 0px 32px 0px' } }}>
                            <Typography variant='h1' className='contact-us'>{t(translations.webTexts.CONTACT_US)}</Typography>
                            <Typography fontSize={'18px'} color={'gray'}>{t(translations.webTexts.CONTACT_US_DESCRIPTION)}</Typography>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <FormControl>
                                    <FormLabel className='radio-label' id="demo-row-radio-buttons-group-label">{t(translations.webTexts.YOU_ARE_CONTACT_US)}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={contactForm.type || userTypeId.PRIVATE}
                                        onChange={(e) => handleUserChange(e.target.value)}
                                    >
                                        <FormControlLabel className='filterCheckBox' value={userTypeId.PRIVATE} control={<Radio />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_PRIVATE)} />
                                        <FormControlLabel className='filterCheckBox' value={userTypeId.DEALER} control={<Radio />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_DEALER)} />
                                    </RadioGroup>
                                </FormControl>
                                <Grid container spacing={2.5}>
                                    <Grid item xs={12} lg={6} xl={6}>
                                        <FormControl fullWidth>
                                            <FormLabel className='input-label required'>{t(translations.profileScreen.FIRSTNAME)}</FormLabel>
                                            <TextField
                                                id="outlined-basic"
                                                name='first_name'
                                                variant="outlined"
                                                value={contactForm.first_name}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                            {errors.first_name && <Typography color={'red'}>{errors.first_name}</Typography>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={6} xl={6}>
                                        <FormControl fullWidth>
                                            <FormLabel className='input-label required'>{t(translations.profileScreen.LASTNAME)}</FormLabel>
                                            <TextField
                                                id="outlined-basic"
                                                name='last_name'
                                                variant="outlined"
                                                value={contactForm.last_name}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                        </FormControl>
                                        {errors.last_name && <Typography color={'red'}>{errors.last_name}</Typography>}
                                    </Grid>
                                </Grid>
                                {(contactForm.type == userTypeId.DEALER) && <FormControl>
                                    <FormLabel className='input-label required'>{t(translations.verificationScreen.COMPANYNAME)}<span className='red-asterisk'>*</span></FormLabel>
                                    <TextField
                                        id="outlined-basic"
                                        name='company_name'
                                        variant="outlined"
                                        value={contactForm.company_name}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                    {errors.company_name && <Typography color={'red'}>{errors.company_name}</Typography>}
                                </FormControl>}
                                <FormControl>
                                    <FormLabel className='input-label required'>{t(translations.formText.Email)}<span className='red-asterisk'>*</span></FormLabel>
                                    <TextField
                                        id="outlined-basic"
                                        name='email'
                                        variant="outlined"
                                        value={contactForm.email}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                    {errors.email && <Typography color={'red'}>{errors.email}</Typography>}
                                </FormControl>
                                <Box className="input-wrapper">
                                    <Box className='country-phone-input'>
                                        <CountryDropDown
                                            countryhandler={countryhandler}
                                            classes={`s-hide-fullname s-hide-name d-hide-fullname d-hide-name s-hide-code`}
                                            default={countryId}
                                        />
                                        <FormControl fullWidth>
                                            <FormLabel className='input-label required'>{t(translations.companySettingScreen.PHONE)}<span className='red-asterisk'>*</span></FormLabel>
                                            <TextField
                                                id="outlined-basic"
                                                name='phone'
                                                type={getMobileOperatingSystem() === 'iOS' ? 'tel' : 'number'}
                                                variant="outlined"
                                                value={contactForm.phone}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                onKeyDown={(evt) => onlyNumberType(evt)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
                                                }}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    {errors.phone && <Typography color={'red'}>{errors.phone}</Typography>}
                                </Box>
                                <FormControl fullWidth>
                                    <FormLabel className='input-label required'>{t(translations.webTexts.SUBJECT)}<span className='red-asterisk'>*</span></FormLabel>
                                    <SearchDropdown title={t(translations.webTexts.SUBJECT)} list={subjectArr ?? []} value={selectedSubject} handleChange={(id) => handleSubject(id)} hideSearch={true} />
                                    {errors.subject_id && <Typography color={'red'}>{errors.subject_id}</Typography>}
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel className='input-label required'>{t(translations.webTexts.HOW_CAN_I_HELP)}</FormLabel>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        name='message'
                                        value={contactForm.message}
                                        multiline
                                        minRows={5}
                                        placeholder={t(translations.webTexts.YOU_CAN_TYPE_MESSAGE)}
                                        variant="outlined"
                                        sx={{ minHeight: '150px' }}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment className='adorement' position={'start'}>{`${contactForm.message ? contactForm.message.length : '0'}/500`}</InputAdornment>,
                                        }}
                                    />
                                    {errors.message && <Typography color={'red'}>{errors.message}</Typography>}
                                </FormControl>
                            </Box>
                        </Box>
                        <Box className='send-btn'>
                            <Button onClick={() => handleSendMssg()}>{t(translations.webTexts.SEND_MESSAGE)}</Button>
                        </Box>
                    </Box>
                    <Box className="imageSide" />
                </ContactUsWrappper>
            </PublicLayout>
        </>
    )
}

export default ContactUs