import { handleActions } from 'redux-actions';
import { addAdInFavouriteFailure, addAdInFavouriteRequest, addAdInFavouriteSuccess, removeAdFromFavouriteFailure, removeAdFromFavouriteRequest, removeAdFromFavouriteSuccess, updateFavoriteList } from 'store/actions/favorite-actions';

const initialState = {
  favoriteList: {},
  likeDislikeProcessing: false,
  addAddFavouriteStatus: {},
  addAddFavouriteError: {},
  removeAddFavouriteError: {},
  removeAddFavouriteStatus: {},
}

const favorites = handleActions({

  [updateFavoriteList]: (state, { payload }) => (
    {
      ...state,
      favoriteList: {
        ...state.favoriteList,
        ...payload
      }
    }),
  [addAdInFavouriteRequest]: (state, { payload }) => ({
    ...state,
    likeDislikeProcessing: true,
    addAddFavouriteStatus: {},
    addAddFavouriteError: {},
  }),

  [addAdInFavouriteSuccess]: (state, { payload }) => ({
    ...state,
    addAddFavouriteStatus: payload,
    likeDislikeProcessing: false,
  }),

  [addAdInFavouriteFailure]: (state, { payload }) => ({
    ...state,
    addAddFavouriteError: payload?.data ?? {},
    addAddFavouriteStatus: {},
    likeDislikeProcessing: false,
  }),

  [removeAdFromFavouriteRequest]: (state, { payload }) => ({
    ...state,
    removeAddFavouriteStatus: {},
    removeAddFavouriteError: {},
    likeDislikeProcessing: true
  }),
  [removeAdFromFavouriteSuccess]: (state, { payload }) => ({
    ...state,
    removeAddFavouriteStatus: payload,
    removeAddFavouriteError: {},
    likeDislikeProcessing: false,
  }),

  [removeAdFromFavouriteFailure]: (state, { payload }) => ({
    ...state,
    removeAddFavouriteError: payload?.data ?? {},
    removeAddFavouriteStatus: {},
    likeDislikeProcessing: false,
  }),
}, initialState,)

export default favorites;