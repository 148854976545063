import styled from "styled-components";


export const CreditCountdownWrapper = styled.div`/* General container styling */
.coupon-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  max-width: 1440px; /* Match width with below section */
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 12px;
  border: 1px dashed #CBD5E1;
  overflow: hidden;
}

/* Coupon Content */
.coupon-content {
  padding: 40px 20px;
  background: linear-gradient(135deg, #ef8b44, #c12395);
  text-align: left;
  margin-bottom: 20px;
  margin-bottom: 0;
  padding-right: 120px;
}

.discount-title {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  margin-bottom:6px
}

.description {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.code-section{
  .description{
    color: black;
  }
}

.coupon-code {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.expires-in {
  font-family: Rubik;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.02em;
  color :#EF4444;
}

.timer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.time-block {
  background: #fff;
  color: #ef8b44;
  border-radius: 5px;
  padding: 10px 5px;
  min-width: 60px;
  text-align: center;
}

.time-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.time-label {
  font-size: 0.8rem;
  color: #ef8b44;
}

/* Copy Button */
.copy-button {
  background:linear-gradient(135deg, #ef8b44, #c12395);
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  margin: 0 22px 0 0 ;
  font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.78px;
  letter-spacing: 0.02em;
}

.copy-icon {
  font-size: 1.2rem;
}

.count {
  color: var(--text-deep-gray, #666);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  position: absolute;
  top: 14px;
  left: 18px;
}
.countText {
  color: var(--text-deep-gray, #666);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  margin-top: -5px;
}
.timer-section{
  >div{
    gap: 40px;
  }
}

@media (min-width: 993px) {
  .coupon-timer-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .timer {
    justify-content: flex-end;
  }
}

/* Mobile responsiveness */
@media (max-width: 992px) {
  .coupon-timer-container{
    margin-bottom: 0;
  }
  .copy-button {
    margin-top: 8px;
    margin-bottom: 20px;
    margin-right: 0;
    padding: 8px 16px;
  }
  .discount-title{
    margin-bottom: 0;
  }
  .coupon-content{
    width: 100%;
    padding: 10px 15px;
  }
  .expires-in {
    font-family: Rubik;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.02em;
  }
  .timer-section{
    margin-top: 10px;
    width: 100%;
    padding: 0 15px;
  }
  .code-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    padding: 0 15px;
    .description{
      font-weight : 500 ;
      font-size: 13px;
    }
  }
  .timer-section >div {
      gap: 0;
  }
}

`