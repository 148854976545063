import moment from "moment";
import { auctionEndAnimationTime, getCookie, getMobileOperatingSystem, userAgentType } from "./variables";
import { getPublicAdsListReset } from "store/actions/public-actions";
import i18n from 'i18next';
import { translations } from 'locales/translations';
import { localstorageKey, showAuctionsTo } from "./constants";
import { appConstant } from "./appConfig";
import { routes } from "routes/routes";
import { ApiPaths } from "services/ApiPaths";
import Api from "services/axios";
import { updateFavoriteList } from "store/actions/favorite-actions";
import { Toaster } from "services/Toaster";


//this is the function to get time after auction is ended , like how much before auction is ended
export const getAuctionEndingBefore = (endDate) => {
  if (endDate === '') return auctionEndAnimationTime.total;
  return moment.utc().diff(endDate);
}

// this function gets the time for "Auction is ending" animation message
export const getEndingAnimationTime = (auctionEndBefore) => {
  let t = auctionEndAnimationTime.endingTime - auctionEndBefore;
  return (t < 0) ? 0 : (t > auctionEndAnimationTime.endingTime) ? auctionEndAnimationTime.endingTime : t;
}

// this function gets the time for "Auction has ended" animation message
export const getEndedAnimationTime = (auctionEndBefore) => {
  let t = auctionEndBefore < auctionEndAnimationTime.endingTime ? auctionEndAnimationTime.endedTime : auctionEndAnimationTime.total - auctionEndBefore;
  return (t < 0) ? 0 : (t > auctionEndAnimationTime.endedTime) ? auctionEndAnimationTime.endedTime : t;
}

// remove ads from the auction list according to the id
export const removeAds = (setAds, id) => {
  setAds(prev => prev.filter(row => row.id !== id));
}

// reset public ads list on page change 
export const handleResetOnPageChange = (dispatch, currentPageVehicleType, nextPageVehicleType) => {
  if (currentPageVehicleType !== nextPageVehicleType) {
    dispatch(getPublicAdsListReset())
  }
}

// share link on social media 
export const shareLink = async (event: React.MouseEvent<HTMLButtonElement>, header, title, shareUrl, setAnchorEl) => {
  const agent = getMobileOperatingSystem()
  try {
    if ((agent != userAgentType.isDesktop) && navigator.share) {
      await navigator.share({
        title: header,
        text: title,
        url: shareUrl,
      });
    } else {
      setAnchorEl(event.currentTarget);
      console.log('Web share api is not supported on this browser.')
    }
  } catch (error) {
    console.log('Error: ' + error)
    setAnchorEl(event.currentTarget);
  }
};


export const createArray = (min, max) => {
  let resultArray: { id: number; name: string }[] = [];
  for (let value = min; value <= max; value++) {
    // if value is less than equal to 9 than add 0 to the value.(01, 02, 03 ...)
    let name = value <= 9 ? ('0' + value) : value + '';
    resultArray.push({
      id: value,
      name: name
    })
  }
  return resultArray

}


// display vat, +vat or tax included in inputfield of bidding section
export const handleDisplayVat = (adDetail) => {
  if (adDetail.display_for == showAuctionsTo.DEALER && adDetail.owner_vehicle.vehicle_deductible_vat) {
    return '+' + i18n.t(translations.ads.VAT_REFUNDABLE_TEXT)
  }
  if (adDetail.display_for == showAuctionsTo.DEALER && !adDetail.owner_vehicle.vehicle_deductible_vat) {
    return i18n.t(translations.ads.VAT_NON_REFUNDABLE_TEXT)
  }
  return i18n.t(translations.ads.VAT_TAX_INCLUD)
}

export const headingText = (documentType) => {
  if (documentType == appConstant.PASSPORT) {
    return i18n.t(translations.verificationScreen.PHOTO_PAGE)
  }
  if (documentType == appConstant.NATIONAL_ID_FRONT) {
    return i18n.t(translations.verificationScreen.FRONT_SIDE)
  }
  if (documentType == appConstant.NATIONAL_ID_BACK) {
    return i18n.t(translations.verificationScreen.REAR_SIDE)
  }
  return ''
}

export const descriptionText = (documentType) => {
  if (documentType == appConstant.PASSPORT) {
    return i18n.t(translations.verificationScreen.MAKE_SURE_DISPLAY_OF_ID)
  }
  if (documentType == appConstant.NATIONAL_ID_FRONT) {
    return i18n.t(translations.verificationScreen.PHOTO_SHOULD_BE_CLEAR)
  }
  if (documentType == appConstant.NATIONAL_ID_BACK) {
    return i18n.t(translations.verificationScreen.PHOTO_SHOULD_BE_CLEAR)

  }
  return ''
}

export const getSuggestedStartingPrices = (totalPrice) => {
  // Calculate starting prices

  const startingPrice1 = Math.floor(totalPrice * 0.3);  // 0.3 means 30% of total price
  const startingPrice2 = Math.floor(totalPrice * 0.4);
  const startingPrice3 = Math.floor(totalPrice * 0.5);
  const startingPrice4 = Math.floor(totalPrice * 0.6);

  // Apply the special conditions
  const applySpecialConditions = (price) => {
    // if(price < 100) return price
    const value = Math.ceil(price / 100) * 100;
    if (value >= totalPrice) return 0;
    return value;
  };

  // Apply special conditions to each starting price
  const finalStartingPrice1 = applySpecialConditions(startingPrice1);
  const finalStartingPrice2 = applySpecialConditions(startingPrice2);
  const finalStartingPrice3 = applySpecialConditions(startingPrice3);
  const finalStartingPrice4 = applySpecialConditions(startingPrice4);

  const priceArray = [...new Set([
    finalStartingPrice1,
    finalStartingPrice2,
    finalStartingPrice3,
    finalStartingPrice4
  ])].filter(price => price != 0)
  if (priceArray.length == 0) return [0]
  return priceArray.sort((price1, price2) => price1 - price2);

};


// channel name 
export const channelName = (subsidiaryId) => {
  if (subsidiaryId) {
    return 'ad_vehicles.dealer'
  }
  return 'ad_vehicles.private_user'
}

// pusher name 
export const pusherName = (subsidiaryId) => {
  if (subsidiaryId) {
    return 'AdVehicleBidChangeForDealer'
  }
  return 'AdVehicleBidChangeForPrivateUser'
}

const spacialCharactersReplacements = {
  '+': ' ',
  '%20': ' ',
  '%21': '!',
  '%22': '"',
  '%23': '#',
  '%24': '$',
  '%26': '&',
  '%27': "'",
  '%28': '(',
  '%29': ')',
  '%2A': '*',
  '%2B': '+',
  '%2C': ',',
  '%2D': '-',
  '%2E': '.',
  '%2F': '/',
  '%3A': ':',
  '%3B': ';',
  '%3C': '<',
  '%3D': '=',
  '%3E': '>',
  '%3F': '?',
  '%40': '@',
  '%5B': '[',
  '%5C': '\\',
  '%5D': ']',
  '%5E': '^',
  '%5F': '_',
  '%60': '`',
  '%7B': '{',
  '%7C': '|',
  '%7D': '}',
  '%7E': '~'
};

// this function will replace special character's code with special character
export const replaceSpaceAndAmpersand = (value) => {
  return decodeURIComponent(
    value.replace(/\+/g, ' ').replace(/%[A-F\d]{2}/gi, match => {
      return spacialCharactersReplacements[match.toUpperCase()] || match;
    })
  );
}


export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}


export const screen = {
  selfie: 0,
  capturedDocument: 1,
  chooseDocument: 2,
  verificationStatusScreen: 3
}
export const progressBarSteps = {
  one: 1,
  two: 2,
  three: 3,
  four: 4
}

export const roundToTwoDecimalPlaces = (value) => {
  return Number(Number.parseFloat(value).toFixed(2));
}

export const handleBuyCredits = (history, url) => {
  window.scrollTo(0, 0)
  localStorage.setItem(localstorageKey.buyCreditPage, url)
  history.push(routes.userRoutes.credits)
}


export const addSeconds = (date, seconds) => {
  return new Date(date.getTime() + seconds * 1000);
}


export const isValidQuery = (str) => {
  const regex = /^[^;/?:@&=+$,#%]*$/;
  return regex.test(str);
}

export const safeRedirect = (url) => {

  const timeout = 1000; // 1 second

  // Attempt to redirect to the app
  window.location.href = url;

  // If the app is not opened, the time difference will be small i.e 1 second
  // paltform.includes('Safari')

  // setTimeout(() => {
  //   if (document.hasFocus()) {
  //     // Fallback to another URL (e.g., App Store or Play Store)
  //     window.location.href = fallbackUrl;
  //   }
  // }, timeout);
}

// Fallback method using the execCommand('copy')
export const fallbackCopyText = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid showing the textarea
  textArea.style.position = "fixed";
  textArea.style.left = "-9999px";

  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    // alert("Text copied to clipboard (fallback)!");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

export const decodeHTMLEntities = (text) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(text, 'text/html').body.textContent;
  return decodedString;
}

export const calculateVATValue = (isVatIncluded, price, vatrate) => {
  const simplifiedPrice = parseFloat(price)
  const simplifiedVAT = parseInt(vatrate)
  if (isVatIncluded) {
    return (simplifiedPrice * simplifiedVAT) / (100 + simplifiedVAT);
  }
  return simplifiedPrice * simplifiedVAT / 100;
}

export const bidirectionalPriceConversion = (isVatIncluded, price, vatrate) => {
  const simplifiedPrice = parseFloat(price)
  const simplifiedVAT = parseInt(vatrate)
  if (isVatIncluded) {
    return (simplifiedPrice - ((simplifiedPrice * simplifiedVAT) / (100 + simplifiedVAT)))
  }
  return (simplifiedPrice * simplifiedVAT / 100 + simplifiedPrice)
}


export const getFavoritesList = (adsList, dispatch) => {
  if (!adsList || adsList.length === 0) return
  let queryString = `${ApiPaths.public.adVehicles}${ApiPaths.vehicles.is_favorite}?`

  adsList.forEach((ad, index) => {
    queryString += `ad_id[${index}]=${ad.id}&`
  });

  Api.get(queryString)
    .then((response) => {
      const resp = response?.data?.data?.values ?? {}
      dispatch(updateFavoriteList(resp))
      return
    }).catch((err) => {
    })
}

export const updateVisitCount = (vehicleId) => {
  if (!vehicleId) return
  const url = `${ApiPaths.public.adVehicles}/${vehicleId}/view?_method=PATCH`
  Api.post(url)
    .then((response) => {
      return
    }).catch((err) => {
      const errorMessage = err.response?.data ?? {}
      Toaster.error(errorMessage?.message)
    })
}
