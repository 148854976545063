import { useCountdown } from "app/Hooks/useCoundown";
import { useTranslation } from "react-i18next";
import { CreditCountdownWrapper } from "../style/CreditCountdownWrapper";
import { SvgCopyWhite } from "app/components/svgicons/svgNew";
import { translations } from "locales/translations";
import Timer from "app/components/AuctionTimer/timer";
import { Toaster } from "services/Toaster";

const CreditCouponCountdown = ({ coupon, expiryTime, percentageOff }) => {

    const { t } = useTranslation()

    const [days, hours, minutes, seconds] = useCountdown(expiryTime);

    const handleCopyCoupon = () => {
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(coupon)
            Toaster.success(t(translations.webTexts.COPIED))
        } else {
            document.execCommand('copy', true, coupon);
            Toaster.success(t(translations.webTexts.COPIED))
        }
    };

    if (!coupon) {
        return null
    }

    return (
        <CreditCountdownWrapper>
            <div className="coupon-timer-container">
                <div className="coupon-content">
                    <h2 className="discount-title">{t(translations.SERVICE_PACKAGES.PERCENTAGEOFF, { amount: `${percentageOff}%` })}</h2>
                    <p className="description">
                        {t(translations.SERVICE_PACKAGES.PERCENTAGEOFFDESC)}
                    </p>

                </div>
                <div className="timer-section">
                    <p className="expires-in">{t(translations.SERVICE_PACKAGES.EXPIRESIN)}</p>

                    <Timer days={days} hours={hours} minutes={minutes} seconds={seconds} />
                </div>
                <div className="code-section">
                    <p className="description">
                        🎉 {t(translations.SERVICE_PACKAGES.USECODE)}{" "}
                    </p>
                    <button className="copy-button" onClick={handleCopyCoupon}>
                        {coupon}
                        <SvgCopyWhite />
                    </button>
                </div>
            </div>
        </CreditCountdownWrapper>
    );
};

export default CreditCouponCountdown;
