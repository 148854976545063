
import React, { useState, useEffect, useRef } from 'react'
import { checkVatFailure, editSubsidiaryFailure, getAllCardsFailure, getSubsidiaryDetailsRequest, getSubsidiaryDetailsReset, getUserSubsidiariesRequest, privateSwitchRequest } from 'store/actions/company-actions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import placeholderImage from 'app/assets/Images/userplaceholder.png'
import companyPlaceholder from 'app/assets/Images/companyLogo.png'
import { CountriesDrop1, InvoiceTabs, Strings, editPageRoutes, getSubsidiaryStored, privateProfilePaths, subsidiaryProfilePaths } from 'config/variables';
import { getUserSubsidiaryRequest, } from 'store/actions/company-actions';
import { switchUserClose } from 'store/actions/component-actions';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Avatar, Badge, Stack } from '@mui/material';
import { getUsersPermissionsRequest } from 'store/actions/user-actions';
import { translations } from 'locales/translations';
import { blockChatLoadedState, blockedByRequest, getArchivedChatRequest, getBlockedChatRequest, getChatRequest, getNewMessageNotification, getsubsidiaryActiveMemberListRequest, lastMessagesState, resetChatRequest } from 'store/actions/chat-action';
import { PrivatePusherClient, PusherClient } from 'websocket';
import { resetPublicProfile } from 'store/actions/publicProfile-action';
import { SvgsignOut } from '../svgicons/svgNew';
import { SvgPlus } from '../svgicons/svg2';
import { Wrapper } from './SwitchAccountStyle';
import { logoutRequest, updateMyWalletBalance } from 'store/actions/auth-actions';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { SvgClose } from '../svgicons/svg';
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon ';
import { resetMyAds } from 'store/actions/myAds-actions';
import { updateFavoriteList } from 'store/actions/favorite-actions';

const SwitchAccountNew = (props: any) => {
  //hooks
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const [isSmallDevice, isMediumDevice] = useDeviceType()

  // loggedIn subsidiary Id from localstorage
  const subsidiaryId = getSubsidiaryStored()

  //redux states
  const { userData } = useSelector((state: RootStateOrAny) => state.user)
  const { subsidiaryData, subsidiaryDetails } = useSelector((state: RootStateOrAny) => state.company)
  const { isFromHeader, setOpenProfile } = props

  //local states
  const [countrySubflag, _countrySubflag] = useState<Array<any>>([])
  const [privateUserPicture, _privateUserPicture] = useState<string>('')
  const [firstName, _firstName] = useState<string>('')
  const [lastName, _lastName] = useState<string>('')
  const [countryFlag, _countryFlag] = useState<any>('')
  const [addSubsidiary, _addSubsidiary] = useState<boolean>(true)

  useEffect(() => {
    if (subsidiaryData.length < 1) {
      dispatch(getUserSubsidiaryRequest())
    }
  }, [])

  useEffect(() => {
    CountriesDrop1().then(data => {
      const defaultCountry = data.filter(x => x.id === userData?.limited_address?.country_id)
      _countryFlag(defaultCountry[0]);
    })
  }, [userData])

  useEffect(() => {
    CountriesDrop1().then(data => {
      _countrySubflag(data);
    })
  }, [subsidiaryData])

  React.useEffect(() => {
    if (props.privateProfile) {
      _privateUserPicture(props.privateProfile)
    } else {
      if (userData.profile_picture_url) {
        _privateUserPicture(userData.profile_picture_url)
      }
    }
  }, [props.privateProfile, userData])

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      if (userData?.first_name) {
        _firstName(userData?.first_name);
      }
      if (userData?.last_name) {
        _lastName(userData?.last_name);
      }
      if (userData?.profile_picture_url) {
        _privateUserPicture(userData?.profile_picture_url)
      }
    }
  }, [userData])

  const removeQueryParams = () => {
    // remove query params as landed on chat page from notification (which includes chat_id and subsidiary_id as query params)
    //i.e http://localhost:3000/en/chat?chat_id=144 (which opens only chat screen for chat id 144)
    const queryParams = new URLSearchParams(location.search)
    // if getting chat id in url then 
    const param = queryParams.get('chat_id');
    const switchParam = queryParams.get('switch');
    if (param || switchParam) {
      // remove chat_id from url and replace with (http://localhost:3000/en/chat)
      queryParams.delete('chat_id');
      queryParams.delete('switch');
      history.replace({
        search: queryParams.toString(),
      })
    }
  };

  const requestChatUpdates = (id) => {
    dispatch(resetChatRequest()); //resetting all redux for chat whenever switching accounts
    dispatch(getChatRequest({ page: 1 }))//chatlist
    dispatch(getArchivedChatRequest({ page: 1 }))//archived chat list
    dispatch(getBlockedChatRequest({ page: 1 })) //blocked chat list
    dispatch(blockedByRequest())//list of users who have blocked loggedIn user
    if (id) {
      dispatch(getsubsidiaryActiveMemberListRequest({ subsidiary_id: id }))
    }
  }
  const changeUser = (name, id) => {
    //establish connection
    const PusherClientInstance = PusherClient();
    const PrivatePusherClientInstance = PrivatePusherClient();

    if (isFromHeader)
      setOpenProfile(null)
    //check if user is on vehicle summary page or add vehicle page ,and trying to switch profile then show a prompt for confirmation
    if (location.pathname === '/vehicle-summary') {
      const isOk = window.confirm(t(translations.webTexts.CONTINUE_WITH_UNSAVED_CHANGES));
      if (!isOk) {
        dispatch(switchUserClose())
        return;
      }
    } else if (location.pathname === '/add-vehicle') {
      const isOk = window.confirm(t(translations.webTexts.ARE_YOU_SURE_TO_QUIT));
      if (!isOk) {
        dispatch(switchUserClose())
        return;
      }
    }

    removeQueryParams()
    localStorage.removeItem('vehicleHistoryId')
    localStorage.removeItem('prevPath')
    dispatch(blockChatLoadedState())
    dispatch(resetPublicProfile())
    dispatch(lastMessagesState([]))
    dispatch(getAllCardsFailure({}))
    dispatch(editSubsidiaryFailure({}))
    dispatch(checkVatFailure({}))
    dispatch(updateFavoriteList({}))
    const prevURL = localStorage.getItem('myadsURL')
    if (name == null && id == null) {
      dispatch(getSubsidiaryDetailsReset())
      dispatch(updateMyWalletBalance(userData?.wallet_balance))

      // unsubscribe message subsidiary channels when account switches to private
      if (userData && userData.id && subsidiaryId) {
        PrivatePusherClientInstance?.unsubscribe(`private-inbox.subsidiary.${subsidiaryId}.user.${userData.id}`);
      }
      // unsubscribe bidding channel for dealer and subscribe private channel when loggedIn as private user

      PusherClientInstance?.unsubscribe('ad_vehicles.dealer')
      PusherClientInstance?.subscribe('ad_vehicles.private_user')
      setTimeout(() => {
        localStorage.removeItem('subsidiary');
        localStorage.removeItem('planId');
        dispatch(switchUserClose())
        dispatch(privateSwitchRequest(true))
        dispatch(getUsersPermissionsRequest({}));
        requestChatUpdates(null)
        dispatch(resetMyAds())
        if (location.pathname.includes(routes.userRoutes.createOrder)
          || location.pathname.includes(routes.userRoutes.confirmSale)
          || location.pathname.includes(routes.userRoutes.orderReservation)
        ) {
          if (prevURL) {
            history.replace(prevURL)
            return
          }
          history.replace('/')
          return
        }
        if (subsidiaryProfilePaths.some(path => location.pathname.includes(path))) {
          if (subsidiaryId && !id) {
            history.replace(routes.userRoutes.myProfile); //Switching to private profile for particular subsidiaryProfilePaths
            return;
          }
        }
        if (history.location.pathname === routes.companyRoutes.companyProfile) {
          history.replace(routes.userRoutes.myProfile)
        } else {
          window.location.reload()
        }
      }, 800)
    } else {
      if (id) {
        // unsubscribe message subsidiary channels when  
        if (userData && userData.id) {
          if (subsidiaryId) {
            PrivatePusherClientInstance?.unsubscribe(`private-inbox.subsidiary.${subsidiaryId}.user.${userData.id}`);//account switches to private to subsidiary
          }
          PrivatePusherClientInstance?.unsubscribe(`private-inbox.user.${userData.id}`);//and subsidiary to subsidiary
        }
        // subscribe bidding channel for dealer and unsubscribe private channel when loggedIn as dealer
        PusherClientInstance?.unsubscribe('ad_vehicles.private_user')
        PusherClientInstance?.subscribe('ad_vehicles.dealer')
        if (id !== subsidiaryId && location.pathname.includes(routes.companyRoutes.editRole)) {
          history.replace(routes.companyRoutes.roleList)
        }
        dispatch(getUsersPermissionsRequest({ subsidiaryId: id }))
        dispatch(getSubsidiaryDetailsRequest({ subsidiary_Id: id, saveSubsidiary: true, subsidiaryId: id }))
        requestChatUpdates(id)
        dispatch(resetMyAds())
        if (location.pathname.includes(routes.userRoutes.createOrder)
          || location.pathname.includes(routes.userRoutes.confirmSale)
          || location.pathname.includes(routes.userRoutes.orderReservation)
        ) {
          if (prevURL) {
            history.replace(prevURL)
            return
          }
          history.replace('/')
          return
        }

        if (privateProfilePaths.some(path => location.pathname.includes(path))) {
          if (!subsidiaryId && id) {
            history.replace(routes.companyRoutes.companyProfile); //Switching to pro profile for particular privateProfilePaths
            return;
          }
        }
        if (history.location.pathname === routes.userRoutes.myProfile) {
          history.replace(routes.companyRoutes.companyProfile)
        }
      }
    }
  }


  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      const getSubsidiaryList = (user) => {
        _addSubsidiary(true)
        const parentCompany = user?.parent_companies
        if (parentCompany.length > 0 && parentCompany[0].subsidiaries.length > 0) {
          const subsidiaries = parentCompany[0].subsidiaries;
          for (var i = 0; i < subsidiaries.length; i++) {
            if ("subscriptions" in subsidiaries[i]) {
              const subscription = subsidiaries[i].subscriptions;
              if (subscription.length > 0 && (subscription[0].stripe_status == Strings.incomplete || subscription[0].stripe_status == Strings.incompleteExpired || subscription[0].stripe_status == Strings.pastDue)) {
                _addSubsidiary(false);
                break;
              } else {
                _addSubsidiary(false);
                break;
              }
            } else {
              _addSubsidiary(false);
              break;
            }
          }
        }
      }
      getSubsidiaryList(userData);
    }
  }, [userData]);


  const showCreateSubsidiaryButton = () => {
    if (addSubsidiary && userData?.profile_is_complete && userData?.can_create_company) {
      return true
    }
    return false
  }



  return (
    <Wrapper>

      {(isSmallDevice || isMediumDevice) &&
        <Box className='switch_head pointer'>
          {t(translations.profileScreen.OTHER_PROFILE)}
        </Box>
      }

      <Box className={`${isSmallDevice ? 'scrolable-view scrollBar' : ''}`} >

        <Box className='switch_wrapper'>
          <Box className='switch_user' onClick={e => changeUser(null, null)}>
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <img className="pointer" src={countryFlag?.flag_thumbnail ? countryFlag?.flag_thumbnail : placeholderImage
                  } style={{ width: "20px", height: "20px", borderRadius: "50%" }} alt="flag" />
                }
              >
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src={privateUserPicture ? privateUserPicture : placeholderImage} />
              </Badge>
            </Stack>
            <Box className='flexColumn'>
              <Box className='flexRow alignItemsCenter flexWrap '>
                <h5>
                  {firstName || lastName ? `${firstName} ${lastName}` : t(translations.profileScreen.PSEUDONAME)}
                </h5>
                <AccountVerifiedIcon isVerified={userData.profile_is_verified} />
                <span className='accountTag privateAccount'>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_PRIVATE)}</span>

              </Box>
              <span >
                {t(translations.companyProfileScreen.PRIVATEACCOUNT)}
              </span>
            </Box>
          </Box>
          <input className="form-check-input pointer" type="radio" value={"0"} name="flexRadioDefault" id="flexRadioDefault" checked={!subsidiaryId}
            onChange={e => changeUser(null, null)}
          />
        </Box>

        {subsidiaryData && subsidiaryData.length > 0 ?
          subsidiaryData.map((subsidiary, i) => {
            return <Box className="switch_wrapper" key={'select-sub-' + subsidiary.id} >
              <Box sx={{ cursor: "pointer" }} className={"switch_user"} onClick={e => changeUser(subsidiary.name, subsidiary.id)} >
                <Stack direction="row" sx={{ justifyContent: 'center' }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <img className="pointer" src={subsidiary?.limited_address?.country_id ? countrySubflag.find((o) => o.id == subsidiary?.limited_address?.country_id)?.flag_thumbnail : null} style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                    }
                  >
                    <Avatar
                      sx={{ width: 50, height: 50 }}
                      src={subsidiary.logo_url ? subsidiary.logo_url : companyPlaceholder} />
                  </Badge>
                </Stack>
                <Box className='flexRow alignItemsCenter flexWrap'>
                  <h5>
                    {subsidiary.name}
                  </h5>
                  <AccountVerifiedIcon isVerified={subsidiary.profile_is_verified} />
                  <span className='accountTag proAccount'>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_DEALER)}</span>
                </Box>
              </Box>
              <input className="form-check-input pointer" value={`${i}`} type="radio" name="flexRadioDefault" id="flexRadioDefault" checked={subsidiaryId == subsidiary.id}
                onChange={e => changeUser(subsidiary.name, subsidiary.id)}
              />
            </Box>
          })
          : <Box className='sign_out no-border'>
            {t(translations.webTexts.NO_COMPANY)}
          </Box>
        }
      </Box>

      {
        (isSmallDevice || isMediumDevice) &&
        <Box className='fixed-bottom'>
          {
            showCreateSubsidiaryButton() ?
              <Link to={routes.companyRoutes.createCompany} className='switch_company' onClick={() => { dispatch(switchUserClose()) }}>
                <span><SvgPlus /></span>
                <span>{t(translations.AD_DETAIL_PAGE.CREATE_PRO_ACCOUNT)}</span>
              </Link>
              : null
          }

          <Box sx={{ cursor: 'pointer' }} className='sign_out' onClick={() => {
            dispatch(logoutRequest())
          }}>
            <span> <SvgsignOut /></span>
            <span>{t(translations.webTexts.SIGN_OUT)}</span>
          </Box>
        </Box>
      }
    </Wrapper >
  )
}


export default SwitchAccountNew;
