
import React from 'react'
import { Box } from '@mui/material'
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { numberFormat, productTypes, secToHourMinun } from 'config/variables'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'

const VehicleIdentification = ({ item }) => {
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType();
    const { t } = useTranslation();
    const productType = item?.owner_vehicle?.product_type_id

    return (
        <>
            <Box className='identification-grid' sx={{ mb: 2 }} >
                <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.identification())}</DetailsTitle>
                <Box className={'identification-tab'} sx={{ display: isSmallDevice || isLargeDevice || isExtraLargeDevice ? 'block' : 'flex' }}>
                    <Box className={`list-of-identification  ${!isSmallDevice && 'grid_color_odd'}`} sx={{ width: isMediumDevice ? '48%' : '100%' }}>
                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(translations.ads.BRAND)}</label>
                            <span>{item?.owner_vehicle?.model?.brand_car?.name || " -"}</span>
                        </DetailListing>

                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(translations.ads.MODEL)}</label>
                            <span>{item?.owner_vehicle?.model?.model_name || "-"}</span>
                        </DetailListing>
                        <DetailListing className='vehicle-detail-label'>
                            <label htmlFor="">{t(translations.ads.KM)}</label>
                            <span>{item?.owner_vehicle?.vehicle_mileage ? numberFormat(item.owner_vehicle.vehicle_mileage) + " km" : '-'}</span>
                        </DetailListing>

                        {productType === productTypes.car ?
                            <>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(translations.ads.BODY)}</label>
                                    {<span>{item?.owner_vehicle?.body_vehicle?.name || "-"}</span>}
                                </DetailListing>
                            </> : null}

                        {productType === productTypes.bike ?
                            <>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(translations.ads.COLOR)}</label>
                                    <Box className='color-name'>
                                        <p>{item?.owner_vehicle?.outside_color?.name || "-"}</p>
                                        {item?.owner_vehicle?.outside_color?.hexa && <span className='disc_color_style' style={{ backgroundColor: item.owner_vehicle.outside_color.hexa || '' }}></span>}
                                    </Box>
                                </DetailListing>
                            </> : null}

                    </Box>

                    {/* Identification for steps */}
                    <Box className={`list-of-identification  ${!isSmallDevice && 'grid_color_even'}`} sx={{ width: isMediumDevice ? '48%' : '100%' }}>
                        {productType !== productTypes.car ?
                            <>
                                {productType == productTypes.bike ?
                                    <DetailListing className='vehicle-detail-label'>
                                        <label>{t(translations.summaryVehicleScreen.SEX)}</label>
                                        <span>{item?.owner_vehicle?.sex_info?.name || '-'}</span>
                                    </DetailListing> : null}

                                {productType !== productTypes.motorcycles ? <>
                                    <DetailListing className='vehicle-detail-label'>
                                        <label>{t(translations.ads.WEIGHT)}</label>
                                        <span>{item?.owner_vehicle?.weight_gross_kg || '-'} {t(translations.summaryVehicleScreen.SUMMARYVEHICLESCREEN_KG)}</span>
                                    </DetailListing>
                                    <DetailListing className='vehicle-detail-label'>
                                        <label>{t(translations.AD_DETAIL_PAGE.PACKAGE_SIZE)}</label>
                                        <span>{item?.owner_vehicle?.packaging_height && item?.owner_vehicle?.packaging_width && item?.owner_vehicle?.packaging_length ? item.owner_vehicle.packaging_width + ' X ' + item.owner_vehicle.packaging_length + ' X ' + item.owner_vehicle.packaging_height + ' (cm)' : '-'}</span>
                                    </DetailListing>
                                    <DetailListing className='vehicle-detail-label'>
                                        <label>{'EAN'}</label>
                                        <span>{item?.owner_vehicle?.ean || '-'}</span>
                                    </DetailListing>
                                </> : null}
                            </>
                            : null}

                        {productType !== productTypes.motorcycles ? <DetailListing className='vehicle-detail-label' >
                            <label htmlFor="">{t(...lan.ref())}</label>
                            <span>{item?.owner_vehicle?.vehicle_reference_number}</span>
                        </DetailListing> : null}

                        {productType === productTypes.step ?

                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(translations.webTexts.SEATS)}</label>
                                <span>{item?.owner_vehicle?.number_of_seats ? t(translations.addVehicleScreen.YES) : t(translations.addVehicleScreen.NO)}</span>
                            </DetailListing> : null}

                        {productType === productTypes.car ?
                            <>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(...lan.numberOfDoor())}</label>
                                    <span>{item?.owner_vehicle?.number_of_doors || '-'}</span>
                                </DetailListing>

                                <DetailListing className='vehicle-detail-label' >
                                    <label>{t(...lan.numberOfSeats())}</label>
                                    <span>{item?.owner_vehicle?.number_of_seats || '-'}</span>
                                </DetailListing>
                            </> : null
                        }

                        {productType === productTypes.car ?
                            <>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(...lan.foldingRearSeat())}</label>
                                    <span>{item?.owner_vehicle?.folding_rear_seat ? t(translations.ads.YES) : t(translations.ads.NO)}</span>
                                </DetailListing>

                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(...lan.numberOfAirbags())}</label>
                                    <span>{item?.owner_vehicle?.number_of_airbags || '-'}</span>
                                </DetailListing>

                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(translations.ads.TRUNK_VOLUME)}</label>
                                    <span>{item?.owner_vehicle?.trunk_volume_liter || '-'}</span>
                                </DetailListing>
                            </>
                            :
                            null}

                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(translations.ads.WARRANTY)}</label>
                            <span>{item?.vehicle_month_warranty ? item?.vehicle_month_warranty + " " + t(translations.summaryVehicleScreen.MONTHS) : '-'}</span>
                        </DetailListing>
                        {productType === productTypes.motorcycles ?
                            <>
                                <DetailListing className='vehicle-detail-label'>
                                    <label>{t(translations.ads.COLOR)}</label>
                                    <Box className='color-name'>
                                        <p>{item?.owner_vehicle?.outside_color?.name || "-"}</p>
                                        {item?.owner_vehicle?.outside_color?.hexa && <span className='disc_color_style' style={{ backgroundColor: item.owner_vehicle.outside_color.hexa || '' }}></span>}
                                    </Box>
                                </DetailListing>
                            </> : null}

                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default VehicleIdentification