import { Box, Grid } from '@mui/material';
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { productTypes } from 'config/variables';
import { lan } from 'locales/multiPageTranslation/adsLan';
import { translations } from 'locales/translations';
import React from 'react'
import { useTranslation } from 'react-i18next'

const WeightDetail = ({ item }) => {
    const { t } = useTranslation();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()


    return (
        <>
            {item?.owner_vehicle?.product_type_id === productTypes.car
                ?
                <>
                    <Grid item xl={4} sm={6} xs={12}>
                        <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.weight())}</DetailsTitle>
                        <Box className={`${!isSmallDevice && 'grid_color_odd'}`}
                        >
                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(translations.ads.GROSS_EMPTY_KG)}</label>
                                <span>{item?.owner_vehicle?.weight_empty_kg ? item?.owner_vehicle?.weight_empty_kg + ' kg' : '- kg'}</span>
                            </DetailListing>
                            <DetailListing
                                className='vehicle-detail-label'
                            >
                                <label>{t(...lan.grossWeghtKg())}</label>
                                <span>{item?.owner_vehicle?.weight_gross_kg ? item?.owner_vehicle?.weight_gross_kg + ' kg' : "- kg"}</span>
                            </DetailListing>
                            <DetailListing
                                className='vehicle-detail-label'
                            >
                                <label>{t(...lan.payloadWeightKg())}</label>
                                <span>{item?.owner_vehicle?.payload_kg ? item?.owner_vehicle?.payload_kg + ' kg' : '- kg'}</span>
                            </DetailListing>
                            <DetailListing
                                className='vehicle-detail-label'
                            >
                                <label>{t(...lan.roofLoadKg())}</label>
                                <span>{item?.owner_vehicle?.roofload_kg ? item?.owner_vehicle?.roofload_kg + ' kg' : '- kg'}</span>
                            </DetailListing>
                        </Box>
                    </Grid>
                </>
                :
                item?.owner_vehicle?.product_type_id !== productTypes.car
                &&
                <>
                    <Grid item xl={4} sm={6} xs={12}>
                        <DetailsTitle sx={{ ml: '10px' }}>{t(...lan.weight())}</DetailsTitle>
                        <Box>

                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(translations.summaryVehicleScreen.MAX_WEIGHT_ALLOWED)}</label>
                                <span>{item.owner_vehicle.max_weight_allowed || '-'} {t(translations.summaryVehicleScreen.SUMMARYVEHICLESCREEN_KG)}</span>
                            </DetailListing>
                        </Box>
                    </Grid>
                </>
            }
        </>
    )
}

export default WeightDetail